import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';

import NavBar from "../NavBar";
import { IDS_WARMUP, S_DOMAIN_DETAILS, S_WARMUP_GROUPS, WARMUP_DAYS, WARMUP_LETTER_OPTS } from "../../store/domainStore";
import { FormHelperText, OutlinedInput, Select, FormControl, MenuItem, TextField, Button, Typography, Input } from "@material-ui/core";
import { MSGS } from "../../store/msgStore";
import InfoDrawer from "../drawers/InfoDrawer";
import CampaignConfirm from "../modals/CampaignConfirm";
import MultiSelectAll from "../MultiSelectAll";
import SendyService from "../../sendy/SendyService";
import { getIndicesOf } from "../../helper/helperFunctions";


const styles = (theme) => ({
    root: {
        maxHeight: '100vh', 
        backgroundColor: 'white', 
        overflow: 'visible',
    },
    buttonStyle: {
        color: theme.palette.primary,
        backgroundColor: theme.palette.primary["light"],
    },
    mainDiv: {
        display: "flex",
        padding: theme.spacing(1),
    },
    wrapperDiv: {
        borderRadius: 10,
        border: 'solid 1px',
    },
});




class SWarmup extends Component {

    constructor(props) {
      super(props);
      
      this.SendyService = new SendyService();

      this.InfoDrawerElement = React.createRef();
      this.CampConfirmElement = React.createRef();
      this.WarmupDaysElement = React.createRef();
    }

    state = {
        uiDisable: false,
        selectedDomain: S_DOMAIN_DETAILS.PROD[0],
        domainIndex: 0,
        selectedLetter: WARMUP_LETTER_OPTS[S_DOMAIN_DETAILS.PROD[0]][0],

        emailSubject: '',
        StartingMonday: '',
        EmailContent: '',
        campaignExtraName: '',
    }

    componentDidMount() {
        this.initWarmupDays()
        this.checkDomainStatus(this.state.domainIndex)
    }
    
    initWarmupDays = () => {
        this.WarmupDaysElement.current.updateOptions(WARMUP_DAYS)
    }

    checkDomainStatus = async (domainIndex) => {
        
        this.setState( {uiDisable: true, showDownload: false} )

        const res = await this.SendyService.fetch_domain_state(S_DOMAIN_DETAILS.REF[domainIndex])
        if (Object.keys(res).length > 0) {
            if (res.sDomainState === false) { // false => not busy, true = busy
                //this.tab0Element.current.setIndex(this.state.refIndex)
                //this.tab0Element.current.fullEnable()
                this.setState( {uiDisable: false} )
            } else { // busy, don't enable it
                //this.tab0Element.current.setIndex(this.state.refIndex)
                this.setState( {uiDisable: true} )
            }       
        }
        //setTimeout(this.tab0Element.current.initFieldgroups, 300)
    }

    refreshDomainFlags = async () => {
        this.checkDomainStatus(this.state.domainIndex)
    }

    handleChangeDomain = async (event) => {
        this.setState( {[event.target.name]: event.target.value/*, uiDisable: true*/} )

        this.WarmupDaysElement.current.updateOptions(WARMUP_DAYS)

        let refIndex = -1
        for (let k = 0; k < S_DOMAIN_DETAILS.REF.length; k++) {
            if (S_DOMAIN_DETAILS.PROD[k] === event.target.value) {
                refIndex = k
                break
            }
        }
        this.checkDomainStatus(refIndex)
        this.setState({domainIndex: refIndex})
    }

    handleChangeOnly = (event) => {
        this.setState( {[event.target.name]: event.target.value} )
    }

    hCampaignCreation = async () => {

        if (!(IDS_WARMUP[S_DOMAIN_DETAILS.PROD[this.state.domainIndex]].includes(this.state.selectedLetter))) {
            this.InfoDrawerElement.current.handleOpen(
                MSGS.ERR.INVALID_DATE.COLOR, 
                "Segment IDs Not Ready",
                MSGS.ERR.INVALID_DATE.DELAY,    
            )
            return            
        }

        // Check For Correct Start Date
        const refDate = new Date(this.state.StartingMonday + "T00:00:00")
        let validDate = -1
        
        if (refDate.getDay() === 1) {
            validDate = 0
        } else {
            validDate = -2
        }
        if (validDate < 0) {
            this.InfoDrawerElement.current.handleOpen(
                MSGS.ERR.INVALID_DATE.COLOR, 
                MSGS.ERR.INVALID_DATE.MSG,
                MSGS.ERR.INVALID_DATE.DELAY,    
            )
            return
        }
        
        // Email Subject Check
        if (this.state.emailSubject === "") {
            this.InfoDrawerElement.current.handleOpen(
                MSGS.ERR.NO_EMAIL_SUBJ.COLOR, 
                MSGS.ERR.NO_EMAIL_SUBJ.MSG,
                MSGS.ERR.NO_EMAIL_SUBJ.DELAY,    
            )
            //this.fullEnable()
            return
        }

        if (this.state.EmailContent === "") {
            this.InfoDrawerElement.current.handleOpen(
                MSGS.ERR.NO_EMAIL_CONT.COLOR, 
                MSGS.ERR.NO_EMAIL_CONT.MSG,
                MSGS.ERR.NO_EMAIL_CONT.DELAY,    
            )
            //this.fullEnable()
            return
        }
        
        /* */

        // Email Content Email Check
        if (!(this.state.EmailContent.includes(S_DOMAIN_DETAILS.EMAIL[this.state.domainIndex]))) {
            this.InfoDrawerElement.current.handleOpen(
                MSGS.ERR.NO_EMAIL_HTML_EMAIL.COLOR, 
                MSGS.ERR.NO_EMAIL_HTML_EMAIL.MSG,
                MSGS.ERR.NO_EMAIL_HTML_EMAIL.DELAY,    
            )
            return
        }
        // Email Content Content Check
        const indList = getIndicesOf("<a href=", this.state.EmailContent)
        let validCount = 0;
        // for each, need to find next soonest space
        for (let i = 0; i < indList.length; i++) {

            const startIndx = indList[i] + 9
            const tempStr = this.state.EmailContent.slice(startIndx, this.state.EmailContent.length)
            //
            const endIndx = tempStr.indexOf(" ") - 1  
            const refStr = this.state.EmailContent.slice(startIndx, endIndx + startIndx)

            if (refStr.includes(S_DOMAIN_DETAILS.LINK[this.state.domainIndex])) {
                validCount++
            }
        }
        // Check if found all matches or not
        if ((validCount < indList.length) || (indList.length === 0)) {
            this.InfoDrawerElement.current.handleOpen(
                MSGS.ERR.NO_EMAIL_HTML_LINK.COLOR, 
                MSGS.ERR.NO_EMAIL_HTML_LINK.MSG,
                MSGS.ERR.NO_EMAIL_HTML_LINK.DELAY,    
            )
            return
        }
        /* */

        const prod = S_DOMAIN_DETAILS.PROD[this.state.domainIndex]
        const sd = S_DOMAIN_DETAILS.EMAIL[this.state.domainIndex]

        let details = {
            title: "Warmup Campaign Info",
            product: "Product: " + prod,
            schedule_domain: "Sending Domain: " + sd,
            email_pre_text: "Email Name Pre-text: " + this.state.campaignExtraName,
            email_subj_line: "Email Subject Line: " + this.state.emailSubject,
            start_date: "Start Date: " + this.state.StartingMonday,
            start_week: "Start Day's Week: " + this.state.selectedLetter,
        }
        this.CampConfirmElement.current.handleOpen(details)
    }

    hCampaignConfirm = async () => {
        //this.hCampaigns()
        this.hServerCampaigns()
    }

    hServerCampaigns = async() => {
        
        const refDate = new Date(this.state.StartingMonday + "T00:00:00")

        // Assemble Email Details
        const emailDetails = [{
            subject: this.state.emailSubject, 
            from_name: S_DOMAIN_DETAILS.NAME[this.state.domainIndex],
            from: S_DOMAIN_DETAILS.EMAIL[this.state.domainIndex],
            content: this.state.EmailContent,
        }]
        
        
        let groups_array = S_WARMUP_GROUPS[S_DOMAIN_DETAILS.PROD[this.state.domainIndex]][this.state.selectedLetter]
        let groups_len = groups_array.length
        if (groups_len === 75) {
            groups_array = groups_array.concat(groups_array)
            groups_len = groups_array.length
        }

        // Assemble Warmups
        const warmup_days = this.WarmupDaysElement.current.getSelected()
        
        let warmups = []
        for (let i = 0; i < warmup_days.length; i++) {
            // Current Warmup Day from Selected list
            const warmup_day_num_str = warmup_days[i]
            const warmup_day_num = parseInt(warmup_day_num_str)
            // Index Range for Given Day
            let l_index = (warmup_day_num - 1) * 15
            let u_index = warmup_day_num * 15
            if (warmup_day_num > 10) {
                l_index -= groups_len//150
                u_index -= groups_len//150
            }

            // Corresponding group-Field portions of Warmup codes
            const subset = groups_array.slice(l_index, u_index)
            // Generate full codes for current Warmup Day
            for (let j = 0; j < subset.length; j++) {
                const warmup_str = warmup_day_num_str + '_' + subset[j]
                warmups.push(warmup_str)
            }
        }
        
        // Assemble Full Information
        const data = {
            start_date: refDate,
            start_week_letter: this.state.selectedLetter,
            email_details: emailDetails,
            pre_text: this.state.campaignExtraName,
            product_type: S_DOMAIN_DETAILS.NAME_TYPE[this.state.domainIndex],//ironic naming
            warmups: warmups,
        }

        // Post Information to backend Server for execution there
        this.SendyService.post_warmup_campaigns(S_DOMAIN_DETAILS.REF[this.state.domainIndex], data)
        .then((res) => {
           this.setState({ uiDisable: true })
        })

    }


    render() {

        const { classes } = this.props;

        const currentPath = this.props.location.pathname;

        return (
            <div className={classes.root}>
                <NavBar currentPath = {currentPath} />
                <div style = {{display: 'flex'}}>
                    <div style = {{flexGrow: 1}}>
                        <FormControl
                            variant="outlined"
                        >
                            <Select
                                value={this.state.selectedDomain}
                                onChange={this.handleChangeDomain}
                                input={
                                    <OutlinedInput name="selectedDomain" />
                                }
                            >
                                {S_DOMAIN_DETAILS.PROD.map((val) => {
                                    return (
                                        <MenuItem key={val} value={val}>
                                            {val}
                                        </MenuItem>
                                    );
                                })}
                            </Select>       
                        <FormHelperText >Domain Product</FormHelperText>
                        </FormControl>
                        <Button
                            onClick = {this.refreshDomainFlags}
                        >
                            Refresh
                        </Button>
                    </div>
                </div>
                <div className= {classes.wrapperDiv}>
                    <div className = {classes.mainDiv}>
                        
                        <div style = {{flexGrow: 1}}>
                            <Typography>
                                Warmup Days Options
                            </Typography>
                            <MultiSelectAll
                                ref = {this.WarmupDaysElement}
                                keystr = 'Warmup'
                            />
                                    
                            <FormControl
                                variant="outlined"
                            >
                                <Select
                                    value={this.state.selectedLetter}
                                    onChange={this.handleChangeOnly}
                                    input={
                                        <OutlinedInput name="selectedLetter" />
                                    }
                                >
                                    {WARMUP_LETTER_OPTS[this.state.selectedDomain].map((val) => {
                                        return (
                                            <MenuItem key={val} value={val}>
                                                {val}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>       
                            <FormHelperText >Warmup Day 1 Letter Choice</FormHelperText>
                            </FormControl>
                        </div>
                        <div style = {{flexGrow: 1}}>     
                            <FormControl
                                disabled = {this.state.uiDisable}
                            >
                                <Input
                                    aria-describedby="standard-pn-helper-text"
                                    inputProps={{
                                        'aria-label': 'pn',
                                    }}
                                    onChange = {this.handleChangeOnly}
                                    autoComplete="off"
                                    name = 'emailSubject'
                                    value = {this.state.emailSubject}
                                />
                                <FormHelperText id="standard-pn-helper-text">Email Subject</FormHelperText>
                            </FormControl>
                        </div>
                        <div style = {{flexGrow: 1}}>
                            <form className={classes.container} noValidate
                                disabled = {this.state.uiDisable}
                                style={{paddingBottom: '20px'}}
                            >
                                <TextField
                                    id="date"
                                    label="Starting Monday"
                                    type="date"
                                    name="StartingMonday"
                                    onChange = {this.handleChangeOnly}
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </form>
                            <form className={classes.container} noValidate
                                disabled = {this.state.uiDisable}
                            >
                                <TextField
                                    id="date"
                                    label="Email Content (HTML String)"
                                    name="EmailContent"
                                    onChange = {this.handleChangeOnly}
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </form>
                        </div>
                        <div style = {{flexGrow: 1}}>     
                            <FormControl
                                disabled = {this.state.uiDisable}
                            >
                                <Input
                                    aria-describedby="standard-pn-helper-text"
                                    inputProps={{
                                        'aria-label': 'pn',
                                    }}
                                    onChange = {this.handleChangeOnly}
                                    autoComplete="off"
                                    name = 'campaignExtraName'
                                    value = {this.state.campaignExtraName}
                                />
                                <FormHelperText id="standard-pn-helper-text">Extra Campaign Pre-Text</FormHelperText>
                            </FormControl>
                        </div>           
                    </div>
                    <div className = {classes.mainDiv}>
                        <div style = {{flexGrow: 1}}>
                            <Button
                                className = {classes.buttonStyle}
                                onClick = {this.hCampaignCreation}
                                disabled = {this.state.uiDisable}
                            >
                                Create and Schedule Warmup
                            </Button>
                        </div>
                    </div>
                </div>
                
                <InfoDrawer
                    ref = {this.InfoDrawerElement}
                    onClose = {() => {}}
                />
                <CampaignConfirm
                    ref = {this.CampConfirmElement}
                    onConfirm = {this.hCampaignConfirm}
                />
            </div>
        )
    }

}
export default withStyles(styles)(SWarmup);