import React, { Component } from "react";
import { CSVLink } from "react-csv";

import { Button, Select, FormControl, OutlinedInput, MenuItem, FormHelperText, 
        TextField, withStyles, List, ListItem, ListItemText, ListItemSecondaryAction, 
        Typography, Grid
    } from "@material-ui/core";

import NavBar from "./NavBar";

import { DOMAIN_DETAILS } from "../store/domainStore";
import MailerService from "../mailer/MailerService";
import InfoDrawer from "./drawers/InfoDrawer";
import { MSGS } from "../store/msgStore";
import { formatNowDate, sleep } from "../helper/helperFunctions";

const styles = (theme) => ({
    root: {
        maxHeight: '100vh', 
        backgroundColor: 'white', 
        overflow: 'visible',
    },
    container: {
        padding: '10px',
    },
    basicBtn: {
        color: theme.palette.primary,
        backgroundColor: theme.palette.primary["light"],
    },
    listItemTextTitle: {
        //fontSize: '20px',
        fontWeight: 'bold',
        color: 'black',
    },
    listItemTextSecondaryTitle: {
        //fontSize: '20px',
        fontWeight: 'bold',
        color: 'black',
    },
    cfList1: {
        border: '1px solid blue',
        maxHeight: '250px',
        overflow: 'auto',
    }
});



class StatsPage extends Component {

    constructor(props) {
        super(props)

        this.Mailer = new MailerService();

        this.mailerGraph = React.createRef();
        this.InfoDrawerElement = React.createRef();
    }

    state = {
        domainIndex: 0,
        csvCampaignData: [],
        csvCampaignName: "",
        csvReady: false,

        csvBouncesData: [],
        csvBouncesName: "",
        csvBouncesRdy: false,

        selectedDomain: DOMAIN_DETAILS.PROD[0],
        StartDate: '',
        EndDate: '',
        StartDateForms: '',
        EndDateForms: '',
        uiDisable: true,

        cf_products_arr: [],
        cf_products: {},
        cf_formnames_arr: [],
        cf_formnames: {},
        cf_ml_camps_arr: [],
        cf_ml_camps: {}, 
    }

    
    componentDidMount() {
        this.checkDomainStatus(this.state.domainIndex)
    }
    

    checkDomainStatus = async (domainIndex) => {
        
        this.setState( {uiDisable: true} )

        await this.Mailer.get_api_token(DOMAIN_DETAILS.REF[domainIndex])

        const res = await this.Mailer.fetch_domain_state(DOMAIN_DETAILS.REF[domainIndex])
        if (Object.keys(res).length > 0) {
            if (res.domainState === false) { // false => not busy, true = busy
                this.setState( {uiDisable: false} )
            } else { // busy, don't enable it
                this.setState( {uiDisable: true} )
            }       
        }
    }

    handleChangeDomain = async (event) => {
        this.setState( {[event.target.name]: event.target.value} )
        
        let refIndex = -1
        for (let k = 0; k < DOMAIN_DETAILS.REF.length; k++) {
            if (DOMAIN_DETAILS.PROD[k] === event.target.value) {
                refIndex = k
                break
            }
        }
        this.checkDomainStatus(refIndex)
        this.setState({domainIndex: refIndex, csvReady: false, csvBouncesRdy: false})
    }

    refreshDomainFlags = async () => {
        this.checkDomainStatus(this.state.domainIndex)
    }

    handleChangeOnly = (event) => {
        this.setState( {[event.target.name]: event.target.value} )
    }

    readCampaignData = async () => {

        let startPg = 1

        if ((this.state.StartDate === '') || (this.state.EndDate === '')) {
            this.InfoDrawerElement.current.handleOpen(
                MSGS.ERR.INVALID_DATE_RANGE.COLOR, 
                MSGS.ERR.INVALID_DATE_RANGE.MSG,
                MSGS.ERR.INVALID_DATE_RANGE.DELAY,    
            )
            return
        }

        let d1 = Date.parse(this.state.StartDate)
        let d2 = Date.parse(this.state.EndDate)
        
        if (d1 >= d2) {
            this.InfoDrawerElement.current.handleOpen(
                MSGS.ERR.INVALID_DATE_RANGE.COLOR, 
                MSGS.ERR.INVALID_DATE_RANGE.MSG,
                MSGS.ERR.INVALID_DATE_RANGE.DELAY,    
            )
            return
        }

        const sdstr = this.state.StartDate
        const edstr = this.state.EndDate

        // Open Info Message
        let textMsg = 'Reading Data...'
        this.InfoDrawerElement.current.handleOpenStay('green', textMsg)

        let fullData = []
        //let res = await this.Mailer.getCampaignsSent(1000, startPg)
        let res = await this.Mailer.getDateRangeSentCampaigns(sdstr, edstr, 100, 1)
        fullData = fullData.concat(res.data)
        
        // Check if multiple pages
        while (res.meta.last_page !== res.meta.current_page) {
            // Update Page index
            startPg += 1

            // Update Info Message
            textMsg = 'Reading Data, Page ' + String(startPg) + ' of ' + String(res.meta.last_page) + '...'
            this.InfoDrawerElement.current.handleOpenStay('green', textMsg)

            res = await this.Mailer.getDateRangeSentCampaigns(sdstr, edstr, 100, startPg)
            fullData = fullData.concat(res.data)
        }

        let filedata = []
        if (fullData.length === 0) {
            this.InfoDrawerElement.current.handleClose()
            this.InfoDrawerElement.current.handleOpen(
                "red", 
                "No Data In This Range",
                3000,    
            )
            return
        }
        const stats_fields = Object.keys(fullData[0].emails[0].stats)
        filedata.push(["name", "sent_date"].concat(stats_fields))
        //console.log(fullData)
        // For each iter in fullData, has .stats, .name
        for (let k = 0; k < fullData.length; k++) {
            let iterData = [fullData[k].name, fullData[k].finished_at]
            for (let m = 0; m < stats_fields.length; m++) {   
                if (Object.keys(fullData[k].emails[0].stats[stats_fields[m]]).length > 0) {
                    iterData.push(fullData[k].emails[0].stats[stats_fields[m]].string)
                } else { // Just a value
                    iterData.push(fullData[k].emails[0].stats[stats_fields[m]])
                }
            }
            filedata.push(iterData)
        }

        // 
        let campaign_data = []
        for (let u = 0; u < fullData.length; u++) {
            // Update Info Message
            textMsg = 'Reading Activity, Campaign ' + String(u+1) + ' of ' + String(fullData.length) + '...'
            this.InfoDrawerElement.current.handleOpenStay('green', textMsg)
            // Read Campaign's Activity for Softbounces
            const softbouncers = await this.Mailer.getCampaignActivity(fullData[u].id)
            for (let r = 0; r < softbouncers.length; r++) {
                campaign_data.push([fullData[u].name, softbouncers[r]])
            }
            await sleep(500);
        }
        this.InfoDrawerElement.current.handleClose()

        // Generate File Name - range would be too much
        const nowDateStr = formatNowDate()
        const filename = nowDateStr + '_CStats_' + DOMAIN_DETAILS.PROD[this.state.domainIndex]
        
        // Enable CSV download
        this.setState({
            csvReady: true, 
            csvCampaignName: filename,
            csvCampaignData: filedata
        })

        const filenamebounces = nowDateStr + '_SBounces_' + DOMAIN_DETAILS.PROD[this.state.domainIndex]
        
        // Enable CSV download
        this.setState({
            csvBouncesRdy: true,
            csvBouncesName: filenamebounces,
            csvBouncesData: campaign_data,
        })

        // Process data for Graph
        const numEntries = fullData.length
        for (let g = 0; g < numEntries; g++) {
            fullData[g].finished_at = fullData[g].finished_at.slice(0, fullData[g].finished_at.indexOf(' '))
        }

        // first find unique days
        const unique_dates = [...new Set(fullData.map(item => item.finished_at))];

        //Total unique_clicks_count for day / total unique_opens_count for day
        //Total unique_opens_count for day / total sends for day
        //Total unsubscribes_count for day / total sends for day
        let uni_click_per_open = [];
        let uni_open_per_sent = [];
        let unsub_per_sent = [];
        let clicks_per_opens = [];
        
        // Iterate through Unique days
        for (let t = 0; t < unique_dates.length; t++) {
            // Get all days matching the current unique day
            const filteredDate = fullData.filter(item => {
                return item.finished_at === unique_dates[t];
            });

            let total_unique_opens_count = 0
            let total_unique_clicks_count = 0
            let total_unsubs_count = 0
            let total_sends = 0
            let total_clicks_count = 0
            let total_opens_count = 0
            // Iterate through day's data
            for (let u = 0; u < filteredDate.length; u++) {
                total_unique_clicks_count += filteredDate[u].emails[0].stats.unique_clicks_count;
                total_unique_opens_count += filteredDate[u].emails[0].stats.unique_opens_count;
                total_unsubs_count += filteredDate[u].emails[0].stats.unsubscribes_count;
                total_sends += filteredDate[u].emails[0].stats.sent;
                
                total_clicks_count += filteredDate[u].emails[0].stats.clicks_count;
                total_opens_count  += filteredDate[u].emails[0].stats.opens_count;
            }

            uni_click_per_open.push(total_unique_clicks_count / total_unique_opens_count);
            uni_open_per_sent.push(total_unique_opens_count / total_sends);
            unsub_per_sent.push(total_unsubs_count / total_sends);
            clicks_per_opens.push(total_clicks_count / total_opens_count)
        }

        let data = []
        data.push({
            x: unique_dates,
            y: uni_click_per_open,
            type: 'lines+markers',
            name: 'Click Rate'
        })
        data.push({
            x: unique_dates,
            y: uni_open_per_sent,
            type: 'lines+markers',
            name: 'Open Rate'
        })
        data.push({
            x: unique_dates,
            y: unsub_per_sent,
            type: 'lines+markers',
            name: 'Unsubscribes'
        })
        data.push({
            x: unique_dates,
            y: clicks_per_opens,
            type: 'lines+markers',
            name: 'Clicks Per Opens'
        })

        this.fillCampaignGraph(data)
    }

    fillCampaignGraph = async (data) => {

        const layout = {}        
        
        await window.Plotly.newPlot(this.mailerGraph.current, data, layout);

    }


    readFormsData = async () => {

        if ((this.state.StartDateForms === '') || (this.state.EndDateForms === '')) {
            this.InfoDrawerElement.current.handleOpen(
                MSGS.ERR.INVALID_DATE_RANGE.COLOR, 
                MSGS.ERR.INVALID_DATE_RANGE.MSG,
                MSGS.ERR.INVALID_DATE_RANGE.DELAY,    
            )
            return
        }

        let d1 = Date.parse(this.state.StartDateForms)
        let d2 = Date.parse(this.state.EndDateForms)
        
        if (d1 >= d2) {
            this.InfoDrawerElement.current.handleOpen(
                MSGS.ERR.INVALID_DATE_RANGE.COLOR, 
                MSGS.ERR.INVALID_DATE_RANGE.MSG,
                MSGS.ERR.INVALID_DATE_RANGE.DELAY,    
            )
            return
        }

        const sdstr = this.state.StartDateForms
        const edstr = this.state.EndDateForms

        this.setState( {uiDisable: true} )
        
        await this.Mailer.post_forms_start(sdstr, edstr)
        
        // will need to start timeout/interval to check flag
        this.waitForFormData()
    }

    waitForFormData = async () => {
        
        // Open Info Message
        let textMsg = 'Waiting For Forms Data...'
        this.InfoDrawerElement.current.handleOpenStay('green', textMsg)

        // Check Flag
        const forms_busy = await this.Mailer.forms_state_getf();
        if (forms_busy['forms_state'] === true) {
            // Keep waiting
            setTimeout(this.waitForFormData, 1000)
        } else {
            // Retrieve data from backend and process 
            let formsdata = await this.Mailer.forms_get_data();
            formsdata = formsdata['forms_data']
            var formattedFormsData = formsdata.replace(/'/g, '"');
            const data = JSON.parse(formattedFormsData)

            let formnames_full_counts = {}
            let formnames_produnique_list = []
            let t_formnames_prodobj = {}
            try {
                // All formnames for all products and their counts
                formnames_full_counts = data['formnames']
                // Product-Specific Unique Formnames List
                formnames_produnique_list = data['products'][this.state.selectedDomain]['formnames']

                t_formnames_prodobj = {}
                for (let i = 0; i < formnames_produnique_list.length; i++) {
                    const nowName = formnames_produnique_list[i]
                    t_formnames_prodobj[nowName] = formnames_full_counts[nowName]
                }
                this.InfoDrawerElement.current.handleClose()
            } catch {
            }


            // Get Mailerlite Part
            let startPg = 1
            const sdstr = this.state.StartDateForms
            const edstr = this.state.EndDateForms
    
            // Open Info Message
            let textMsg = 'Reading Data...'
            this.InfoDrawerElement.current.handleOpenStay('green', textMsg)

            let fullData = []
            //let res = await this.Mailer.getCampaignsSent(1000, startPg)
            let res = await this.Mailer.getDateRangeSentCampaigns(sdstr, edstr, 100, 1)
            fullData = fullData.concat(res.data)

            // Check if multiple pages
            while (res.meta.last_page !== res.meta.current_page) {
                // Update Page index
                startPg += 1

                // Update Info Message
                textMsg = 'Reading Data, Page ' + String(startPg) + ' of ' + String(res.meta.last_page) + '...'
                this.InfoDrawerElement.current.handleOpenStay('green', textMsg)

                res = await this.Mailer.getDateRangeSentCampaigns(sdstr, edstr, 100, startPg)
                fullData = fullData.concat(res.data)
            }
            this.InfoDrawerElement.current.handleClose()
            
            //console.log(fullData)

            // Group Campaigns by Date, counting total sent
            let campaigns_data = {} // Storage for day-grouped names/data

            // Iterate through all Entries
            for (let i = 0; i < fullData.length; i++) {

                // Get Item Name Substring
                const fullName = fullData[i].name
                const subNameEnd = fullName.lastIndexOf(" ")
                const subName = fullName.slice(0, subNameEnd).trimEnd()

                // Get Item Finished Date
                //const fullFinishedDate = fullData[i].finished_at //ex: "2023-02-07 23:00:29"
                //const appendDateStrEnd = fullFinishedDate.lastIndexOf(" ")
                //const dateStr = fullFinishedDate.slice(0, appendDateStrEnd)

                // Name for Object Property/List
                //const propStr = subName + '_' + dateStr

                // Get Sent Count of Item
                const sentCount = fullData[i].emails[0].stats.sent

                if (Object.hasOwn(campaigns_data, 'subName')) {
                    campaigns_data[subName] += sentCount
                } else {
                    campaigns_data[subName] = sentCount
                }
            }

            //console.log(campaigns_data)            

            // Update state
            this.setState( {
                cf_products_arr: Object.keys(data['products']),
                cf_products: data['products'],
                cf_formnames_arr: formnames_produnique_list,
                cf_formnames: t_formnames_prodobj,
                cf_ml_camps_arr: Object.keys(campaigns_data),
                cf_ml_camps: campaigns_data,
            } )

            this.setState( {uiDisable: false} )

        }


    }



    render() {

        const { classes } = this.props

        const currentPath = this.props.location.pathname;

        return (
            <div className={classes.root}>
                <NavBar currentPath = {currentPath} />
                <div style = {{display: 'flex'}}>
                    <div style = {{flexGrow: 1}}>
                        <FormControl
                            variant="outlined"
                        >
                            <Select
                                value={this.state.selectedDomain}
                                onChange={this.handleChangeDomain}
                                input={
                                    <OutlinedInput name="selectedDomain" />
                                }
                            >
                                {DOMAIN_DETAILS.PROD.map((val) => {
                                    return (
                                        <MenuItem key={val} value={val}>
                                            {val}
                                        </MenuItem>
                                    );
                                })}
                            </Select>       
                        <FormHelperText >Domain Product</FormHelperText>
                        </FormControl>
                        <Button
                            onClick = {this.refreshDomainFlags}
                        >
                            Refresh
                        </Button>
                    </div>
                </div>
                <div style = {{display: 'flex'}}>
                    <form className={classes.container} noValidate>
                        <TextField
                            disabled = {this.state.uiDisable}
                            id="datesm"
                            label="Start Date"
                            type="date"
                            name="StartDate"
                            onChange = {this.handleChangeOnly}
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </form>
                    <form className={classes.container} noValidate>
                        <TextField
                            disabled = {this.state.uiDisable}
                            id="dateem"
                            label="End Date"
                            type="date"
                            name="EndDate"
                            onChange = {this.handleChangeOnly}
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </form>
                    <Button
                        disabled = {this.state.uiDisable}
                        onClick = {this.readCampaignData}
                        className = {classes.basicBtn}
                    >
                        Read in Mailerlite Data
                    </Button>
                    {(this.state.csvReady) && (
                        <CSVLink 
                            data={this.state.csvCampaignData} 
                            filename={this.state.csvCampaignName}
                        >Download Campaigns Stats</CSVLink>
                    )}
                    {(this.state.csvBouncesRdy) && (
                        <CSVLink 
                            data={this.state.csvBouncesData} 
                            filename={this.state.csvBouncesName}
                        >Download Campaigns Soft Bouncers</CSVLink>
                    )}
                </div>
                <div style = {{display: 'flex', border: '1px solid'}}>
                    {(this.state.csvReady) && (
                        <div ref={this.mailerGraph}/>
                    )}
                </div>
                <div style = {{display: 'flex', border: '1px solid', padding: '5px'}}>
                    
                    <form className={classes.container} noValidate>
                        <TextField
                            disabled = {this.state.uiDisable}
                            id="datesc"
                            label="Start Date"
                            type="date"
                            name="StartDateForms"
                            onChange = {this.handleChangeOnly}
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </form>
                    <form className={classes.container} noValidate>
                        <TextField
                            disabled = {this.state.uiDisable}
                            id="dateec"
                            label="End Date"
                            type="date"
                            name="EndDateForms"
                            onChange = {this.handleChangeOnly}
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </form>
                    <Button
                        disabled = {this.state.uiDisable}
                        onClick = {this.readFormsData}
                        className = {classes.basicBtn}
                    >
                        Read in Cognito Forms Data
                    </Button>
                </div>
                {/* UI for Cognito Forms */}
                <div style = {{display: 'flex', border: '1px solid', padding: '5px'}}>
                {(this.state.cf_products_arr.length > 0) && (
                    <Grid container justifyContent="center">
                        <Grid
                            spacing={1}
                            container
                            className={classes.grid}
                        >
                        <Grid item xs={3}>
                            <List className={classes.cfList1}>
                                <ListItem key={'ltitle'}>
                                    <ListItemText primary={'Form Product'} 
                                        classes={{primary:classes.listItemTextTitle}}
                                    />
                                    <ListItemSecondaryAction>
                                        <Typography  className={classes.listItemTextSecondaryTitle}>
                                            {'Count'}
                                        </Typography>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                {this.state.cf_products_arr.map((val, i) => {
                                    return (
                                    <ListItem key={i}>
                                        <ListItemText primary={val} />
                                        <ListItemSecondaryAction>
                                            <Typography>
                                                {this.state.cf_products[val].count}
                                            </Typography>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    )
                                })}

                            </List>
                        </Grid>
                        <Grid item xs={3}>
                            <List className={classes.cfList1}>
                                <ListItem key={'ltitle'}>
                                    <ListItemText primary={'Form Name'} 
                                        classes={{primary:classes.listItemTextTitle}}
                                    />
                                    <ListItemSecondaryAction>
                                        <Typography  className={classes.listItemTextSecondaryTitle}>
                                            {'Count'}
                                        </Typography>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                {this.state.cf_formnames_arr.map((val, i) => {
                                    return (
                                    <ListItem key={i}>
                                        <ListItemText primary={val} />
                                        <ListItemSecondaryAction>
                                            <Typography>
                                                {this.state.cf_formnames[val]}
                                            </Typography>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    )
                                })}

                            </List>
                        </Grid>
                        <Grid item xs={6}>
                            <List className={classes.cfList1}>
                                <ListItem key={'ltitle'}>
                                    <ListItemText primary={'Campaign Name'} 
                                        classes={{primary:classes.listItemTextTitle}}
                                    />
                                    <ListItemSecondaryAction>
                                        <Typography  className={classes.listItemTextSecondaryTitle}>
                                            {'Total Sent'}
                                        </Typography>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                {this.state.cf_ml_camps_arr.map((val, i) => {
                                    return (
                                    <ListItem key={i}>
                                        <ListItemText primary={val} />
                                        <ListItemSecondaryAction>
                                            <Typography>
                                                {this.state.cf_ml_camps[val]}
                                            </Typography>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    )
                                })}

                            </List>
                        </Grid>
                    </Grid>
                    </Grid>
                )}
                </div>
                <InfoDrawer
                    ref = {this.InfoDrawerElement}
                    onClose = {() => {}}
                />

            </div>
        )


    }

}
export default withStyles(styles)(StatsPage)