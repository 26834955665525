import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';

import MCCScheduler from "./MCCScheduler";

import { MC_DOMAIN_DETAILS } from '../../store/domainStore';
import InfoModal from "../modals/InfoModal";
import NavBar from "../NavBar";
import MailchimpService from "../../mailchimp/MailchimpService";


const styles = (theme) => ({
    root: {
        maxHeight: '100vh', 
        backgroundColor: 'white', 
        overflow: 'visible',
    },
});


class MCMainPage extends Component {

    constructor(props) {
      super(props);
      
      this.Mailchimp = new MailchimpService();

      this.tab0Element = React.createRef()
      this.InfoModalElement = React.createRef()
      
      this.TrueSubscriberCount = 0;
      this.SubscribersReadIn = 0;
      
      this.SubsFlags = {};
      this.Subscribers = {};
      this.FieldSegments = {};
    }
  
    state = {
        loading: false,  
        selectedDomain: MC_DOMAIN_DETAILS.PROD[0],
        refIndex: 0,
        Subscribers: {},
        uiDisable: true,
        pgStr: "",
        paging: false,
    };

    componentDidMount() {
        this.checkDomainStatus(this.state.refIndex)
    }

    checkDomainStatus = async (refIndex) => {

        this.setState( {uiDisable: true} )
        const res = await this.Mailchimp.fetch_domain_state(MC_DOMAIN_DETAILS.REF[refIndex])
        if (res.mcDomainState === false) { // false => not busy, true = busy
            this.tab0Element.current.setIndex(refIndex)
            await this.tab0Element.current.fetchTemplates(refIndex)
            this.tab0Element.current.fullEnable()
        } else { // busy, don't enable it
            this.tab0Element.current.setIndex(refIndex)
        }
        this.setState( {uiDisable: false} )
        setTimeout(this.tab0Element.current.initFieldgroups, 300)
    }

    refreshScheduler = async () => {
        this.checkDomainStatus(this.state.refIndex)
    }

    handleChangeDomain = async (event) => {
        this.setState( {[event.target.name]: event.target.value, uiDisable: true} )

        this.tab0Element.current.fullDisable()

        let refIndex = -1
        for (let k = 0; k < MC_DOMAIN_DETAILS.REF.length; k++) {
            if (MC_DOMAIN_DETAILS.PROD[k] === event.target.value) {
                refIndex = k
                break
            }
        }

        this.tab0Element.current.resetState()
        //this.tab0Element.current.fullEnable()
        
        this.checkDomainStatus(refIndex)

        this.setState( {refIndex: refIndex, uiDisable: false} )
    }
    
    render() {

        const { classes } = this.props;

        const currentPath = this.props.location.pathname;

        return (
            <div className={classes.root}>
                <NavBar currentPath = {currentPath} />
                <div style = {{display: 'flex'}}>
                    <div style = {{flexGrow: 1}}>
                        <FormControl
                            variant="outlined"
                            disabled = {this.state.uiDisable}
                        >
                            <Select
                                value={this.state.selectedDomain}
                                onChange={this.handleChangeDomain}
                                input={
                                    <OutlinedInput name="selectedDomain" />
                                }
                            >
                                {MC_DOMAIN_DETAILS.PROD.map((val) => {
                                    return (
                                        <MenuItem key={val} value={val}>
                                            {val}
                                        </MenuItem>
                                    );
                                })}
                            </Select>       
                        <FormHelperText >Domain Product</FormHelperText>
                        </FormControl>
                        <Button
                            onClick = {this.refreshScheduler}
                        >
                            Refresh
                        </Button>
                    </div>
                </div>
                <MCCScheduler 
                    ref = {this.tab0Element}
                />
                <InfoModal
                    ref = {this.InfoModalElement}
                />
            </div>
        )
    }

}

export default withStyles(styles)(MCMainPage);