import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';

import { Typography, Button, TextField } from "@material-ui/core";

import NavBar from "./NavBar";
import MultiSelectAll from "./MultiSelectAll";

import MailerService from "../mailer/MailerService";
import { DOMAIN_DETAILS } from '../store/domainStore'
import UnsubCleanConfirm from "./modals/UnsubCleanConfirm";
import InfoDrawer from "./drawers/InfoDrawer";
import InfoModal from "./modals/InfoModal";
import { MSGS } from "../store/msgStore";
import { sleep } from "../helper/helperFunctions";

const styles = (theme) => ({
    root: {
        maxHeight: '100vh', 
        backgroundColor: 'white', 
        overflow: 'visible',
    },
    mainDiv: {
        display: "flex",
        padding: theme.spacing(1),
        borderRadius: 10,
        border: 'solid 1px',
    },
    cleanUnsubBtn: {
        color: theme.palette.primary,
        backgroundColor: theme.palette.primary["light"],
    },
});


class Unsubscribers extends Component {

    constructor(props) {
        super(props)
        
        this.Mailer = new MailerService();
        this.ProductElement = React.createRef();
        this.InfoDrawerElement = React.createRef();
        this.InfoModalElement = React.createRef();
        this.cleanConfirmElement = React.createRef();
    }

    state = {
        uiDisable: false,

        UnsubStart: '',
        UnsubEnd: '',
    }


    componentDidMount() {

        this.delayedUpdate()
    }

    delayedUpdate = () => {
        setTimeout(this.updateProdSelect, 100)
        setTimeout(this.fullEnable, 100)
    }

    updateProdSelect = () => {
        this.ProductElement.current.updateOptions(DOMAIN_DETAILS.PROD)
    }

    fullEnable = () => {
        this.ProductElement.current.setEnable(true)
        this.setState( {uiDisable: false} )
    }

    fullDisable = () => {
        this.ProductElement.current.setEnable(false)
        this.setState( {uiDisable: true} )
    }

    handleChangeOnly = (event) => {
        this.setState( {[event.target.name]: event.target.value} )
    }

    hcleanAction = async() => {

        // Check for Products selected
        const ProductsSelected = this.ProductElement.current.getSelected()
        if (ProductsSelected.length <= 1) {
            this.InfoDrawerElement.current.handleOpen(
                MSGS.ERR.UNSUB_NO_PROD.COLOR, 
                MSGS.ERR.UNSUB_NO_PROD.MSG,
                MSGS.ERR.UNSUB_NO_PROD.DELAY,    
            )
            //this.fullEnable()
            return
        }

        // Check Dates
        if ((this.state.UnsubStart === "") || (this.state.UnsubEnd === "")) {
            this.InfoDrawerElement.current.handleOpen(
                MSGS.ERR.UNSUB_NO_DATES.COLOR, 
                MSGS.ERR.UNSUB_NO_DATES.MSG,
                MSGS.ERR.UNSUB_NO_DATES.DELAY,    
            )
            //this.fullEnable()
            return
        }

        const details = {
            products: "Products: " + ProductsSelected.join(','),
            startDate: "Range Start: " + this.state.UnsubStart,
            endDate: "Range End: " + this.state.UnsubEnd,
        }
        this.cleanConfirmElement.current.handleOpen(details)
    }


    cleanConfirm = async () => {
        this.fullDisable()
        
        let countObj = {};
        const ProductsSelected = this.ProductElement.current.getSelected()
        for (let m = 0; m < ProductsSelected.length; m++) {

            const domainIndx = DOMAIN_DETAILS.PROD.indexOf(ProductsSelected[m])
            const domainStr = DOMAIN_DETAILS.REF[domainIndx]

            // Get Access Token for Selected Domain
            await this.Mailer.get_api_token(domainStr)
            
            let textMsg = 'Reading Unsubs from ' + ProductsSelected[m] + ', Page 1...'
            this.InfoDrawerElement.current.handleOpenStay('green', textMsg)

            let totalUnsubs = {data: []}
            let cursor = ""
            let resUnsubs = await this.Mailer.listUnsubscribers(cursor)
            totalUnsubs.data = totalUnsubs.data.concat(resUnsubs.data)
            cursor = resUnsubs.meta.next_cursor

            let page = 2
            while (cursor !== null) {
                textMsg = 'Reading Unsubs from ' + ProductsSelected[m] + ', Page ' + String(page) + '...'
                this.InfoDrawerElement.current.handleOpenStay('green', textMsg)
                resUnsubs = await this.Mailer.listUnsubscribers(cursor)
                totalUnsubs.data = totalUnsubs.data.concat(resUnsubs.data)
                cursor = resUnsubs.meta.next_cursor 
                page++
            }

            // Filter unsubs by date
            const startDate = Date.parse(this.state.UnsubStart)
            const endDate = Date.parse(this.state.UnsubEnd)

            let validUnsubs = {data: []}
            // Iterate through all unsubs
            for (let s = 0; s < totalUnsubs.data.length; s++) {
                const unsubDate = Date.parse(totalUnsubs.data[s].unsubscribed_at)

                if ((unsubDate >= startDate) && (unsubDate <= endDate)) {
                    validUnsubs.data.push(totalUnsubs.data[s])
                }
            }
            //console.log(validUnsubs.data.length)
            //console.log(validUnsubs.data)

            countObj[ProductsSelected[m]] = {}

            // iterate through all "other" selected products
            for (let x = 0; x < ProductsSelected.length; x++) {
                if (ProductsSelected[m] === ProductsSelected[x]) {
                    continue
                }

                countObj[ProductsSelected[m]][ProductsSelected[x]] = {
                    cleaned: 0,
                    already: 0,
                    missing_failed: 0,
                }
                    
                const dIndx = DOMAIN_DETAILS.PROD.indexOf(ProductsSelected[x])
                const dStr = DOMAIN_DETAILS.REF[dIndx]

                textMsg = 'Checking ' + ProductsSelected[m] + '\'s Matching Valid Unsubs in ' + ProductsSelected[x]
                this.InfoDrawerElement.current.handleOpenStay('green', textMsg)

                // Get Access Token for Selected Domain
                await this.Mailer.get_api_token(dStr)
                await sleep(250)
                // Iterate through all valid unsubs
                for (let s = 0; s < validUnsubs.data.length; s++) {
                    
                    textMsg = 'Cleaning ' + ProductsSelected[m] + '\'s Matching Valid Unsubs in ' + ProductsSelected[x]
                        + ' ' + String(s + 1) + ' of ' + String(validUnsubs.data.length)
                    this.InfoDrawerElement.current.handleOpenStay('green', textMsg)

                    try {
                        const res = await this.Mailer.getSubscriber(validUnsubs.data[s].email)
                        await sleep(250)

                        if (res.data.status !== 'unsubscribed') {
                            const res2 = await this.Mailer.updateSubscriberUnsub(res.data.id)
                            await sleep(250)
                            countObj[ProductsSelected[m]][ProductsSelected[x]].cleaned++
                        } else {
                            countObj[ProductsSelected[m]][ProductsSelected[x]].already++
                        }
                    } catch (err) {
                        console.log(err)
                        countObj[ProductsSelected[m]][ProductsSelected[x]].missing_failed++
                    }
                }

            }
        }

        this.InfoDrawerElement.current.handleOpen('green', "Cleaning Complete", 3000)

        //countObj[ProductsSelected[m]][ProductsSelected[x]]
        // iterate through each main (m), get keys for each to create string per 
            //infomodal?
        // try just viewing it first
        //console.log(countObj)
        let info_msgs = [
            JSON.stringify(countObj)
        ]

        this.InfoModalElement.current.handleOpen(info_msgs)

        this.fullEnable()
    }



    render() {

        const { classes } = this.props;
        
        const currentPath = this.props.location.pathname;

        return (
            <div className={classes.root}>
                <NavBar currentPath = { currentPath } />

                <div className = {classes.mainDiv}>
                    <div style = {{flexGrow: 1}}>
                        <Typography>
                            Product Selection
                        </Typography>
                        <MultiSelectAll
                            ref = {this.ProductElement}
                        />
                    </div>
                    
                    <div style = {{flexGrow: 1}}>
                        <form className={classes.container} noValidate
                            disabled = {this.state.uiDisable}
                        >
                            <TextField
                                id="date"
                                label="Unsub Start"
                                type="date"
                                name="UnsubStart"
                                onChange = {this.handleChangeOnly}
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </form>
                        <form className={classes.container} noValidate
                            disabled = {this.state.uiDisable}
                        >
                            <TextField
                                id="date"
                                label="Unsub End"
                                type="date"
                                name="UnsubEnd"
                                onChange = {this.handleChangeOnly}
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </form>
                    </div> 
                </div>
                <div className = {classes.mainDiv}>
                    <div style = {{flexGrow: 1}}>
                        <Button 
                            className = {classes.cleanUnsubBtn}
                            disabled = {this.state.uiDisable}
                            onClick={this.hcleanAction}
                        >
                            Clean Unsubscribers
                        </Button>
                    </div>
                </div>
                <UnsubCleanConfirm
                    ref = {this.cleanConfirmElement}
                    onConfirm = {this.cleanConfirm}
                />
                <InfoDrawer
                    ref = {this.InfoDrawerElement}
                    onClose = {() => {}}
                />
                <InfoModal
                    ref = {this.InfoModalElement}
                />
            </div>
        );
    }

}

export default withStyles(styles)(Unsubscribers);