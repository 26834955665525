import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import { 
    Input, FormControl, FormHelperText, TextField, CircularProgress ,
    Typography, Select, OutlinedInput, MenuItem, Button
} from "@material-ui/core";

import SendyService from "../../sendy/SendyService";
import { FIELD_GROUP_MAP, FIELD_GROUP_OPTS, NO_IDS_REGULAR, S_DOMAIN_DETAILS } from "../../store/domainStore";

import MultiSelectAll from "../MultiSelectAll";
import InfoDrawer from "../drawers/InfoDrawer";
import InfoModal from "../modals/InfoModal";
import { MSGS } from "../../store/msgStore";
import CampaignConfirm from "../modals/CampaignConfirm";
import { getIndicesOf } from "../../helper/helperFunctions";


const styles = (theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: 'white',
    },
    mainDiv: {
        display: "flex",
        padding: theme.spacing(1),
    },
    wrapperDiv: {
        borderRadius: 10,
        border: 'solid 1px',
    },
    segmentSelect: {
        width: 250,
        padding: theme.spacing(1),
    },
    createGroupsBtn: {
        color: theme.palette.primary,
        backgroundColor: theme.palette.primary["light"],
    },
    paperExistGroupList: {
        maxHeight: 200,
        maxWidth: 200,
        overflow: 'auto',
    },
    indeterminateColor: {
        color: "#f50057"
    },
    selectAllText: {
        fontWeight: 500
    },
    selectedAll: {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
        "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)"
        }
    }

});

class S_CScheduler extends Component {

    constructor(props) {
      super(props);
      
      this.Sendy = new SendyService();
      
      this.FieldgroupsElement = React.createRef();
      this.InfoDrawerElement = React.createRef();
      this.InfoModalElement = React.createRef();
      this.CampConfirmElement = React.createRef();
    }

  
    state = {
        loading: false,
        uiDisable: false,
        domainIndex: 0,

        templateData: [],
        templateSelectOpts: [],
        templateSelect: '',
        templateFilter: '',
        
        scheduleType: FIELD_GROUP_OPTS[S_DOMAIN_DETAILS.PROD[0]][0],
        
        emailSubject: '',
        StartingMonday: '',
        campaignExtraName: '',
    };
    resetState = () => {
        this.setState( {
            templateData: [],
            templateSelectOpts: [],
            templateSelect: '',
            templateFilter: '',
            
            scheduleType: FIELD_GROUP_OPTS[S_DOMAIN_DETAILS.PROD[0]][0],
            
            emailSubject: '',
            StartingMonday: '',
            campaignExtraName: '',
        })
        this.FieldgroupsElement.current.clearSelected()
    }

    initFieldgroups = () => {
        this.FieldgroupsElement.current.updateOptions(FIELD_GROUP_MAP[this.state.scheduleType])
    }


    enable = (loaded) => {
        this.setState({
            loading: loaded,
        })
    }

    setIndex = (refIndex) => {
        this.setState({
            domainIndex: refIndex,
            scheduleType: FIELD_GROUP_OPTS[S_DOMAIN_DETAILS.PROD[refIndex]][0],
        })
    }

    fullDisable = () => {
        this.setState( {uiDisable: true} )
    }

    fullEnable = () => {
        this.setState( {uiDisable: false} )
    }
    

    handleChangeSchedule = (event) => {
        this.FieldgroupsElement.current.updateOptions(FIELD_GROUP_MAP[event.target.value])
        this.setState( {[event.target.name]: event.target.value} )
    }
    
    handleChange = (event) => {
        this.setState( {[event.target.name]: event.target.value} )
    }

    handleTemplateFilter = (event) => {
        let templatesFull = [...this.state.templatesFull]
        let newOpts = [];
        for (let i = 0; i < templatesFull.length; i++) {
            if (templatesFull[i].includes(event.target.value)) {
                newOpts.push(templatesFull[i])
            }
        }
        this.setState( {
            templateSelectOpts: newOpts,
            templateFilter: event.target.value
        } )
    }

    
    hCampaignCreation = async () => {

        if (NO_IDS_REGULAR.includes(S_DOMAIN_DETAILS.PROD[this.state.domainIndex])) {
            this.InfoDrawerElement.current.handleOpen(
                MSGS.ERR.INVALID_DATE.COLOR, 
                "Segment IDs Not Ready",
                MSGS.ERR.INVALID_DATE.DELAY,    
            )
            return            
        }
        
        if (validDate < 0) {
            this.InfoDrawerElement.current.handleOpen(
                MSGS.ERR.INVALID_DATE.COLOR, 
                MSGS.ERR.INVALID_DATE.MSG,
                MSGS.ERR.INVALID_DATE.DELAY,    
            )
            return
        }


        // Check For Correct Start Date
        const refDate = new Date(this.state.StartingMonday + "T00:00:00")
        let validDate = -1
        
        if (refDate.getDay() === 1) {
            validDate = 0
        } else {
            validDate = -2
        }
        if (validDate < 0) {
            this.InfoDrawerElement.current.handleOpen(
                MSGS.ERR.INVALID_DATE.COLOR, 
                MSGS.ERR.INVALID_DATE.MSG,
                MSGS.ERR.INVALID_DATE.DELAY,    
            )
            return
        }
        
        // Email Subject Exists Check
        if (this.state.emailSubject === "") {
            this.InfoDrawerElement.current.handleOpen(
                MSGS.ERR.NO_EMAIL_SUBJ.COLOR, 
                MSGS.ERR.NO_EMAIL_SUBJ.MSG,
                MSGS.ERR.NO_EMAIL_SUBJ.DELAY,    
            )
            return
        }
        // Email Content Exists Check
        if (this.state.EmailContent === "") {
            this.InfoDrawerElement.current.handleOpen(
                MSGS.ERR.NO_EMAIL_CONT.COLOR, 
                MSGS.ERR.NO_EMAIL_CONT.MSG,
                MSGS.ERR.NO_EMAIL_CONT.DELAY,    
            )
            return
        }
        
        // Email Content Email Check
        if (!(this.state.EmailContent.includes(S_DOMAIN_DETAILS.EMAIL[this.state.domainIndex]))) {
            this.InfoDrawerElement.current.handleOpen(
                MSGS.ERR.NO_EMAIL_HTML_EMAIL.COLOR, 
                MSGS.ERR.NO_EMAIL_HTML_EMAIL.MSG,
                MSGS.ERR.NO_EMAIL_HTML_EMAIL.DELAY,    
            )
            return
        }
        // Email Content Content Check
        const indList = getIndicesOf("<a href=", this.state.EmailContent)
        let validCount = 0;
        // for each, need to find next soonest space
        for (let i = 0; i < indList.length; i++) {

            const startIndx = indList[i] + 9
            const tempStr = this.state.EmailContent.slice(startIndx, this.state.EmailContent.length)
            //
            const endIndx = tempStr.indexOf(" ") - 1  
            const refStr = this.state.EmailContent.slice(startIndx, endIndx + startIndx)

            if (refStr.includes(S_DOMAIN_DETAILS.LINK[this.state.domainIndex])) {
                validCount++
            }
        }
        // Check if found all matches or not
        if ((validCount < indList.length) || (indList.length === 0)) {
            this.InfoDrawerElement.current.handleOpen(
                MSGS.ERR.NO_EMAIL_HTML_LINK.COLOR, 
                MSGS.ERR.NO_EMAIL_HTML_LINK.MSG,
                MSGS.ERR.NO_EMAIL_HTML_LINK.DELAY,    
            )
            return
        }

        if (this.FieldgroupsElement.current.getSelected().length === 0) {
            this.InfoDrawerElement.current.handleOpen(
                MSGS.ERR.NO_FIELD_GROUP.COLOR, 
                MSGS.ERR.NO_FIELD_GROUP.MSG,
                MSGS.ERR.NO_FIELD_GROUP.DELAY,    
            )
            return
        }

        
        const prodt = S_DOMAIN_DETAILS.PROD[this.state.domainIndex]
        const st = this.state.scheduleType
        const sch = st.substring(0,st.indexOf(":"))
        const sd = S_DOMAIN_DETAILS.EMAIL[this.state.domainIndex]
        let details = {
            product: "Product: " + prodt,
            schedule: "Schedule: " + sch, 
            schedule_domain: "Sending Domain: " + sd,
            email_pre_text: "Email Name Pre-text: " + this.state.campaignExtraName,
            email_subj_line: "Email Subject Line: " + this.state.emailSubject,
            start_date: "Start Date: " + this.state.StartingMonday,
        }
        this.CampConfirmElement.current.handleOpen(details)
    }

    hCampaignConfirm = async () => {
        this.hServerCampaigns()
    }

    hServerCampaigns = async () => {

        //
        const refDate = new Date(this.state.StartingMonday + "T00:00:00")
        
        // Assemble Email Details
        const emailDetails = [{
            subject: this.state.emailSubject, 
            from_name: S_DOMAIN_DETAILS.NAME[this.state.domainIndex],
            from: S_DOMAIN_DETAILS.EMAIL[this.state.domainIndex],
            content: this.state.EmailContent,
        }]

        const data = {
            start_date: refDate,
            email_details: emailDetails,
            pre_text: this.state.campaignExtraName,
            schedule_type: this.state.scheduleType,
            groups: this.FieldgroupsElement.current.getSelected(),
        }

        // Campaign Create
        this.Sendy.post_campaign_create(S_DOMAIN_DETAILS.REF[this.state.domainIndex], data)
         .then((res) => {
            this.setState({ uiDisable: true })
         })
    }
    

    render() {

        const { classes } = this.props

        return (
            <div>
                {(this.state.loading === true) && (
                    <CircularProgress size={24}/>
                )}
                {(this.state.loading === false) && (
                    <div className= {classes.wrapperDiv}>
                        <div className = {classes.mainDiv}>
                            <div style = {{flexGrow: 1}}>
                                <FormControl
                                    variant="outlined"
                                    disabled = {this.state.uiDisable}
                                >
                                    <Select
                                        value={this.state.scheduleType}
                                        onChange={this.handleChangeSchedule}
                                        input={
                                            <OutlinedInput name="scheduleType" />
                                        }
                                    >
                                        {FIELD_GROUP_OPTS[S_DOMAIN_DETAILS.PROD[this.state.domainIndex]].map((val) => {
                                            return (
                                                <MenuItem key={val} value={val}>
                                                    {val}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>       
                                <FormHelperText >Schedule Type</FormHelperText>
                                </FormControl>
                            </div>
                            <div style = {{flexGrow: 1}}>
                                <Typography>
                                    Field groups Options
                                </Typography>
                                <MultiSelectAll
                                    ref = {this.FieldgroupsElement}
                                    keystr = 'Grp'
                                />
                            </div>
                        </div>
                        <div className = {classes.mainDiv}>
                            <div style = {{flexGrow: 1}}>     
                                <FormControl
                                    disabled = {this.state.uiDisable}
                                >
                                    <Input
                                        aria-describedby="standard-pn-helper-text"
                                        inputProps={{
                                            'aria-label': 'pn',
                                        }}
                                        onChange = {this.handleChange}
                                        autoComplete="off"
                                        name = 'emailSubject'
                                        value = {this.state.emailSubject}
                                    />
                                    <FormHelperText id="standard-pn-helper-text">Email Subject</FormHelperText>
                                </FormControl>
                            </div>
                            <div style = {{flexGrow: 1}}>
                                <form className={classes.container} noValidate
                                    disabled = {this.state.uiDisable}
                                >
                                    <TextField
                                        id="date"
                                        label="Starting Monday"
                                        type="date"
                                        name="StartingMonday"
                                        onChange = {this.handleChange}
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </form>
                            </div> 
                            <div style = {{flexGrow: 1}}>
                                <form className={classes.container} noValidate
                                    disabled = {this.state.uiDisable}
                                >
                                    <TextField
                                        id="date"
                                        label="Email Content (HTML String)"
                                        name="EmailContent"
                                        onChange = {this.handleChange}
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </form>
                            </div>
                            <div style = {{flexGrow: 1}}>     
                                <FormControl
                                    disabled = {this.state.uiDisable}
                                >
                                    <Input
                                        aria-describedby="standard-pn-helper-text"
                                        inputProps={{
                                            'aria-label': 'pn',
                                        }}
                                        onChange = {this.handleChange}
                                        autoComplete="off"
                                        name = 'campaignExtraName'
                                        value = {this.state.campaignExtraName}
                                    />
                                    <FormHelperText id="standard-pn-helper-text">Extra Campaign Pre-Text</FormHelperText>
                                </FormControl>
                            </div>           
                        </div>
                        <div className = {classes.mainDiv}>
                            <div style = {{flexGrow: 1}}>
                                <Button
                                    className = {classes.createGroupsBtn}
                                    onClick = {this.hCampaignCreation}
                                    disabled = {this.state.uiDisable}
                                >
                                    Create And Schedule Campaigns
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
                <InfoDrawer
                    ref = {this.InfoDrawerElement}
                    onClose = {() => {}}
                />
                <InfoModal
                    ref = {this.InfoModalElement}
                />
                <CampaignConfirm
                    ref = {this.CampConfirmElement}
                    onConfirm = {this.hCampaignConfirm}
                />
            </div>
        )
    }

}

export default withStyles(styles)(S_CScheduler);