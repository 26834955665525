import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';

import Papa from 'papaparse';

import NavBar from "../NavBar";
import { Button, FormControl, FormHelperText, Input, Typography } from "@material-ui/core";
import { MC_DOMAIN_DETAILS } from "../../store/domainStore";
import MailchimpService from "../../mailchimp/MailchimpService";
import InfoDrawer from "../drawers/InfoDrawer";
import { MSGS } from "../../store/msgStore";


const styles = (theme) => ({
    root: {
        maxHeight: '100vh', 
        backgroundColor: 'white', 
        overflow: 'visible',
    },
    buttonStyle: {
        color: theme.palette.primary,
        backgroundColor: theme.palette.primary["light"],
    },
    mainDiv: {
        display: "flex",
        padding: theme.spacing(1),
    },
    wrapperDiv: {
        borderRadius: 10,
        border: 'solid 1px',
    },
});


class MCUploadSubscribers extends Component {

    constructor(props) {
      super(props);

      this.Mailchimp = new MailchimpService()
      this.InfoDrawerElement = React.createRef();
    }

    state = {
        groupSize: '75',
        groupCount: 75,
        csvFileInput: null,
        uiDisable: true,
        filedata: null,

        csvFileInputTest: null,
    }

    componentDidMount() {
        this.checkDomains()
    }


    handleChangeGroupSize = (event) => {
        let val = event.target.value
        try {
            val = parseInt(val)
        } catch {
            val = 150
        }
        if (val < 0) { val = 150 }
        if (val > 150) { val = 150 }
        this.setState( {groupCount: val, [event.target.name]:event.target.value} )
    }


    checkDomains = async () => {

        this.setState( {uiDisable: true} )

        let domainsBusy = 0
        for (let i = 0; i < MC_DOMAIN_DETAILS.REF.length; i++) {
            if (MC_DOMAIN_DETAILS.REF[i] !== "intern") {
                const res = await this.Mailchimp.fetch_domain_state(MC_DOMAIN_DETAILS.REF[i])
                if (Object.keys(res).length > 0) {
                    if (res.mcDomainState === true) { // false => not busy, true = busy
                        domainsBusy += 1
                    }
                }
            }
        }
        if (domainsBusy === 0) { // None are busy
            this.setState( {uiDisable: false} )
        }
    }

    handleOnChange = (e) => {
        this.setState( { [e.target.name]: e.target.files[0]})
        
        var me = this;
        const reader = new FileReader();
        reader.readAsText(e.target.files[0]);
        reader.addEventListener('load', function (e) {
            const data = e.target.result;
            Papa.parse(data, {
                complete: function (results) {
                    me.setState({filedata: results.data})
                },
            });
        });
    }

    uploadSubscribers = async () => {

        this.setState( {uiDisable: true} )

        if (this.state.csvFileInput === null) {
            this.InfoDrawerElement.current.handleOpen(
                MSGS.ERR.SUB_NO_FILE.COLOR, 
                MSGS.ERR.SUB_NO_FILE.MSG,
                MSGS.ERR.SUB_NO_FILE.DELAY,    
            )
            return
        }

        if (Number.isNaN(this.state.groupCount)) {
            this.InfoDrawerElement.current.handleOpen(
                MSGS.ERR.SUB_NO_GROUP.COLOR, 
                MSGS.ERR.SUB_NO_GROUP.MSG,
                MSGS.ERR.SUB_NO_GROUP.DELAY,    
            )
            return
        }

        await this.Mailchimp.post_upload_subscribers(this.state.filedata, this.state.groupCount)

    }


    render() {

        const { classes } = this.props;

        const currentPath = this.props.location.pathname;

        return (
            <div className={classes.root}>
                <NavBar currentPath = {currentPath} />
                <div style = {{display: 'flex'}}>
                    <div style = {{flexGrow: 1}}>
                        <Button
                            onClick = {this.checkDomains}
                        >
                            Refresh
                        </Button>
                    </div>
                </div>
                <div className= {classes.wrapperDiv}>
                    <div className = {classes.mainDiv}>
                        <div style = {{flexGrow: 1}}>
                            <FormControl
                                disabled = {this.state.uiDisable}
                            >
                                <Input
                                    aria-describedby="standard-pn-helper-text"
                                    inputProps={{
                                        'aria-label': 'pn',
                                    }}
                                    onChange = {this.handleChangeGroupSize}
                                    autoComplete="off"
                                    name = 'groupSize'
                                    value = {this.state.groupSize}
                                />
                                <FormHelperText id="standard-pn-helper-text">Group Size (Max 75)</FormHelperText>
                            </FormControl>
                        </div>
                        <div style = {{flexGrow: 1}}>
                            <form>
                                <Typography>Import Subscribers CSV</Typography>
                                <input 
                                    type={"file"}
                                    id={"csvFileInput"}
                                    name={"csvFileInput"}
                                    accept={".csv"}
                                    onChange={this.handleOnChange}
                                />
                            </form>
                        </div>
                        <div style = {{flexGrow: 1}}>
                            <Button
                                className = {classes.buttonStyle} 
                                onClick={this.uploadSubscribers}
                                disabled={this.state.uiDisable}
                            >
                                Get Subscribers Upload Files
                            </Button>
                        </div>
                    </div>
                </div>
                <InfoDrawer
                    ref = {this.InfoDrawerElement}
                    onClose = {() => {}}
                />
            </div>
        )
    }

}
export default withStyles(styles)(MCUploadSubscribers)

