

const SERV_DOMAIN_STATE = '/sDomainState/?'
const SERV_CAMPAIGN_CREATE = '/sCampaignCreate/'
const SERV_WARMUP_CAMPAIGN = '/sWarmupCampaign/'
const SERV_CLEAN_UNSUBS = '/sCleanUnsubs/'

export default class SendyService {

  fetch_domain_state = async (domainName) => {
      
      return fetch(SERV_DOMAIN_STATE + new URLSearchParams({
          domain: domainName,
      }))
        .then(res => res.json()
        .then((data) => {
          return data
        })).catch((err) => {
          return err
        })
  };

  
  // a bit redundant logic in the 3 functions below
  post_campaign_create = async (domain, data) => {
    let options = {
      method:'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
          domain: domain,
          data: data,
      })
    };

    return fetch(SERV_CAMPAIGN_CREATE, options)
      .then(res => res.json()
      .then((data) => {
        return data
      })).catch((err) => {
        console.log(err)
        return err
      })
  }

  post_warmup_campaigns = async (domain, data) => {
    let options = {
      method:'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
          domain: domain,
          data: data,
      })
    };

    return fetch(SERV_WARMUP_CAMPAIGN, options)
      .then(res => res.json()
      .then((data) => {
        return data
      })).catch((err) => {
        console.log(err)
        return err
      })
    
  }

  post_clean_unsubs = async (domain, data) => {
    let options = {
      method:'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
          domain: domain,
          data: data,
      })
    };

    return fetch(SERV_CLEAN_UNSUBS, options)
      .then(res => res.json()
      .then((data) => {
        return data
      })).catch((err) => {
        console.log(err)
        return err
      })
    
  }


}