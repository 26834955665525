import { saveAs } from 'file-saver';

import { DOMAIN_DETAILS, SUBS_PAGE_LIM } from "../store/domainStore"

const URL_GROUPS = 'https://connect.mailerlite.com/api/groups'
const URL_SUBSCRIBERS = 'https://connect.mailerlite.com/api/subscribers'
const URL_CAMPAIGNS = 'https://connect.mailerlite.com/api/campaigns'
const URL_FIELDS = 'https://connect.mailerlite.com/api/fields'
const URL_SEGMENTS = 'https://connect.mailerlite.com/api/segments'

const SERV_DOMAIN_STATE = '/domainState/?'
const SERV_DOMAIN_STATE_SET = '/domainStateSet/'
const SERV_CAMPAIGN_CREATE = '/campaignCreate/'
const SERV_WARMUP_CAMPAIGN = '/warmupCampaign/'
const SERV_UPLOAD_SUBSCRIBERS = '/uploadSubscribers/'
const SERV_PY_G_START = '/pyGStart/'
const SERV_PY_G_GETF = '/pyGGetF'
const SERV_PY_G_GET = '/pyGGet'

const DASH_URL_FILTER = 'https://dashboard.mailerlite.com/api/'

export default class MailerService {

    constructor() {

        this.key = "";

    }

    set_domain_state = async (domainName, state) => {

        let options = {
            method:'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                domain: domainName,
                state: state,
            })
        };
        return fetch(SERV_DOMAIN_STATE_SET, options)
          .then(res => res.json()
          .then((data) => {
            //console.log(data)
            return data
          })).catch((err) => {
            console.log(err)
            return err
          })
    }

    fetch_domain_state = async (domainName) => {
        
        return fetch(SERV_DOMAIN_STATE + new URLSearchParams({
            domain: domainName,
        }))
          .then(res => res.json()
          .then((data) => {
            return data
          })).catch((err) => {
            return err
          })
    }

    check_subscribers = async () => {

        let requests = []
        requests.push({
            method: 'GET',
            path: 'api/subscribers/operations+33@locorobo.co',
        })
        requests.push({
            method: 'GET',
            path: 'api/subscribers/operations+32@locorobo.co',
        })
        
        let options = {
            method:'POST',
            body: JSON.stringify({
                "requests": requests, 
            })
        };
        const res = await this.mailer_fetch('https://connect.mailerlite.com/api/batch', options)
        return res
    }

    // for testing
    create_subscriber = async () => {

        let requests = []
        requests.push({
            method: 'POST',
            path: 'api/subscribers',
            body: {
                email: "operations+25@locorobo.co",
                fields: {
                    name: "Tester",
                    last_name: "Testing",
                    batch: "TEST011248",
                    state: "PA",
                    institution: "Test University",
                    referral: "Test Source",
                    title: " Test Coordinator",
                    group: "M11_A",
                    segment: "District",
                },
            }

        })
        let options = {
            method:'POST',
            body: JSON.stringify({
                "requests": requests, 
            })
        };
        const res = await this.mailer_fetch('https://connect.mailerlite.com/api/batch', options)
        return res
    }

    //DASH_URL_FILTER
    dash_post_filter_warm = async (group_field, group_code) => {
        let post_body = {
            "status": "active",
            "limit": 1000,
            "rules": [
                [
                    {
                        "operator": "text_field_equals",
                        "args": [
                            group_code,
                            group_field,
                        ]
                    }
                ]
            ],
            "after": [],
            "search": "",
            "sort": {
                "opens_count": "desc"
            }
        }
        let options = {
            method: 'POST',
            body: JSON.stringify(post_body)
        }
        const url = DASH_URL_FILTER + 'filters/'
        const res = await this.mailer_fetch(url, options)
        let data = res.data
        let next = res.meta.next
        while (next !== null) {
            post_body.after = next
            options.body = JSON.stringify(post_body)
            const url = DASH_URL_FILTER + 'filters/'
            const res = await this.mailer_fetch(url, options)
            data = data.concat(res.data)
            next = res.meta.next
        }
        return data

    }

    dash_get_fields = async () => {
        
        let options = {
            method:'GET',
        };
        const url = DASH_URL_FILTER + 'fields'
        const res = await this.mailer_fetch(url, options)
        const data = res.data
        const group_check = data.map(o => o.key === 'group')
        const segment_check = data.map(o => o.key === 'segment')
        const group_index = group_check.indexOf(true)
        const segment_index = segment_check.indexOf(true)
        const group_code = data[group_index].id
        let fields_obj = {
            group_code: group_code
        }
        if (segment_index !== -1) {
            fields_obj['segment_code'] = data[segment_index].id
        }
        return fields_obj
    }

    post_upload_subscribers = async (data, groupCount) => {
        
        let options = {
            method:'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                domain: groupCount,
                data: data,
            })
        };

        return fetch(SERV_UPLOAD_SUBSCRIBERS, options)
          .then(res => res.json()
          .then((data) => {
            const blob = new Blob([new Uint8Array(data.zipFile.data)], {type: "application/zip"});
			saveAs(blob, "data.zip");
            return data
          })).catch((err) => {
            console.log(err)
            return err
          })
        
    }

    post_warmup_campaigns = async (domain, data) => {
        return this.post_backend_data(SERV_WARMUP_CAMPAIGN, domain, data).then((res) => {
            return res
        })
    }

    post_backend_data = async (url, domain, data) => {

        let options = {
            method:'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                domain: domain,
                data: data,
            })
        };

        return fetch(url, options)
          .then(res => res.json()
          .then((data) => {
            //console.log(data)
            return data
          })).catch((err) => {
            console.log(err)
            return err
          })
    }

    post_campaign_create = async (domain, data) => {
        
        let options = {
            method:'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                domain: domain,
                data: data,
            })
        };

        return fetch(SERV_CAMPAIGN_CREATE, options)
          .then(res => res.json()
          .then((data) => {
            //console.log(data)
            return data
          })).catch((err) => {
            console.log(err)
            return err
          })
    }


    post_forms_start = async (start_date, end_date) => {
        
        let options = {
            method:'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                start_date: start_date,
                end_date: end_date,
            })
        };

        return fetch(SERV_PY_G_START, options)
          .then(res => res.json()
          .then((data) => {
            //console.log(data)
            return data
          })).catch((err) => {
            console.log(err)
            return err
          })
    }

    forms_state_getf = async () => {

        return fetch(SERV_PY_G_GETF)
          .then(res => res.json()
          .then((data) => {
            return data
          })).catch((err) => {
            return err
          })
    }

    forms_get_data = async() => {
        
        return fetch(SERV_PY_G_GET)
          .then(res => res.json()
          .then((data) => {
            //console.log(data)
            return data
          })).catch((err) => {
            console.log(err)
            return err
          })
    }




    fetch_api_token = async (domainName) => {
        const res = await fetch(`/token/${domainName}/${DOMAIN_DETAILS.FILES[domainName]}`);
        // check for errors
        if (!res.ok) {
          throw res;
        }
        return res.text();
    };

    get_api_token = async (domainName) => {
        this.key = await this.fetch_api_token(domainName) 
    }


    mailer_fetch = async (url, options) => {
        // performs api calls sending the required authentication headers
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        // Setting Authorization header
        // Authorization: Bearer XXX
        headers['Authorization'] = 'Bearer ' + this.key
        
        return fetch(url, {
            headers,
            ...options
        })
            .then(this._checkStatus)
            .then(response => {
                if (response.status !== 204) {
                    return response.json();
                }
            })
    }

    _checkStatus = (response) => {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
            return response;
        } else {
            var error = new Error(response.statusText)
            console.log(error);
        }
    }


    getCampaignList = async (status) => {
        const params = {"filter[status]": status, "filter[type]": 'regular'}
        let options = {
            method:'GET',
        };
        const url = URL_CAMPAIGNS + '/?' + ( new URLSearchParams( params ) ).toString();
        const res = await this.mailer_fetch(url, options)
        return res
    }

    getCampaignsSent = async (limit, page) => {
        const params = {
            "filter[status]": "sent", 
            "filter[type]": "regular",
            "limit": limit,
            "page": page,
        }
        let options = {
            method:'GET',
        };
        const url = URL_CAMPAIGNS + '/?' + ( new URLSearchParams( params ) ).toString();
        const res = await this.mailer_fetch(url, options)
        return res
    }

    getDateRangeSentCampaigns = async(startDate, endDate, limit, page) => {
        const params = {
            "filter[status]": "sent", 
            "filter[type]": "regular",
            "filter[date_from]": startDate,
            "filter[date_to]": endDate,
            "limit": limit,
            "page": page,
        }
        const sort_by = "-created_at"
        let options = {
            method:'GET',
        };

        let url = DASH_URL_FILTER + 'campaigns?'// + ( new URLSearchParams( params ) ).toString();
        const paramKeys = Object.keys(params)
        for (let i = 0; i < paramKeys.length; i++) {
            url += paramKeys[i] + '=' + params[paramKeys[i]] + '&'
        }
        url += 'sort=' + sort_by
        
        /*
        let url = DASH_URL_FILTER + "campaigns/?filter[status]=sent&"
        url += "filter[date_from]=" + String(startDate) + "&"
        url += "filter[date_to]=" + String(endDate) + "&"
        url += "filter[type]=all&" + "page=" + String(page)
        */
        const res = await this.mailer_fetch(url, options)
        return res
    }

    //GET https://connect.mailerlite.com/api/campaigns/{campaign_id}/reports/subscriber-activity
    getCampaignActivity = async (campaign_id) => {
    
        let softbouncers = []
        let params = {
            "filter[type]": "softbounced",
            "limit": 100,
            "page": 1,
            "with_subscribers": 1
        }
        let options = {
            method:'GET',
        };
        let url = URL_CAMPAIGNS + '/' + campaign_id + 
            '/reports/subscriber-activity/?' + 
            ( new URLSearchParams( params ) ).toString();
        let res = await this.mailer_fetch(url, options)

        for (let f = 0; f < res.data.length; f++) {
            softbouncers.push(res.data[f].subscriber.email)
        }

        const total_pages = res.meta.last_page
        
        let pg = 2
        // if more than 1 page, iterate by pages
        if (total_pages > 1) {
            while (pg <= total_pages) {
                params['page'] = pg
                url = URL_CAMPAIGNS + '/' + campaign_id + 
                    '/reports/subscriber-activity/?' + 
                    ( new URLSearchParams( params ) ).toString();
                res = await this.mailer_fetch(url, options)
                // Store Emails
                for (let f = 0; f < res.data.length; f++) {
                    softbouncers.push(res.data[f].subscriber.email)
                }
                // Update Page Index
                pg += 1
            }
        }
        return softbouncers
    }

    getSubscriberFromID = async (id) => {
        let options = {
            method:'GET',
        };
        const url = URL_SUBSCRIBERS + '/' + id;
        const res = await this.mailer_fetch(url, options)
        //console.log(res)
        return res
    }

    batchGetSubscribers = async (ids) => {
        //GET https://connect.mailerlite.com/api/subscribers/(:id or :email)
        let requests = []
        for (let m = 0; m < ids.length; m++) {
            requests.push({
                method: 'GET',
                path: 'api/subscribers' + `/${ids[m]}`
            })
        }
        let options = {
            method:'POST',
            body: JSON.stringify({
                "requests": requests, 
            })
        };
        const res = await this.mailer_fetch('https://connect.mailerlite.com/api/batch', options)
        return res

    }

    getSubscribersCount = async () => {
        let options = {
            method:'GET',
        };
        const res = await this.mailer_fetch(`${URL_SUBSCRIBERS}?limit=0`, options)
        return res
    }

    listSubscribers = async (cursor) => {
        
        let params = {
            limit: SUBS_PAGE_LIM,
        }
        if (cursor !== "") {
            params["cursor"] = cursor
        }
        let options = {
            method:'GET',
        };
        const url = URL_SUBSCRIBERS + '/?' + ( new URLSearchParams( params ) ).toString();
        const res = await this.mailer_fetch(url, options)
        //console.log(res)
        return res
    }

    getSubscriber = async (email) => {
        let options = {
            method:'GET',
        };
        const url = URL_SUBSCRIBERS + '/' + email;
        const res = await this.mailer_fetch(url, options)
        //console.log(res)
        return res
    }

    //PUT https://connect.mailerlite.com/api/subscribers/(:id)
    updateSubscriberUnsub = async (id) => {

        const currentTime = new Date();

        const hours = (currentTime.getHours()<10?'0':'') + currentTime.getHours()
        const mins = (currentTime.getMinutes()<10?'0':'') + currentTime.getMinutes()
        const seconds = (currentTime.getSeconds()<10?'0':'') + currentTime.getSeconds()
        const unsubDateStr = currentTime.getFullYear() + '-' + currentTime.getMonth() + '-' + 
            currentTime.getDate() + ' ' + hours + ':' + mins + ':' + seconds
        
        let options = {
            method:'PUT',
            body: JSON.stringify({
                status:'unsubscribed',
                unsubscribed_at: unsubDateStr,
            })
        };
        const url = URL_SUBSCRIBERS + '/' + id;
        const res = await this.mailer_fetch(url, options)
        return res
    }

    listUnsubscribers = async (cursor) => {
        
        let params = {
            limit: SUBS_PAGE_LIM,
            "filter[status]": 'unsubscribed',
        }
        if (cursor !== "") {
            params["cursor"] = cursor
        }
        let options = {
            method:'GET',
        };
        const url = URL_SUBSCRIBERS + '/?' + ( new URLSearchParams( params ) ).toString();
        const res = await this.mailer_fetch(url, options)
        //console.log(res)
        return res
    }

    listSegments = async () => {
        let options = {
            method:'GET',
        };
        const res = await this.mailer_fetch(URL_SEGMENTS, options)
        return res
    }

    createSegment = async (SegName) => {
        let options = {
            method:'POST',
            body: JSON.stringify({
                "name": SegName, 
            })
        };
        const res = await this.mailer_fetch(URL_SEGMENTS, options)
        return res
    }

    getFieldsList = async () => {
        let options = {
            method:'GET',
        };
        const res = await this.mailer_fetch(URL_FIELDS, options)
        return res
    }

    createGroup = async (name) => {
        const params = {"name": name}
        let options = {
            method:'POST',
        };
        const url = URL_GROUPS + '/?' + ( new URLSearchParams( params ) ).toString();
        const res = await this.mailer_fetch(url, options)
        return res
    }

    listGroups = async () => {
        let options = {
            method:'GET',
        };
        const res = await this.mailer_fetch(URL_GROUPS, options)
        return res
    }

    assignToGroup = async (subscriber_id, group_id) => {
        let options = {
            method:'POST',
        };
        let url = URL_SUBSCRIBERS + `/${subscriber_id}/groups/${group_id}`
        const res = await this.mailer_fetch(url, options)
        return res
    }

    assignToGroupBatch = async (subscriber_ids, group_id) => {

        let requests = []
        for (let m = 0; m < subscriber_ids.length; m++) {
            requests.push({
                method: 'POST',
                path: 'api/subscribers' + `/${subscriber_ids[m]}/groups/${group_id}`
            })
        }
        let options = {
            method:'POST',
            body: JSON.stringify({
                "requests": requests, 
            })
        };
        const res = await this.mailer_fetch('https://connect.mailerlite.com/api/batch', options)
        return res
    }



    deleteGroup = async (group_id) => {
        //DELETE https://connect.mailerlite.com/api/groups/{group_id}
        let options = {
            method:'DELETE',
        };
        const url = URL_GROUPS + `/${group_id}`
        const res = await this.mailer_fetch(url, options)
        return res
    }

    createCampaign = async (name, type, id, emailArray) => {
        //POST https://connect.mailerlite.com/api/campaigns
        let options = {}
        if (type === "Groups") {
            options = {
                method:'POST',
                body: JSON.stringify({
                    "name": name,
                    "type": "regular",
                    "emails": emailArray,
                    "groups": id,
                })
            }
        } else {
            options = {
                method:'POST',
                body: JSON.stringify({
                    "name": name,
                    "type": "regular",
                    "emails": emailArray,
                    "segments": id,
                })
            }
        }

        const res = await this.mailer_fetch(URL_CAMPAIGNS, options)
        return res 
    }

    scheduleCampaign = async (campaign_id, campaign_date, campaign_hr, campaign_min) => {
        //POST https://connect.mailerlite.com/api/campaigns/{campaign_id}/schedule
        let options = {
            method:'POST',
            body: JSON.stringify({
                "delivery": "scheduled",
                schedule: {
                    date:    campaign_date,
                    hours:   campaign_hr,
                    minutes: campaign_min,
                },
            })
        };
        let url = URL_CAMPAIGNS + `/${campaign_id}/schedule`
        const res = await this.mailer_fetch(url, options)
        return res
    }


    /*
        const params = {"filter[status]": status, "filter[type]": 'all'}
        let options = {
            method:'GET',
        };
        const url = URL_CAMPAIGNS + '/?' + ( new URLSearchParams( params ) ).toString();
        const res = await this.mailer_fetch(url, options) 
    */



}