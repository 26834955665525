import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import { 
    Input, FormControl, FormHelperText, TextField, CircularProgress ,
    Typography, Select, OutlinedInput, MenuItem, Button
} from "@material-ui/core";

import MailchimpService from "../../mailchimp/MailchimpService";
import { FIELD_GROUP_MAP, FIELD_GROUP_OPTS, MC_DOMAIN_DETAILS, SEG_FIELD_OPTS } from "../../store/domainStore";

import MultiSelectAll from "../MultiSelectAll";
import InfoDrawer from "../drawers/InfoDrawer";
import InfoModal from "../modals/InfoModal";
import { MSGS } from "../../store/msgStore";
import CampaignConfirm from "../modals/CampaignConfirm";

/*
const styles = (theme) => ({
    root: {
        maxHeight: '100vh', 
        backgroundColor: 'white', 
        overflow: 'visible',
    },
});*/


const styles = (theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: 'white',
    },
    mainDiv: {
        display: "flex",
        padding: theme.spacing(1),
    },
    wrapperDiv: {
        borderRadius: 10,
        border: 'solid 1px',
    },
    segmentSelect: {
        width: 250,
        padding: theme.spacing(1),
    },
    createGroupsBtn: {
        color: theme.palette.primary,
        backgroundColor: theme.palette.primary["light"],
    },
    paperExistGroupList: {
        maxHeight: 200,
        maxWidth: 200,
        overflow: 'auto',
    },
    indeterminateColor: {
        color: "#f50057"
    },
    selectAllText: {
        fontWeight: 500
    },
    selectedAll: {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
        "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)"
        }
    }

});

class MC_CScheduler extends Component {

    constructor(props) {
      super(props);
      
      this.Mailchimp = new MailchimpService();
      
      this.FieldgroupsElement = React.createRef();
      this.InfoDrawerElement = React.createRef();
      this.InfoModalElement = React.createRef();
      this.CampConfirmElement = React.createRef();
    }
  
    state = {
        loading: false,
        uiDisable: false,
        domainIndex: 0,

        templateData: [],
        templateSelectOpts: [],
        templateSelect: '',
        templateFilter: '',
        
        scheduleType: FIELD_GROUP_OPTS[MC_DOMAIN_DETAILS.PROD[0]][0],
        segments_options: ["None"].concat(SEG_FIELD_OPTS),
        segSelections: "None",
        
        emailSubject: '',
        StartingMonday: '',
        campaignExtraName: '',
        previewText: '',
    };
    resetState = () => {
        this.setState( {
            templateData: [],
            templateSelectOpts: [],
            templateSelect: '',
            templateFilter: '',
            
            scheduleType: FIELD_GROUP_OPTS[MC_DOMAIN_DETAILS.PROD[0]][0],
            segments_options: ["None"].concat(SEG_FIELD_OPTS),
            segSelections: "None",
            
            emailSubject: '',
            StartingMonday: '',
            campaignExtraName: '',
            previewText: '',
        })
        this.FieldgroupsElement.current.clearSelected()
    }

    initFieldgroups = () => {
        this.FieldgroupsElement.current.updateOptions(FIELD_GROUP_MAP[this.state.scheduleType])
    }


    enable = (loaded) => {
        this.setState({
            loading: loaded,
        })
    }

    setIndex = (refIndex) => {
        this.setState({
            domainIndex: refIndex,
            scheduleType: FIELD_GROUP_OPTS[MC_DOMAIN_DETAILS.PROD[refIndex]][0],
        })
    }

    fullDisable = () => {
        this.setState( {uiDisable: true} )
    }

    fullEnable = () => {
        this.setState( {uiDisable: false} )
    }
    

    fetchTemplates = async (refIndex) => {
        const res = await this.Mailchimp.get_templates(MC_DOMAIN_DETAILS.REF[refIndex])
        // res => Array [{id: , name: }]
        let resUnpacked = null;
        try {
            resUnpacked = JSON.parse(res)
        } catch {
            this.InfoDrawerElement.current.handleOpen(
                "red", 
                "No Templates, Can't Use This Domain Until At Least One Exists",
                4000,
            )
            return
        }
        let tempNames = []
        for (let i = 0; i < resUnpacked.length; i++) {
            tempNames.push(resUnpacked[i].name)
        }
        this.setState({
            templateData: resUnpacked,
            templateSelectOpts: tempNames,
            templatesFull: tempNames,
            templateSelect: tempNames[0],
        })
    }
    
    handleChangeSchedule = (event) => {
        this.FieldgroupsElement.current.updateOptions(FIELD_GROUP_MAP[event.target.value])
        this.setState( {[event.target.name]: event.target.value} )
    }
    
    handleChange = (event) => {
        this.setState( {[event.target.name]: event.target.value} )
    }

    handleTemplateFilter = (event) => {
        let templatesFull = [...this.state.templatesFull]
        let newOpts = [];
        for (let i = 0; i < templatesFull.length; i++) {
            if (templatesFull[i].includes(event.target.value)) {
                newOpts.push(templatesFull[i])
            }
        }
        this.setState( {
            templateSelectOpts: newOpts,
            templateFilter: event.target.value
        } )
    }

    
    hCampaignCreation = async () => {

        // Check For Correct Start Date
        const refDate = new Date(this.state.StartingMonday + "T00:00:00")
        let validDate = -1
        
        if (refDate.getDay() === 1) {
            validDate = 0
        } else {
            validDate = -2
        }
        if (validDate < 0) {
            this.InfoDrawerElement.current.handleOpen(
                MSGS.ERR.INVALID_DATE.COLOR, 
                MSGS.ERR.INVALID_DATE.MSG,
                MSGS.ERR.INVALID_DATE.DELAY,    
            )
            return
        }
        
        // Email Subject Check
        if (this.state.emailSubject === "") {
            this.InfoDrawerElement.current.handleOpen(
                MSGS.ERR.NO_EMAIL_SUBJ.COLOR, 
                MSGS.ERR.NO_EMAIL_SUBJ.MSG,
                MSGS.ERR.NO_EMAIL_SUBJ.DELAY,    
            )
            return
        }

        if (this.FieldgroupsElement.current.getSelected().length === 0) {
            this.InfoDrawerElement.current.handleOpen(
                MSGS.ERR.NO_FIELD_GROUP.COLOR, 
                MSGS.ERR.NO_FIELD_GROUP.MSG,
                MSGS.ERR.NO_FIELD_GROUP.DELAY,    
            )
            return
        }

        
        const prod = MC_DOMAIN_DETAILS.PROD[this.state.domainIndex]

        const st = this.state.scheduleType
        const sch = st.substring(0,st.indexOf(":"))

        const sd = MC_DOMAIN_DETAILS.EMAIL[this.state.domainIndex]

        let details = {
            product: "Product: " + prod,
            schedule: "Schedule: " + sch, 
            schedule_domain: "Sending Domain: " + sd,
            email_pre_text: "Email Name Pre-text: " + this.state.campaignExtraName,
            email_subj_line: "Email Subject Line: " + this.state.emailSubject,
            prev_text: "Email Preview Text: " + this.state.previewText,
            start_date: "Start Date: " + this.state.StartingMonday,
        }
        this.CampConfirmElement.current.handleOpen(details)

    }

    hCampaignConfirm = async () => {

        this.hServerCampaigns()
    }

    hServerCampaigns = async () => {

        // 
        const result = this.state.templateData.find(item => 
            item.name === this.state.templateSelect);
            
        if (typeof result === 'undefined') {
            this.InfoDrawerElement.current.handleOpen(
                "red", 
                "Can't Find Template ID, No Action Performed",
                3000,
            )
            return
        }

        const templateID = result.id

        const refDate = new Date(this.state.StartingMonday + "T00:00:00")
        
        // Assemble Email Details
        const emailDetails = [{
            subject: this.state.emailSubject, 
            from_name: MC_DOMAIN_DETAILS.NAME[this.state.domainIndex],
            from: MC_DOMAIN_DETAILS.EMAIL[this.state.domainIndex],
            template: templateID,
            preview_text: this.state.previewText
        }]

        let segmentPick = []
        if (this.state.segSelections !== "None") {
            segmentPick.push(this.state.segSelections)
        }
        const data = {
            start_date: refDate,
            email_details: emailDetails,
            pre_text: this.state.campaignExtraName,
            schedule_type: this.state.scheduleType,
            segments: segmentPick,
            groups: this.FieldgroupsElement.current.getSelected(),
        }


        // Campaign Create
        this.Mailchimp.post_campaign_create(MC_DOMAIN_DETAILS.REF[this.state.domainIndex], data)
         .then((res) => {
            this.setState({ uiDisable: true })
         })
    }
    

    render() {

        const { classes } = this.props

        return (
            <div>
                {(this.state.loading === true) && (
                    <CircularProgress size={24}/>
                )}
                
                {(this.state.loading === false) && (
                    <div className= {classes.wrapperDiv}>
                        <div className = {classes.mainDiv}>
                            <div style = {{flexGrow: 1}}>
                                <FormControl
                                    variant="outlined"
                                    disabled = {this.state.uiDisable}
                                >
                                    <Select
                                        value={this.state.scheduleType}
                                        onChange={this.handleChangeSchedule}
                                        input={
                                            <OutlinedInput name="scheduleType" />
                                        }
                                    >
                                        {FIELD_GROUP_OPTS[MC_DOMAIN_DETAILS.PROD[this.state.domainIndex]].map((val) => {
                                            return (
                                                <MenuItem key={val} value={val}>
                                                    {val}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>       
                                <FormHelperText >Schedule Type</FormHelperText>
                                </FormControl>
                            </div>
                            <div style = {{flexGrow: 1}}>
                                <Typography>
                                    Field groups Options
                                </Typography>
                                <MultiSelectAll
                                    ref = {this.FieldgroupsElement}
                                    keystr = 'Grp'
                                />
                            </div>
                            <div style = {{flexGrow: 1}}>
                                <FormControl 
                                    className={classes.segmentSelect}
                                    disabled = {this.state.uiDisable}
                                >
                                    <Select
                                        native
                                        value={this.state.segSelections}
                                        input={
                                            <OutlinedInput name="segSelections" />
                                        }
                                        onChange={this.handleChange}
                                    >
                                    {this.state.segments_options.map((val) => (
                                        <option key={val} value={val}>
                                            {val}
                                        </option>
                                    ))}
                                    </Select>
                                <FormHelperText>Field segment Selections</FormHelperText>
                                </FormControl>
                            </div>
                        </div>
                        <div className = {classes.mainDiv}>
                            <div style = {{flexGrow: 1}}>     
                                <FormControl
                                    disabled = {this.state.uiDisable}
                                >
                                    <Input
                                        aria-describedby="standard-pn-helper-text"
                                        inputProps={{
                                            'aria-label': 'pn',
                                        }}
                                        onChange = {this.handleChange}
                                        autoComplete="off"
                                        name = 'emailSubject'
                                        value = {this.state.emailSubject}
                                    />
                                    <FormHelperText id="standard-pn-helper-text">Email Subject</FormHelperText>
                                </FormControl>
                            </div>
                            <div style = {{flexGrow: 1}}>
                                <form className={classes.container} noValidate
                                    disabled = {this.state.uiDisable}
                                >
                                    <TextField
                                        id="date"
                                        label="Starting Monday"
                                        type="date"
                                        name="StartingMonday"
                                        onChange = {this.handleChange}
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </form>
                            </div> 
                            <div style = {{flexGrow: 1}}>
                                <FormControl
                                    variant="outlined"
                                    disabled = {this.state.uiDisable}
                                >
                                    <Input
                                        aria-describedby="standard-pn-helper-text"
                                        inputProps={{
                                            'aria-label': 'pn',
                                        }}
                                        onChange = {this.handleTemplateFilter}
                                        autoComplete="off"
                                        name = 'templateFilter'
                                        value = {this.state.templateFilter}
                                    />
                                    <FormHelperText >Template Filter</FormHelperText>
                                </FormControl>
                                <FormControl
                                    variant="outlined"
                                    disabled = {this.state.uiDisable}
                                >
                                    <Select
                                        value={this.state.templateSelect}
                                        onChange={this.handleChange}
                                        input={
                                            <OutlinedInput name="templateSelect" />
                                        }
                                    >
                                        {this.state.templateSelectOpts.map((val, i) => {
                                            return (
                                                <MenuItem key={val + String(i)} value={val}>
                                                    {val}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>       
                                <FormHelperText >Template Selection</FormHelperText>
                                </FormControl>
                            </div>
                            <div style = {{flexGrow: 1}}>     
                                <FormControl
                                    disabled = {this.state.uiDisable}
                                >
                                    <Input
                                        aria-describedby="standard-pn-helper-text"
                                        inputProps={{
                                            'aria-label': 'pn',
                                        }}
                                        onChange = {this.handleChange}
                                        autoComplete="off"
                                        name = 'campaignExtraName'
                                        value = {this.state.campaignExtraName}
                                    />
                                    <FormHelperText id="standard-pn-helper-text">Extra Campaign Pre-Text</FormHelperText>
                                </FormControl>
                                <FormControl
                                    disabled = {this.state.uiDisable}
                                >
                                    <Input
                                        aria-describedby="standard-pn-helper-text"
                                        inputProps={{
                                            'aria-label': 'pn',
                                        }}
                                        onChange = {this.handleChange}
                                        autoComplete="off"
                                        name = 'previewText'
                                        value = {this.state.previewText}
                                    />
                                    <FormHelperText id="standard-pn-helper-text">Preview Text</FormHelperText>
                                </FormControl>
                            </div>
                            <div className = {classes.mainDiv}>
                                <div style = {{flexGrow: 1}}>
                                    <Button
                                        className = {classes.createGroupsBtn}
                                        onClick = {this.hCampaignCreation}
                                        disabled = {this.state.uiDisable}
                                    >
                                        Create And Schedule Campaigns
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <InfoDrawer
                    ref = {this.InfoDrawerElement}
                    onClose = {() => {}}
                />
                <InfoModal
                    ref = {this.InfoModalElement}
                />
                <CampaignConfirm
                    ref = {this.CampConfirmElement}
                    onConfirm = {this.hCampaignConfirm}
                />
            </div>
        )
    }
}

export default withStyles(styles)(MC_CScheduler);