

export const MSGS = {
    ERR: {
        NO_EMAIL_SUBJ: {MSG: "Blank Email Subject Not Allowed", DELAY: 2000, COLOR: 'red',},
        NO_EMAIL_CONT: {MSG: "Email Content Required", DELAY: 2000, COLOR: 'red',},
        NO_EMAIL_HTML_EMAIL: {MSG: "The Email Content Does Not Match the Product", DELAY: 3000, COLOR: 'red',},
        NO_EMAIL_HTML_LINK: {MSG: "Email Link Missing or Incorrect", DELAY: 3000, COLOR: "red"},
        NO_FIELD_GROUP: {MSG: "Must Select Field group(s)", DELAY: 3000, COLOR: "red"},
        INVALID_DATE: {MSG: "Invalid Date", DELAY: 2000, COLOR: 'red',},
        INVALID_DATE_RANGE: {MSG: "Invalid Date Range", DELAY: 2000, COLOR: 'red',},
        NOT_A_MONDAY: {MSG: "Start Date Must Be A Future Monday", DELAY: 2000, COLOR: 'red',},
        CAMP_NO_SELECTED: {MSG: "Select Groups or Segments to Create Campaigns", DELAY: 3000, COLOR: 'red',},
        UNSUB_NO_PROD: {MSG: "Missing Product Selections (2+ required)", DELAY: 2000, COLOR: 'red'},
        UNSUB_NO_DATES: {MSG: "Missing Date Range", DELAY: 2000, COLOR: 'red'},
        UNSUB_DOMAIN_BUSY: {MSG: "One or More Selected Domains are Busy", DELAY: 2000, COLOR: 'red'},
        SUB_NO_FILE: {MSG: "Must Upload File", DELAY: 2000, COLOR: 'red'},
        SUB_NO_GROUP: {MSG: "Missing Group Size", DELAY: 2000, COLOR: 'red'},
        GROUP_SEGMENTS_MISSING: {MSG: "Missing Group Segments, Create Them First", DELAY: 2000, COLOR: 'red'},
        S_UNSUBS_NUM_FILES: {MSG: "Must Select At Least Two Files", DELAY: 2000, COLOR: "red"},
        S_UNSUBS_BAD_FILENAME: {MSG: "Incorrect File Names, Can't Get Domain Code", DELAY: 3000, COLOR: "red"},
        S_DOMAINS_BUSY: {MSG: "One Or More Relevant Domains Is Busy", DELAY: 3000, COLOR: "red"},
    },
    INFO: {
        GROUPS_FAILED: {MSG: "Created: "},
        GROUPS_SUCCEEDED: {MSG: "Failures: "},
        MISSING_GROUP_SEGMENTS: {MSG: "Creating Missing Group Segments", DELAY: 2000, COLOR: 'orange'},
        GROUP_SEGMENTS_EXIST: {MSG: "All Needed Group Segments Already Exist", DELAY: 2000, COLOR: 'green'},
    },

}