import { saveAs } from 'file-saver';

const SERV_DOMAIN_STATE = '/mcDomainState/?'
//const SERV_DOMAIN_STATE_SET = '/mcDomainStateSet/'
const SERV_GET_TEMPLATES = '/mcGetTemplates/'
const SERV_CAMPAIGN_CREATE = '/mcCampaignCreate/'
const SERV_WARMUP_ASSIGN = '/mcWarmupAssign/'
const SERV_WARMUP_ASSIGN_GET = '/mcWarmupAssignGet/?'
const SERV_WARMUP_CAMPAIGN = '/mcWarmupCampaign/'
const SERV_SEG_GET = '/mcSegmentGet/'
const SERV_SEG_CREATE = '/mcSegmentCreate/'
const SERV_CLEAN_UNSUBS = '/mcCleanUnsubs/'
const SERV_UPLOAD_SUBSCRIBERS = '/mcUploadSubscribers/'
const SERV_CAMPAIGN_STATS = '/mcCampaignStats/'
const SERV_GET_CAMPAIGN_STATS = '/mcGetCampaignStats/?'

export default class MailchimpService {

    constructor() {

        this.key = "";
        
    }

    fetch_api_token = async (domainName) => {
        let options = {
            method:'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "domain": domainName, 
            })
        };
        //const res = await fetch(, options);
        const res = await fetch('/mc_token/', options)
        // check for errors
        if (!res.ok) {
          throw res;
        }
        return res.text();
    };

    fetch_domain_state = async (domainName) => {
        
        return fetch(SERV_DOMAIN_STATE + new URLSearchParams({
            domain: domainName,
        }))
          .then(res => res.json()
          .then((data) => {
            return data
          })).catch((err) => {
            return err
          })
    };

    get_templates = async (domainName) => {
        let options = {
            method:'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                domain: domainName,
            })
        };

        return fetch(SERV_GET_TEMPLATES, options)
          .then(res => res.json()
          .then((data) => {
            //console.log(data)
            return data['mcTemplates']
        })).catch((err) => {
            console.log(err)
            return err
        });
    }


    post_campaign_create = async (domain, data) => {
        
        let options = {
            method:'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                domain: domain,
                data: data,
            })
        };

        return fetch(SERV_CAMPAIGN_CREATE, options)
          .then(res => res.json()
          .then((data) => {
            return data
          })).catch((err) => {
            console.log(err)
            return err
          })
    }

    
    post_warmup_campaigns = async (domain, data) => {
        return this.post_backend_data(SERV_WARMUP_CAMPAIGN, domain, data).then((res) => {
            return res
        })
    }

    get_segments = async (domain) => {
        return this.post_backend_data(SERV_SEG_GET, domain, {}).then((res) => {
            const data = res['mcSegments']
            return data
        })
    }

    create_segments = async (domain, data) => {
        return this.post_backend_data(SERV_SEG_CREATE, domain, data).then((res) => {
            return res
        })
    }

    post_assign_warmup = async (domain, data) => {
        return this.post_backend_data(SERV_WARMUP_ASSIGN, domain, data).then((res) => {
            return res
        })
    }

    get_assign_warmup = async (domain) => {
        return fetch(SERV_WARMUP_ASSIGN_GET + new URLSearchParams({
            domain: domain,
        }))
          .then(res => res.json()
          .then((data) => {
            return data['mcFileData']
          })).catch((err) => {
            return err
          })
    }

    post_campaign_stats = async (domain, data) => {
        return this.post_backend_data(SERV_CAMPAIGN_STATS, domain, data).then((res) => {
            return res
        })
    }

    get_campaign_stats = async (domain) => {
        return fetch(SERV_GET_CAMPAIGN_STATS + new URLSearchParams({
            domain: domain,
        }))
          .then(res => res.json()
          .then((data) => {
            return data['mcStatsData']
          })).catch((err) => {
            return err
          })
    }

    post_clean_unsubs = async (domain, data) => {
        return this.post_backend_data(SERV_CLEAN_UNSUBS, domain, data).then((res) => {
            return res
        })
    }

    post_upload_subscribers = async (data, groupCount) => {

        let options = {
            method:'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                domain: groupCount,
                data: data,
            })
        };

        return fetch(SERV_UPLOAD_SUBSCRIBERS, options)
          .then(res => res.json()
          .then((data) => {
            //const blob = new Blob([new Uint8Array(data.zipFile.data)], {type: "application/zip"});
			//saveAs(blob, "data.zip");
            return data
          })).catch((err) => {
            console.log(err)
            return err
          })
    }

    post_backend_data = async (url, domain, data) => {

        let options = {
            method:'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                domain: domain,
                data: data,
            })
        };

        return fetch(url, options)
          .then(res => res.json()
          .then((data) => {
            //console.log(data)
            return data
          })).catch((err) => {
            console.log(err)
            return err
          })
    }




}