// Import React and Material-UI Modules
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

// Material-UI CSS-type Style Specifications
const styles = (theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      position: 'absolute',
      width: 600,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      borderRadius: 15,
      square: false,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    mainDiv: {
        display: 'flex',
    },
})



// Component Class
class InfoModal extends React.Component {

    // Class constructor
    constructor(props) {
        // Access to this.props
        super(props);

    }

    // Class state
    state = {
        isOpen: false,
        infoMessages: [],
    }


    // For Displaying Self (Modal), Called by Parent ProgramCoding
    handleOpen = (infoMmgs) => {
        this.setState( {isOpen: true, infoMessages: infoMmgs} );
    };

    // For Stopping Program Run
    handleClose = () => {
        this.setState( {isOpen: false} );
    };

    // Takes input data, returns what to display
    render() {

        // Referenced below for setting styles
        const { classes } = this.props;

        // What to Display - The Modal: Header, Button, "pre" tagged Text
        return (
            <div>
                <Modal
                    className={classes.modal}
                    open={this.state.isOpen}
                    onClose={this.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                <Fade in={this.state.isOpen}>
                    <div className={classes.paper}>
                        <div>
                            <Button
                                onClick = {this.handleClose}
                            >
                                Close
                            </Button>
                        </div>
                        {this.state.infoMessages.map((val) => (
                            <div className = {classes.mainDiv} key={val}>
                                <Typography  key={'t'+val}>
                                    {val}
                                </Typography>
                            </div>
                        ))}
                    </div>
                </Fade>
                </Modal>
            </div>
        )
    }

}
export default withStyles(styles)(InfoModal);