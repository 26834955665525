import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
//import axios from 'axios';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";

import { AuthContext } from "../context/auth";
import { ACCESS_CHOICES, ACCESS_SCH } from "../store/domainStore";

// Material-UI CSS-type Style Specifications
const styles = (theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: 'white',
    },
    paper: {
        flexGrow: 1,
        padding: theme.spacing(4),
        textAlign: "center",
        justify: "center",
        color: theme.palette.primary,
        backgroundColor: theme.palette.primary["light"],
        maxWidth: 400,
        alignItems: "center",
    },
    grid: {
        padding: theme.spacing(4),
    },
    actionButton: {
        fontSize:'18px',
        type: "submit",
        justify: "center",
        margin: theme.spacing(2),
        color: theme.palette.primary["contrastText"],
        backgroundColor: "green",
        width: 200,
    },
    input: {
        justify: "center",
        margin: theme.spacing(2),
        color: theme.palette.primary["contrastText"],
        backgroundColor: theme.palette.primary["light"],
    },
    title: {
        color: theme.palette.primary["contrastText"],
        backgroundColor: theme.palette.primary["light"],
    },
    inline: {
      display: "inline"
    },
    buttonProgress: {
      color: "white",
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: 40,
      marginLeft: -12,
    },
  });


// Component Class - Login
class Login extends Component {

    constructor(props) {
        super(props);

        //this.postLogin.bind(this);
        this.handleLoginAttempt.bind(this);

        
    }

    static contextType = AuthContext;

    state = {
        isLoggedIn: false,
        isError: false,
        username: '',
        password: '',
        helperText: ' ',
        loading: false,
        selectedService: ACCESS_SCH[0],
    }

    enterFunction = (event) => {
        if(event.keyCode === 13) {
          this.handleLoginAttempt();
        }
      }

    componentDidMount() {
        document.addEventListener("keydown", this.enterFunction, false);        
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.enterFunction, false);
    }



    handleLoginAttempt = () => {
        this.setState( {loading: true} );
        this.context.AuthInstance.login(
            this.state.username,this.state.password,
            this.state.selectedService
        )
            .then(res =>{
                this.context.AuthInstance.accessChoice = this.state.selectedService
                this.handleLoginSuccess().then((res_in) => {
                    if (res_in === "error") {
                        this.context.AuthInstance.logout()
                        this.setState( {isError: true, helperText: 'Invalid Credentials'} );
                        this.setState( {loading: false} );
                    } else {
                        this.setState( {loading: false} );
                        this.props.history.replace('/');
                    }
                });
            })
            .catch(err =>{
                //alert(err);
                this.setState( {isError: true, helperText: 'Invalid Credentials'} );
                this.setState( {loading: false} );
            });/*.then(() => {
                // remove this "then" when not cheating
                this.handleLoginSuccess();
                this.setState( {loading: false} );
                this.props.history.replace('/');
                this.context.AuthInstance.authTokens = true;
                this.context.AuthInstance.userName = "username";
            });*/
    }

    handleLoginSuccess = async () => {
        return this.context.AuthInstance.getProductAccess().then((res) => {
            return Promise.resolve(res);
        })
    };

    handleChangeService = (event) => {
        this.setState( {[event.target.name]: event.target.value} )
    }

    render() {

        // Referenced below for setting styles
        const { classes } = this.props;

        return (
            <div className={classes.root}
                style={{ minHeight: '100vh' }}>
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                    className={classes.grid}
                >
                    <Grid>

                    <Card 
                        className={classes.paper}
                        style={{borderRadius: 20}}
                    >
                        <CardMedia
                            component="img"
                            image={require("../images/logo.png")}
                            title="LocoRobo"
                            style={{marginBottom:'40px'}}
                        />
                        <Typography
                            className={classes.title}
                            variant='h4'
                        >
                            LocoRobo Email Scheduling
                        </Typography>
                        <CardContent>
                            <FormControl className={classes.paper}>
                                <TextField
                                    className={classes.input}
                                    type="username"
                                    value={this.state.username}
                                    onChange={e => {
                                        this.setState( {helperText: ' ', username:  e.target.value} );
                                    }}
                                    placeholder="Username"
                                />
                                <TextField
                                    className={classes.input}
                                    type="password"
                                    value={this.state.password}
                                    onChange={e => {
                                        this.setState( {helperText: ' ', password:  e.target.value} );
                                    }}
                                    placeholder="Password"
                                />
                                
                                <FormControl
                                    variant="outlined"
                                >
                                    <Select
                                        value={this.state.selectedService}
                                        onChange={this.handleChangeService}
                                        input={
                                            <OutlinedInput name="selectedService" />
                                        }
                                    >
                                        {ACCESS_SCH.map((val) => {
                                            return (
                                                <MenuItem key={val} value={val}>
                                                    {val}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>       
                                <FormHelperText>Service</FormHelperText>
                                </FormControl>
                                <Button 
                                    onClick={this.handleLoginAttempt}
                                    className={classes.actionButton}
                                    disabled={this.state.loading}
                                    style={{borderRadius: 20}}
                                >
                                    Sign In
                                </Button>
                                <FormHelperText 
                                    style={{color: "firebrick", 
                                            fontSize: '15px', 
                                            textAlign: "center", 
                                            fontWeight:"bold"
                                        }}
                                >
                                    {this.state.helperText}
                                </FormHelperText>
                                {this.state.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </FormControl>

                        </CardContent>
                    </Card>
                        
                </Grid>
                </Grid>
            </div>
        );

    }
}

export default withStyles(styles)(Login);
