// This file is from an online tutorial
import decode from 'jwt-decode';

export default class AuthService {
    // Initializing important variables
    constructor(domain) {
        // Local DB
        //this.domain = 'http://127.0.0.1:8000/api/token/';
        //this.apiServer = 'http://127.0.0.1:8000/api/';
        //this.userdomain = 'http://127.0.0.1:8000/api/v1/user/';
        // Server DB
        this.domain = 'https://api-dev.locoroboapp.com/api/token/';
        this.apiServer = 'https://api-dev.locoroboapp.com/api/';
        this.userdomain = 'https://api-dev.locoroboapp.com/api/v1/user/';
        this.fetch = this.fetch.bind(this); // React binding stuff
        this.lr_login = this.login.bind(this);
        this.getProfile = this.getProfile.bind(this);
        this.sendUserCreation = this.sendUserCreation.bind(this);
        this.authTokens = null;
        this.userName = null;
        this.userPW = null;
        this.userAccess = [];
        this.accessChoice = null;
    };

    login(username, password, service) {
        // Get a token from api server using the fetch api
        return this.fetch(`${this.domain}`, {
            method: 'POST',
            body: JSON.stringify({
                username,
                password
            })
        }).then(res => {
            this.setTokens(res) // Setting the token in localStorage
            this.setService(service);
            this.authTokens = res.access;
            this.userName = username;
            this.userPW = password;
            return Promise.resolve(res);
        })
    };

    loggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getAccessToken() // Getting token from localstorage
        return !!token && !this.isTokenExpired(token) // handwaiving here
        //return !this.isTokenExpired(token);
    }

    validRefresh() {
        const token = this.getRefreshToken() // Getting token from localstorage
        return !!token && !this.isTokenExpired(token) // handwaiving here
    }


    createProgram(name, editor, language, instructions) {

        language = language.toLowerCase();

        return this.handleRefresh().then(() => {
            return this.fetch(`${this.apiServer}v1/program/`, {
                method: 'POST',
                body: JSON.stringify({
                    name,
                    editor,
                    language,
                    instructions,
                })
            }).then(res => {
                if (!this.domain.includes('127.0.0.1')) {
                    res.resource_uri = res.resource_uri.replace('http', 'https');
                }
                    
                return Promise.resolve(res);
            });
        });
    }

    getProductAccess() {
        
        return this.handleRefresh().then(() => {    
            return this.fetch(`${this.userdomain}`, {
                method: 'GET',
            }).then(res => {
                
                if ((res.is_staff === true) || (res.is_superuser === true)) {
                    return Promise.resolve(res);
                } else {
                    return Promise.resolve("error");
                }
            })
        }).catch((err) => { // catch is new
            return Promise.resolve("error");
        });
    }

    getStudentList = (data) => {
        return this.handleRefresh().then(() => {
            return this.fetch(`${this.apiServer}v1/students/`, {
                method: 'POST',
                body: JSON.stringify({
                    data,
                })
            }).then((res) => {
                return Promise.resolve(res);                
            }).catch((err) => {
                return Promise.resolve("error");
            });
        });
    }


    sendUserCreation(users) {
        return this.handleRefresh().then(() => {
            return this.fetch(`${this.apiServer}v1/accounts/`, {
                method: 'POST',
                body: JSON.stringify({
                    users,
                })
            }).catch((err) => {
                console.log(err);
            });
        });
    }



    isTokenExpired(token) {
        try {
            const decoded = decode(token);
            if (decoded.exp < Date.now() / 1000) { // Checking if token is expired. N
                return true;
            }
            else
                return false;
        }
        catch (err) {
            return false;
        }
    }

    setTokens(idToken) {
        // Saves user token to localStorage
        localStorage.setItem('accesss_token', idToken.access);
        localStorage.setItem('refresh_token', idToken.refresh);
    };
    setService(service) {
        localStorage.setItem('service', service);
    }

    getService() {
        return localStorage.getItem('service');
    }

    getAccessToken() {
        // Retrieves the user token from localStorage
        return localStorage.getItem('accesss_token');
    }

    getRefreshToken() {
        // Retrieves the user token from localStorage
        return localStorage.getItem('refresh_token');
    }

    logout() {
        // Clear user token and profile data from localStorage
        localStorage.removeItem('accesss_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('service');
        this.authTokens = null;
        this.userName = null;
    }

    getProfile() {
        // Using jwt-decode npm package to decode the token
        return decode(this.getAccessToken());
    }


    handleRefresh() {
        if (this.loggedIn() === false) {
            return this.checkRefresh().then(res => {
                this.authTokens = res.access;
                localStorage.setItem('accesss_token', res.access);
                return Promise.resolve(res);
            }).catch(() => {
                try {
                    this.logout();
                    setTimeout(window.location.reload(true), 100);
                } catch {}
            });
        } else {
            return Promise.resolve("done");
        }
    }


    checkRefresh() {

        let refresh = this.getRefreshToken();

        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };
        let options = {
            method:'POST',
            body: JSON.stringify({
                refresh,
            }),
        };
        let url = `${this.domain}refresh/`;
        return fetch(url, {
            headers,
            ...options
        })
            .then(this._checkStatus)
            .then(response => response.json())
    
    }


    fetch(url, options) {
        // performs api calls sending the required authentication headers
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }

        // Setting Authorization header
        // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
        if (this.loggedIn()) {
            //headers['Authorization'] = 'Bearer ' + this.getAccessToken()
            headers['Authorization'] = 'Bearer ' + this.getAccessToken()
        }

        return fetch(url, {
            headers,
            ...options
        })
            .then(this._checkStatus)
            .then(response => {
                if (response.status !== 204) {
                    return response.json();
                }
            })
    }

    _checkStatus = (response) => {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
            return response;
        } else {
            if (response.status === 403) {
                this.logout();
            } else {
                var error = new Error(response.statusText)
                console.log(response);
                error.response = response
                //throw error
            }
        }
    }
}
