import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';

import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';



const styles = (theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 300
  },
  indeterminateColor: {
    color: "#f50057"
  },
  selectAllText: {
    fontWeight: 500
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 40, 0.6)", //"rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 20, 20, 0.4)", //"rgba(0, 0, 0, 0.08)"
    }
  }
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center"
  },
  variant: "menu"
};


class MultiSelectAll extends Component {

    state = {
        selected: [],
        isAllSelected: false,
            //(this.props.options.length > 0 && 
            //    this.state.selected.length === this.props.options.length),
        options: [],
        uiEnabled: true,
    }

    updateOptions = (options) => {
        this.setState( {
            options: options,
            isAllSelected: (this.state.options.length > 0 && 
                this.state.selected.length === this.state.options.length),
        } )
    }

    setEnable = (enableState) => {
        this.setState( {uiEnabled: enableState} )
    }

    getSelected = () => {
        return this.state.selected
    }

    clearSelected = () => {
        this.setState({selected: []})
    }

    handleChange = (event) => {
        
        const value = event.target.value;
        if (value[value.length - 1] === "all") {
            this.setState( {
                selected: this.state.selected.length === this.state.options.length ? [] : this.state.options,
                isAllSelected: this.state.options.length > 0 && value.length <= this.state.options.length,
            } )
            return;
        }
        this.setState( {
            selected: value,
            isAllSelected: this.state.options.length > 0 && value.length === this.state.options.length,
        } )
    };

    render() {

        const { classes } = this.props

        const selected = this.state.selected
        const isAllSelected = this.state.isAllSelected
        const options = this.state.options

        return (
            <FormControl className={classes.formControl}
                disabled = {!this.state.uiEnabled}
            >
            <InputLabel id="mutiple-select-label">Multiple Select</InputLabel>
            <Select
                labelId="mutiple-select-label"
                multiple
                value={selected}
                onChange={this.handleChange}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
            >
                <MenuItem
                    value="all"
                    classes={{
                        root: isAllSelected ? classes.selectedAll : ""
                    }}
                >
                <ListItemIcon>
                    <Checkbox
                        color="secondary"
                        classes={{ indeterminate: classes.indeterminateColor }}
                        checked={isAllSelected}
                        indeterminate={
                            selected.length > 0 && selected.length < options.length
                        }
                    />
                </ListItemIcon>
                <ListItemText
                    classes={{ primary: classes.selectAllText }}
                    primary="Select All"
                />
                </MenuItem>
                {options.map((option) => (
                    <MenuItem key={option + this.props.keystr} value={option}
                    >
                        <ListItemIcon>
                        <Checkbox checked={selected.indexOf(option) > -1} 
                            style={{
                                color: "#f50057",
                                '&.MuiChecked': {
                                  color: "#f50057",
                                },
                              }}
                        />
                        </ListItemIcon>
                        <ListItemText primary={option} />
                    </MenuItem>
                ))}
            </Select>
            </FormControl>
        );
    }
}


export default withStyles(styles)(MultiSelectAll);