// Import React and Material-UI Modules
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { FormControl, Typography } from '@material-ui/core';

// Material-UI CSS-type Style Specifications
const styles = (theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      position: 'absolute',
      width: 600,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      borderRadius: 25,
      square: false,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    btnColored: {
        color: theme.palette.primary,
        backgroundColor: theme.palette.primary["light"],
    },
})



// Component Class
class CampaignConfirm extends React.Component {

    // Class constructor
    constructor(props) {
        // Access to this.props
        super(props);

    }

    // Class state
    state = {
        isOpen: false,
        checked: false,
        details: {},
    }

    // For Displaying Self (Modal)
    handleOpen = (details) => {

        this.setState( {isOpen: true, details: details} );
    };

    // For Stopping Program Run
    handleClose = () => {
        this.setState( {isOpen: false, details: {}, checked: false} );
    };

    handleConfirm = () => {
        if (this.state.checked === true) {
            this.props.onConfirm()
            this.setState( {isOpen: false, details: {}, checked: false} );
        }
    }

    handleChangeCheck = (event) => {
        this.setState( {checked: event.target.checked} )
    }

    render() {

        // Referenced below for setting styles
        const { classes } = this.props;

        return (
            <div>
                <Modal
                    className={classes.modal}
                    open={this.state.isOpen}
                    onClose={this.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                <Fade in={this.state.isOpen}>
                    <div className={classes.paper}>
                        <FormControl>
                            {Object.keys(this.state.details).map((val, i) => {

                                return (
                                    <Typography key = {'typ_' + i}>
                                        {this.state.details[val]}
                                    </Typography>
                                )
                            })}

                        </FormControl>
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={this.state.checked}
                                onChange={this.handleChangeCheck}
                                name="checked"
                                color="primary"
                            />
                            }
                            label="I have tested this email content and it matches the above information"
                        />
                        <Button
                            onClick = {this.handleConfirm}
                            className={classes.btnColored}
                        >
                            Schedule Campaigns
                        </Button>
                    </div>
                </Fade>
                </Modal>
            </div>
        )
    }

}
export default withStyles(styles)(CampaignConfirm);