import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';

import Scheduler from "./Scheduler";

import MailerService from "../mailer/MailerService";

import { DOMAIN_DETAILS } from '../store/domainStore'
//import { onlyUnique, sleep } from "../helper/helperFunctions"
//import { Typography } from "@material-ui/core";
import InfoModal from "./modals/InfoModal";
import NavBar from "./NavBar";


const styles = (theme) => ({
    root: {
        maxHeight: '100vh', 
        backgroundColor: 'white', 
        overflow: 'visible',
    },
});

//const VIEW_OPTS_MAP = ["Scheduler", "Groups View",]
const VIEW_OPTS = {
    SCHEDULER: "Scheduler",
    GROUPS: "Groups View",
}

/*function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }*/

class MainPage extends Component {

    constructor(props) {
      super(props);
      
      this.Mailer = new MailerService();

      this.tab0Element = React.createRef()
      this.tab1Element = React.createRef()
      this.InfoModalElement = React.createRef()
      
      this.TrueSubscriberCount = 0;
      this.SubscribersReadIn = 0;
      
      this.SubsFlags = {};
      this.Subscribers = {};
      this.FieldSegments = {};
    }
  
    state = {
        loading: false,
        viewState: VIEW_OPTS.SCHEDULER,  
        tabsVal: 0,
        selectedDomain: DOMAIN_DETAILS.PROD[0],
        refIndex: 0,
        Subscribers: {},
        uiDisable: true,
        pgStr: "",
        paging: false,
    };

    componentDidMount() {
        //this.initialLoad()
        this.checkDomainStatus()
    }

    checkDomainStatus = async () => {

        this.setState( {uiDisable: true} )
        //this.tab0Element.current.enable(true)
        const res = await this.Mailer.fetch_domain_state(DOMAIN_DETAILS.REF[this.state.refIndex])
        //console.log("Domain Busy: ", res.domainState)
        if (res.domainState === false) { // false => not busy, true = busy
            //this.tab0Element.current.enable(false) // is about loading state, poor name choice
            this.tab0Element.current.setIndex(this.state.refIndex)
            this.tab0Element.current.fullEnable()
        } else { // busy, don't enable it
            //this.tab0Element.current.enable(true) // is about loading state
            this.tab0Element.current.setIndex(this.state.refIndex)
        }
        this.setState( {uiDisable: false} )
        setTimeout(this.tab0Element.current.initFieldgroups, 300)
    }

    refreshScheduler = async () => {
        this.checkDomainStatus()
    }

    handleChangeDomain = async (event) => {
        this.setState( {[event.target.name]: event.target.value, uiDisable: true} )

        
        if (this.state.tabsVal === 0) {
            this.tab0Element.current.fullDisable()
        } else if (this.state.tabsVal === 1) {
            this.tab1Element.current.fullDisable()
        }

        let refIndex = -1
        for (let k = 0; k < DOMAIN_DETAILS.REF.length; k++) {
            if (DOMAIN_DETAILS.PROD[k] === event.target.value) {
                refIndex = k
                break
            }
        }


        //await this.tab0Element.current.refresh(refIndex)
        this.tab0Element.current.resetState()
        this.tab0Element.current.fullEnable()
        /*
        if (this.state.tabsVal === 0) {
        } else if (this.state.tabsVal === 1) {
            await this.tab1Element.current.refresh(refIndex)
            this.tab1Element.current.fullEnable()
        }*/
        this.checkDomainStatus()

        this.setState( {refIndex: refIndex, uiDisable: false} )
    }
    
    render() {

        const { classes } = this.props;

        const currentPath = this.props.location.pathname;

        return (
            <div className={classes.root}>
                <NavBar currentPath = {currentPath} />
                <div style = {{display: 'flex'}}>
                    <div style = {{flexGrow: 1}}>
                        <FormControl
                            variant="outlined"
                            disabled = {this.state.uiDisable}
                        >
                            <Select
                                value={this.state.selectedDomain}
                                onChange={this.handleChangeDomain}
                                input={
                                    <OutlinedInput name="selectedDomain" />
                                }
                            >
                                {DOMAIN_DETAILS.PROD.map((val) => {
                                    return (
                                        <MenuItem key={val} value={val}>
                                            {val}
                                        </MenuItem>
                                    );
                                })}
                            </Select>       
                        <FormHelperText >Domain Product</FormHelperText>
                        </FormControl>
                        <Button
                            onClick = {this.refreshScheduler}
                        >
                            Refresh
                        </Button>
                    </div>
                </div>
                <Scheduler 
                    ref = {this.tab0Element}
                />
                <InfoModal
                    ref = {this.InfoModalElement}
                />
            </div>
        )
    }

}

export default withStyles(styles)(MainPage);