import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';

import { Typography, Button, TextField } from "@material-ui/core";

import NavBar from "../NavBar";

import Papa from 'papaparse';

import SendyService from "../../sendy/SendyService";
import { S_DOMAIN_DETAILS } from '../../store/domainStore'
import UnsubCleanConfirm from "../modals/UnsubCleanConfirm";
import InfoDrawer from "../drawers/InfoDrawer";
import { MSGS } from "../../store/msgStore";

const styles = (theme) => ({
    root: {
        maxHeight: '100vh', 
        backgroundColor: 'white', 
        overflow: 'visible',
    },
    mainDiv: {
        display: "flex",
        padding: theme.spacing(1),
        borderRadius: 10,
        border: 'solid 1px',
    },
    cleanUnsubBtn: {
        color: theme.palette.primary,
        backgroundColor: theme.palette.primary["light"],
    },
});


class SUnsubscribers extends Component {

    constructor(props) {
        super(props)
        
        this.SendyService = new SendyService();
        this.ProductElement = React.createRef();
        this.InfoDrawerElement = React.createRef();
        this.cleanConfirmElement = React.createRef();

        this.dataReadyID = null;
    }

    state = {
        uiDisable: false,

        UnsubStart: '',
        UnsubEnd: '',

        domains_data: {},
        numFiles: 0,
    }

    fullEnable = () => {
        this.setState( {uiDisable: false} )
    }

    fullDisable = () => {
        this.setState( {uiDisable: true} )
    }

    handleFileEvent = async (e) => {
        if (e.target.files.length < 2) {
            this.InfoDrawerElement.current.handleOpen(
                MSGS.ERR.S_UNSUBS_NUM_FILES.COLOR, 
                MSGS.ERR.S_UNSUBS_NUM_FILES.MSG,
                MSGS.ERR.S_UNSUBS_NUM_FILES.DELAY,    
            )
            return
        }



        this.setState({files: e.target.files, numFiles: e.target.files.length})
    }

    handleChange = (event) => {
        this.setState( {[event.target.name]: event.target.value} )
    }

    hcleanAction = () => {
        if (this.state.numFiles < 2) {
            this.InfoDrawerElement.current.handleOpen(
                MSGS.ERR.S_UNSUBS_NUM_FILES.COLOR, 
                MSGS.ERR.S_UNSUBS_NUM_FILES.MSG,
                MSGS.ERR.S_UNSUBS_NUM_FILES.DELAY,    
            )
            return
        }

        // Check Dates
        if ((this.state.UnsubStart === "") || (this.state.UnsubEnd === "")) {
            this.InfoDrawerElement.current.handleOpen(
                MSGS.ERR.UNSUB_NO_DATES.COLOR, 
                MSGS.ERR.UNSUB_NO_DATES.MSG,
                MSGS.ERR.UNSUB_NO_DATES.DELAY,    
            )
            return
        }

        // Check which products
        let products = []
        for (let k = 0; k < this.state.numFiles; k++) {
            products.push(this.state.files[k].name)
        }

        // Check
        let mismatch = false
        for (let g = 0; g < products.length; g++) {
            const leading_div = products[g].indexOf("_")
            const start_name = products[g].slice(0, leading_div)
            if (!(S_DOMAIN_DETAILS.REF.includes(start_name))) {
                mismatch = true
            }
        }
        if (mismatch === true) {
            this.InfoDrawerElement.current.handleOpen(
                MSGS.ERR.S_UNSUBS_BAD_FILENAME.COLOR, 
                MSGS.ERR.S_UNSUBS_BAD_FILENAME.MSG,
                MSGS.ERR.S_UNSUBS_BAD_FILENAME.DELAY,    
            )
            return
        }
        
        this.fullDisable()
        for (let k = 0; k < products.length; k++) {
            var me = this;
            const reader = new FileReader();
            reader.readAsText(this.state.files[k]);
            reader.addEventListener('load', function (e) {
                const data = e.target.result;
                Papa.parse(data, {
                    complete: function (results) {
                        let temp_data = me.state.domains_data

                        // Filter Based on Date Ranges
                        const startDate = Date.parse(me.state.UnsubStart)
                        const endDate = Date.parse(me.state.UnsubEnd)        
                        
                        const now_data = results.data
                        let filtered_data = []
                        for (let r = 0; r < now_data.length; r++) {
                            const laci = now_data[r][3].indexOf(",")
                            const lastActivityCut = now_data[r][3].slice(0, laci)
                            const lActDate = Date.parse(lastActivityCut)
                            if ((lActDate >= startDate) && (lActDate <= endDate)) {
                                filtered_data.push(now_data[r][1])
                            }
                        }

                        const leading_div = products[k].indexOf("_")
                        const start_name = products[k].slice(0, leading_div)
                        temp_data[start_name] = filtered_data
                        me.setState({domains_data: temp_data})
                    },
                });
            });
        }

        // check flags
        this.dataReadyID = setTimeout(this.dataReady, 500)

    }

    dataReady = async () => {
        if (Object.keys(this.state.domains_data) < this.state.numFiles) {
            this.dataReadyID = setTimeout(this.dataReady, 250)
        } else {
            // Check Flags
            let has_clearance = true;
            const domain_names = Object.keys(this.state.domains_data)
            for (let k = 0; k < domain_names.length; k++) {

                const res = await this.SendyService.fetch_domain_state(domain_names[k])
                if (res.sDomainState === true) { // false => not busy, true = busy
                    has_clearance = false
                }
            }

            if (has_clearance === false) {
                this.InfoDrawerElement.current.handleOpen(
                    MSGS.ERR.S_DOMAINS_BUSY.COLOR, 
                    MSGS.ERR.S_DOMAINS_BUSY.MSG,
                    MSGS.ERR.S_DOMAINS_BUSY.DELAY,    
                )
                this.fullEnable()
                return
            }

            // Can Move onto Confirmation Check
            const details = {
                products: "Products: " + domain_names.join(','),
                startDate: "Range Start: " + this.state.UnsubStart,
                endDate: "Range End: " + this.state.UnsubEnd,
            }
            this.cleanConfirmElement.current.handleOpen(details)
            

        }

    }

    cleanConfirm = async () => {
        const data = {
            productsSelected: Object.keys(this.state.domains_data),
            domainsData: this.state.domains_data
        }

        // Post Data to Backend To Check and Apply Unsubscribes
        const res = await this.SendyService.post_clean_unsubs(
            "empty", data
        )
        this.fullEnable()
    }



    render() {

        const { classes } = this.props;
        
        const currentPath = this.props.location.pathname;

        return (
            <div className={classes.root}>
                <NavBar currentPath = { currentPath } />

                <div className= {classes.wrapperDiv}>
                    <div className = {classes.mainDiv}>
                        <div style = {{flexGrow: 1}}>
                            <form>
                                <Typography>Import Unsubscribers CSV Files</Typography>
                                <input 
                                    type="file" 
                                    multiple
                                    accept={".csv"}
                                    onChange={this.handleFileEvent} 
                                />
                            </form>
                        </div>    
                        <div style = {{flexGrow: 1}}>
                            <form className={classes.container} noValidate
                                disabled = {this.state.uiDisable}
                            >
                                <TextField
                                    id="date"
                                    label="Unsub Start"
                                    type="date"
                                    name="UnsubStart"
                                    onChange = {this.handleChange}
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </form>
                            <form className={classes.container} noValidate
                                disabled = {this.state.uiDisable}
                            >
                                <TextField
                                    id="date"
                                    label="Unsub End"
                                    type="date"
                                    name="UnsubEnd"
                                    onChange = {this.handleChange}
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </form>
                        </div>


                    </div>
                </div>
                <div className = {classes.mainDiv}>
                    <div style = {{flexGrow: 1}}>
                        <Button 
                            className = {classes.cleanUnsubBtn}
                            disabled = {this.state.uiDisable}
                            onClick={this.hcleanAction}
                        >
                            Clean Unsubscribers
                        </Button>
                    </div>
                </div>
                <UnsubCleanConfirm
                    ref = {this.cleanConfirmElement}
                    onConfirm = {this.cleanConfirm}
                />
                <InfoDrawer
                    ref = {this.InfoDrawerElement}
                    onClose = {() => {}}
                />
            </div>
        );
    }


}
export default withStyles(styles)(SUnsubscribers);