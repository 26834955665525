import MainPage from "../components/MainPage"
import Warmup from "../components/Warmup";

import MCMainPage from "../components/Mailchimp/MCMainPage";
import MCWarmup from "../components/Mailchimp/MCWarmup";

import SMainPage from "../components/Sendy/SMainPage";

import { map_groups } from "../helper/helperFunctions";
import Unsubscribers from "../components/Unsubscribers";
import UploadSubscribers from "../components/UploadSubscribers";
import StatsPage from "../components/StatsPage";
import MCUnsubscribers from "../components/Mailchimp/MCUnsubscribers";
import MCUploadSubscribers from "../components/Mailchimp/MCUploadSubscribers";
import MCStatsPage from "../components/Mailchimp/MCStatsPage";
import SWarmup from "../components/Sendy/SWarmup";
import SUnsubscribers from "../components/Sendy/SUnsubscribers";

export const SUBS_PAGE_LIM = 1000;

export const RATE_PAUSE = {ITER: 100, ACT: 500, POST: 30000};

export const BASE_NAMES_A = map_groups('M', 'A')
export const BASE_NAMES_B = map_groups('M', 'B')
export const BASE_NAMES_C = map_groups('M', 'C')

export const ACCESS_CHOICES = {
    CSchedule: {
        MAILERLITE: MainPage,
        MAILCHIMP: MCMainPage,
        SENDY: SMainPage,
    },
    Warmup: {
        MAILERLITE: Warmup,
        MAILCHIMP: MCWarmup,
        SENDY: SWarmup,
    },
    Unsubscribers: {
        MAILERLITE: Unsubscribers,
        MAILCHIMP: MCUnsubscribers,
        SENDY: SUnsubscribers,
    },
    UploadSubscribers: {
        MAILERLITE: UploadSubscribers,
        MAILCHIMP: MCUploadSubscribers,
    },
    StatsPage: {
        MAILERLITE: StatsPage,
        MAILCHIMP: MCStatsPage,
    }
}
export const ACCESS_SCH = [
    "MAILERLITE",
    "MAILCHIMP",
    "SENDY"
]


//****** Sendy ********//

export const NO_IDS_REGULAR = [
    'LocoXtreme',
    'LocoDrone',
    'TryDrones',
    'LocoDroneScience',
]
export const IDS_WARMUP = {
    'MGTB': [],
    'LocoXtreme': ["B"],
    'LocoDrone': ["A"],
    'TryDrones': ["A"],
    'LocoDroneScience': [],
}


export const S_DOMAIN_DETAILS = {
    REF: [
        "mgtb",
        "lx",
        "ld",
        "td",
        "lds"
    ],
    ACCOUNT: [
        "lisa@locorobo.co",
        "",
        "",
        "",
        "",
    ],
    EMAIL: [
        "education@robotics.moregoodthanbad.org", 
        "education@robot.locoxtreme.com",
        "education@flight.locodrone.com",
        "education@cbl.try-drones.com",
        "edu@learn.dronesandscience.com",
    ],
    LINK: [
        "moregoodthanbad.org",
        "locoxtreme.com",
        "locodrone.com",
        "cbl.try-drones.com",
        "learn.dronesandscience.com",
    ],
    NAME: [
        "More Good Than Bad Blog",
        "LocoXtreme Modern Robotics",
        "LocoDrone Curriculum",
        "TryDrones Competitions",
        "Drones For Science",
    ],
    NAME_TYPE: [
        "MGTB",
        "LX",
        "LD",
        "TD",
        "LDS",
    ],
    PROD: [
        "MGTB",
        "LocoXtreme",
        "LocoDrone", 
        "TryDrones",
        "LocoDroneScience",
    ],
}

//****** End of Sendy ********//


//****** Mailchimp ********//
export const MC_DOMAIN_DETAILS = {
    REF: [
        "intern",
        "td",
        "lx",
        "ld",
        "cmd",
        "cmr"
    ],
    ACCOUNT: [
        "intern@locorobo.co",
        "lisa@liftoff.try-drones.com", //"education@try-drones.com",
        "lisa@coding.locoxtreme.com",
        "",
        "lisa@codemydrones.com",
        "lisa@codemyrobots.com",
    ],
    EMAIL: [
        "intern@locorobo.co", 
        "education@go.try-drones.com",//"education@soar.try-drones.com",
        "robotics@code.locoxtreme.com",
        "education@pathway.locodrone.com", //"edu@pathway.locodrone.com",
        "education@stem.codemydrones.com",
        "code@stem.codemyrobots.com",
    ],
    LINK: [
        "locorobo.com",
        "locorobo.com",
        "locorobo.com",
        "locodrone.com",
        "codemydrones.com",
        "codemyrobots.com",
    ],
    NAME: [
        "Testing",
        "TryDrones Competitions",
        "LocoXtreme Modern Robotics",
        "LocoDrone Curriculum",
        "CodeMyDrones Education",
        "CodeMyRobots Curriculum",
    ],
    NAME_TYPE: [
        "TD",
        "TD",
        "LX",
        "LD",
        "CMD",
        "CMR",
    ],
    PROD: [
        "Testing",
        "TryDrones",
        "LocoXtreme", 
        "LocoDrone",
        "CodeMyDrones",
        "CodeMyRobots",
    ],
}


//****** End of Mailchimp ********//


/*
LocoDrone - liftoff.locodrone.com, education@liftoff.locodrone.com
LocoXtreme - stem.locoxtreme.com, education@stem.locoxtreme.com
TryDrones - stem.try-drones.com, education@stem.try-drones.com
LocoMath - pythoncoding.locomath.co, education@pythoncoding.locomath.co

*/

export const DOMAIN_DETAILS = {
    REF: ["domain1", "domain2", "domain3", "domain4", "domain5", "domain6"],
    FILES: {
        domain1: "api_token.txt",
        domain2: "api_token_locodrone.txt",
        domain3: "api_token_locoxtreme.txt",
        domain4: "api_token_trydrones.txt",
        domain5: "api_token_locomath.txt",
        domain6: "api_token_ds.txt",
    },
    MAILERLITE: [
        "intern@locorobo.co", 
        "education@locorobo.co", 
        "lisa@coding.locoxtreme.com", 
        "education@try-drones.com",
        "education@code.locomath.co",
        "edu@science.locodrone.com",
        "lisa@dronesandscience.com"
    ],
    EMAIL: [
        "intern@locorobo.co", 
        "education@liftoff.locodrone.com",//"education@takeoff.locodrone.com",
        "edu@program.locoxtreme.com",//"robotics@learn.locoxtreme.com",
        "education@stem.try-drones.com",//"education@lift.try-drones.com",
        "education@pythoncoding.locomath.co",//"education@py.locomath.co",
        "edu@learn.dronesandscience.com"
    ],
    LINK: [
        "locorobo.com",
        "locodrone.com",
        "locoxtreme.com",
        "try-drones.com",
        "locomath.co",
        "dronesandscience.com"
    ],
    NAME: [
        "Testing",
        "LocoDrone Curriculum", 
        "LocoXtreme Modern Robotics Curriculum", 
        "TryDrones Competitions",
        "LocoMath Coding Curriculum",
        "Drones For Science"
    ],
    NAME_TYPE: ["TD", "LD", "LX", "TD", 
                "Math", "DFS"],
    PROD: ["Testing", "LocoDrone", "LocoXtreme", "TryDrones", 
            "LocoMath", "DF Science"],
}


export const MC_WARMUP_SEGS_MAP = {
    'A': BASE_NAMES_A,
    'B': BASE_NAMES_B,
    'C': BASE_NAMES_C,
}
export const MC_WARMUP_GROUPS = {
    "Testing": {
        "A": map_groups('M', 'A').concat(map_groups('M', 'B'), map_groups('M', 'C')),
        "B": map_groups('M', 'B').concat(map_groups('M', 'C'), map_groups('M', 'A')),
        "C": map_groups('M', 'C').concat(map_groups('M', 'A'), map_groups('M', 'B')),
    },
    "TryDrones": {
        "A": map_groups('M', 'A').concat(map_groups('M', 'B')),
        "B": map_groups('M', 'B').concat(map_groups('M', 'A')),
    },
    "LocoXtreme": {
        "A": map_groups('H', 'A').concat(map_groups('H', 'B')),
        "B": map_groups('H', 'B').concat(map_groups('H', 'A')),
    },
    "LocoDrone": {
        //"A": map_groups('H', 'A').concat(map_groups('H', 'B')),
        //"B": map_groups('H', 'B').concat(map_groups('H', 'A')),
        "A": map_groups('H', 'A').concat(map_groups('H', 'B'), map_groups('H', 'C')),
        "B": map_groups('H', 'B').concat(map_groups('H', 'C'), map_groups('H', 'A')),
        "C": map_groups('H', 'C').concat(map_groups('H', 'A'), map_groups('H', 'B')),
    },
    "CodeMyDrones": {
        //"B": map_groups('H', 'B').concat(map_groups('H', 'A')),
        //"A": map_groups('H', 'A').concat(map_groups('H', 'B')),
        "A": map_groups('H', 'A').concat(map_groups('H', 'B'), map_groups('H', 'C')),
        "B": map_groups('H', 'B').concat(map_groups('H', 'C'), map_groups('H', 'A')),
        "C": map_groups('H', 'C').concat(map_groups('H', 'A'), map_groups('H', 'B')),
    },
    "CodeMyRobots": {
        "A": map_groups('H', 'A').concat(map_groups('H', 'B')),
        "B": map_groups('H', 'B').concat(map_groups('H', 'A')),
    }
}

export const WARMUP_GROUPS = {
    "Testing": {
        A: map_groups('M', 'A').concat(map_groups('M', 'B')),
        B: map_groups('M', 'B').concat(map_groups('M', 'A')),
    },
    "LocoDrone": {
        A: map_groups('H', 'A').concat(map_groups('H', 'B')),
        B: map_groups('H', 'B').concat(map_groups('H', 'A')),
    },
    "LocoXtreme": {
        A: map_groups('H', 'A').concat(map_groups('H', 'B')),
        B: map_groups('H', 'B').concat(map_groups('H', 'A')),
    },
    "TryDrones": {
        A: map_groups('M', 'A').concat(map_groups('M', 'B')),
        B: map_groups('M', 'B').concat(map_groups('M', 'A')),
    },
    "LocoMath": {
        A: map_groups('M', 'A'),
    },
    "DF Science": {
        A: map_groups('M', 'A'),
    },
}

export const S_WARMUP_GROUPS = {
    "LocoDrone": {
        "A": map_groups('H', 'A').concat(map_groups('H', 'B')),
        "B": map_groups('H', 'B').concat(map_groups('H', 'A'))
    },
    "LocoXtreme": {
        "A": map_groups('H', 'A').concat(map_groups('H', 'B')),
        "B": map_groups('H', 'B').concat(map_groups('H', 'A'))
    },
    "TryDrones": {
        "A": map_groups('M', 'A').concat(map_groups('M', 'B')),
        "B": map_groups('M', 'B').concat(map_groups('M', 'A'))
    },
    "MGTB": {
        "A": map_groups('M', 'A').concat(map_groups('M', 'B')),
        "B": map_groups('M', 'B').concat(map_groups('M', 'A'))
    },
    "LocoDroneScience": {
        "A": map_groups('M', 'A').concat(map_groups('M', 'B')),
    },    
}

export const WARMUP_LETTER_OPTS = {
    "Testing": ['A', 'B', 'C'],
    "MGTB": ['A', 'B'],
    "LocoDrone": ['A', 'B', 'C'],
    "LocoXtreme": ['A', 'B'],
    "TryDrones": ['A', 'B'],
    "LocoMath": ['A'],
    "DF Science": ['A'],
    "CodeMyDrones": ['A', 'B', 'C'],
    "CodeMyRobots": ['A', 'B'],
}

/*export const WARMUP_DAYS = [
    "1", "2", "3", "4", "5", "6", "7", "8", "9", "10",
    "11", "12", "13", "14", "15", "16", "17", "18", "19", "20",
]*/
const WARMUP_DAYS_NUM = 50;
export const WARMUP_DAYS = [...Array(WARMUP_DAYS_NUM)].map((_, i) => `${i+1}`)


export const SEG_FIELD_OPTS = [
    "ES",
    "MS",
    "HS",
    "MS/HS",
    "District",
    "Math",
    "CS",
]

export const FIELD_GROUP_MAP = {
    "TD Week 1: M1_A - F15_A": BASE_NAMES_A,
    "TD Week 2: M1_B - F15_B": BASE_NAMES_B,
    "LX Week 1: H1_A - W15_A": BASE_NAMES_A,
    "LX Week 2: H1_B - W15_B": BASE_NAMES_B,
    "MGTB Week 1: M1_B - F15_B": BASE_NAMES_B,
    "MGTB Week 2: M1_A - F15_A": BASE_NAMES_A,
    "LD Week 1: H1_B - W15_B": BASE_NAMES_B,
    "LD Week 2: H1_A - W15_A": BASE_NAMES_A,
    "Math Week 1: M1_A - F15_A": BASE_NAMES_A,
    "DF Science Week 1: M1_A - F15_A": BASE_NAMES_A,
    "LDS Week 1: M1_A - F15_A": BASE_NAMES_A,
    "CMD Week 1: H1_B - W15_B": BASE_NAMES_B,
    "CMD Week 2: H1_A - W15_A": BASE_NAMES_A,
    "CMR Week 1: H1_A - W15_A": BASE_NAMES_A,
    "CMR Week 2: H1_B - W15_B": BASE_NAMES_B,
}

export const FIELD_GROUP_OPTS = {
    Testing: [
        "TD Week 1: M1_A - F15_A",
        "TD Week 2: M1_B - F15_B",
    ],
    TryDrones: [
        "TD Week 1: M1_A - F15_A",
        "TD Week 2: M1_B - F15_B",
    ],
    LocoXtreme: [
        "LX Week 1: H1_A - W15_A",
        "LX Week 2: H1_B - W15_B",
    ],
    MGTB: [
        "MGTB Week 1: M1_B - F15_B",
        "MGTB Week 2: M1_A - F15_A",
    ],
    LocoDrone: [
        "LD Week 1: H1_B - W15_B",
        "LD Week 2: H1_A - W15_A",
    ],
    LocoMath: [
        "Math Week 1: M1_A - F15_A",
    ],
    'DF Science': [
        "DF Science Week 1: M1_A - F15_A"
    ],
    'LocoDroneScience': [
        "LDS Week 1: M1_A - F15_A"
    ],
    "CodeMyDrones": [
        "CMD Week 1: H1_B - W15_B",
        "CMD Week 2: H1_A - W15_A",
    ],
    "CodeMyRobots": [
        "CMR Week 1: H1_A - W15_A",
        "CMR Week 2: H1_B - W15_B",
    ],
}

export const DAY_MAP = {
    M: {TD: 0, LX: 2, MGTB: 0, LD: 2, Math: 0, DFS: 0, LDS: 0, CMD: 2, CMR: 2, },
    T: {TD: 1, LX: 3, MGTB: 1, LD: 3, Math: 1, DFS: 1, LDS: 1, CMD: 3, CMR: 3, },
    W: {TD: 2, LX: 4, MGTB: 2, LD: 4, Math: 2, DFS: 2, LDS: 2, CMD: 4, CMR: 4, },
    H: {TD: 3, LX: 0, MGTB: 3, LD: 0, Math: 3, DFS: 3, LDS: 3, CMD: 0, CMR: 0, },
    F: {TD: 4, LX: 1, MGTB: 4, LD: 1, Math: 4, DFS: 4, LDS: 4, CMD: 1, CMR: 1, },
}

export const HR_MIN_MAP = {
    1: '07:00',
    2: '08:00',
    3: '09:00',
    4: '10:00',
    5: '11:00',
    6: '12:00',
    7: '13:00',
    8: '14:00',
    9: '15:00',
    10: '16:00',
    11: '17:00',
    12: '18:00',
    13: '19:00',
    14: '20:00',
    15: '21:00',
}

export const WARMUP_PER_HOUR = {
	1: 7,
	2: 7,
	3: 14,
	4: 14,
	5: 27,
	6: 27,
	7: 100,
	8: 100,
	9: 200,
	10: 200,
	11: 400,
	12: 400,
	13: 600,
	14: 600,
	15: 800,
	16: 800,
	17: 1000,
	18: 1000,
	19: 1200,
	20: 1200,
}