import React, { Component } from "react";
import { CSVLink } from "react-csv";

import { Button, Select, FormControl, OutlinedInput, MenuItem, FormHelperText, 
        TextField, withStyles
    } from "@material-ui/core";
    //, List, ListItem, ListItemText, ListItemSecondaryAction, Typography, Grid

import NavBar from "../NavBar";

import { MC_DOMAIN_DETAILS } from "../../store/domainStore";
import MailchimpService from "../../mailchimp/MailchimpService";
import InfoDrawer from "../drawers/InfoDrawer";
//import { MSGS } from "../../store/msgStore";
import { formatNowDate } from "../../helper/helperFunctions";

const styles = (theme) => ({
    root: {
        maxHeight: '100vh', 
        backgroundColor: 'white', 
        overflow: 'visible',
    },
    container: {
        padding: '10px',
    },
    basicBtn: {
        color: theme.palette.primary,
        backgroundColor: theme.palette.primary["light"],
    },
    listItemTextTitle: {
        //fontSize: '20px',
        fontWeight: 'bold',
        color: 'black',
    },
    listItemTextSecondaryTitle: {
        //fontSize: '20px',
        fontWeight: 'bold',
        color: 'black',
    },
    cfList1: {
        border: '1px solid blue',
        maxHeight: '250px',
        overflow: 'auto',
    }
});



class MCStatsPage extends Component {

    constructor(props) {
        super(props)

        this.Mailchimp = new MailchimpService();

        this.mailchimpGraph = React.createRef();
        this.InfoDrawerElement = React.createRef();
    }

    state = {
        domainIndex: 0,

        csvCampaignData: [],
        csvCampaignName: "",
        csvReady: false,
        
        csvBouncesData: [],
        csvBouncesName: "",
        csvBouncesRdy: false,

        canGetStats: false,

        selectedDomain: MC_DOMAIN_DETAILS.PROD[0],
        StartDate: '',
        EndDate: '',
        StartDateForms: '',
        EndDateForms: '',
        uiDisable: true,

        cf_products_arr: [],
        cf_products: {},
        cf_formnames_arr: [],
        cf_formnames: {},
        cf_ml_camps_arr: [],
        cf_ml_camps: {}, 
    }

    
    componentDidMount() {
        this.checkDomainStatus(this.state.domainIndex)
    }
    

    checkDomainStatus = async (domainIndex) => {
        
        this.setState( {uiDisable: true} )
        const res = await this.Mailchimp.fetch_domain_state(MC_DOMAIN_DETAILS.REF[domainIndex])
        if (Object.keys(res).length > 0) {
            if (res.mcDomainState === false) { // false => not busy, true = busy
                this.setState( {uiDisable: false} )
            } else { // busy, don't enable it
                this.setState( {uiDisable: true} )
            }       
        }
    }

    handleChangeDomain = async (event) => {
        this.setState( {[event.target.name]: event.target.value} )
        
        let refIndex = -1
        for (let k = 0; k < MC_DOMAIN_DETAILS.REF.length; k++) {
            if (MC_DOMAIN_DETAILS.PROD[k] === event.target.value) {
                refIndex = k
                break
            }
        }
        this.checkDomainStatus(refIndex)
        this.setState({domainIndex: refIndex, csvReady: false, csvBouncesRdy: false})
    }

    refreshDomainFlags = async () => {
        this.checkDomainStatus(this.state.domainIndex)
    }

    handleChangeOnly = (event) => {
        this.setState( {[event.target.name]: event.target.value} )
    }

    getCampaignData = async () => {
        const res = await this.Mailchimp.get_campaign_stats(MC_DOMAIN_DETAILS.REF[this.state.domainIndex])
        const resUnpacked = JSON.parse(res)
        // For Files
        
        // Assemble Data For Files
        let filedata = []
        let campaign_data = []
        filedata.push([
            "campaign_title", "send_time",
            "opens_total", "unique_opens",
            "clicks_total", "unique_clicks",
            "unsubscribed", "emails_sent"
        ])
        for (let e = 0; e < resUnpacked.length; e++) {
            filedata.push([
                resUnpacked[e]["campaign_title"], resUnpacked[e]["send_time"],
                resUnpacked[e]["opens"]["opens_total"], resUnpacked[e]["opens"]["unique_opens"],
                resUnpacked[e]["clicks"]["clicks_total"], resUnpacked[e]["clicks"]["unique_clicks"],
                resUnpacked[e]["unsubscribed"], resUnpacked[e]["emails_sent"]
            ])
            for (let q = 0; q < resUnpacked[e]["sbouncers"].length; q++) {
                campaign_data.push([
                    resUnpacked[e]["campaign_title"],
                    resUnpacked[e]["sbouncers"][q],
                ])
            }
        }
        
        // Generate File Name - range would be too much
        const nowDateStr = formatNowDate()
        const filename = nowDateStr + '_MC_CStats_' + MC_DOMAIN_DETAILS.PROD[this.state.domainIndex]
        const filenamebounces = nowDateStr + '_MC_SBounces_' + MC_DOMAIN_DETAILS.PROD[this.state.domainIndex]
        
        // Enable CSV download
        this.setState({
            csvReady: true, 
            csvCampaignName: filename,
            csvCampaignData: filedata,
            csvBouncesRdy: true,
            csvBouncesName: filenamebounces,
            csvBouncesData: campaign_data,
        })

        // For Graphing

        // Get Rid of Hrs/Mins/Secs/TZ
        for (let k = 0; k < resUnpacked.length; k++) {
            resUnpacked[k]["send_time"] = resUnpacked[k]["send_time"].slice(0, resUnpacked[k]["send_time"].indexOf('T'))
        }
        // first find unique days
        const unique_dates_unsorted = [...new Set(resUnpacked.map(item => item["send_time"]))];
        
        const unique_dates = unique_dates_unsorted.sort(function(a,b) {
            a = a.split('-').join('');
            b = b.split('-').join('');
            return a.localeCompare(b);
        });

        //Total unique_clicks_count for day / total unique_opens_count for day
        //Total unique_opens_count for day / total sends for day
        //Total unsubscribes_count for day / total sends for day
        let uni_click_per_open = [];
        let uni_open_per_sent = [];
        let unsub_per_sent = [];
        let clicks_per_opens = [];
        
        // Iterate through Unique days
        for (let t = 0; t < unique_dates.length; t++) {
            // Get all days matching the current unique day
            const filteredDate = resUnpacked.filter(item => {
                return item.send_time === unique_dates[t];
            });
            
            let total_unique_opens_count = 0
            let total_unique_clicks_count = 0
            let total_unsubs_count = 0
            let total_sends = 0
            let total_clicks_count = 0
            let total_opens_count = 0
            // Iterate through day's data
            for (let u = 0; u < filteredDate.length; u++) {
                total_unique_clicks_count += filteredDate[u].clicks.unique_clicks;
                total_unique_opens_count += filteredDate[u].opens.unique_opens;
                total_unsubs_count += filteredDate[u].unsubscribed;
                total_sends += filteredDate[u].emails_sent;

                total_clicks_count += filteredDate[u].clicks.clicks_total;
                total_opens_count  += filteredDate[u].opens.opens_total;
            }
            
            uni_click_per_open.push(total_unique_clicks_count / total_unique_opens_count);
            uni_open_per_sent.push(total_unique_opens_count / total_sends);
            unsub_per_sent.push(total_unsubs_count / total_sends);
            clicks_per_opens.push(total_clicks_count / total_opens_count);
        }

        let data = []
        data.push({
            x: unique_dates,
            y: uni_click_per_open,
            type: 'lines+markers',
            name: 'Click Rate'
        })
        data.push({
            x: unique_dates,
            y: uni_open_per_sent,
            type: 'lines+markers',
            name: 'Open Rate'
        })
        data.push({
            x: unique_dates,
            y: unsub_per_sent,
            type: 'lines+markers',
            name: 'Unsubscribes'
        })
        data.push({
            x: unique_dates,
            y: clicks_per_opens,
            type: 'lines+markers',
            name: 'Clicks Per Opens'
        })

        this.fillCampaignGraph(data)
    }

    assembleCampaignData = async () => {
        // Date Ranges
        const refDateStart = new Date(this.state.StartDate + "T00:00:00")
        const refDateEnd = new Date(this.state.EndDate + "T00:00:00")
        
        // Data Object
        const data = {
            start_date: refDateStart,
            end_date: refDateEnd
        }

        await this.Mailchimp.post_campaign_stats(MC_DOMAIN_DETAILS.REF[this.state.domainIndex], data)
        
        this.setState( {
            canGetStats: true,
            uiDisable: true
        } )
    }

    fillCampaignGraph = async (data) => {

        const layout = {}        
        
        await window.Plotly.newPlot(this.mailchimpGraph.current, data, layout);

    }




    render() {

        const { classes } = this.props

        const currentPath = this.props.location.pathname;

        return (
            <div className={classes.root}>
                <NavBar currentPath = {currentPath} />
                <div style = {{display: 'flex'}}>
                    <div style = {{flexGrow: 1}}>
                        <FormControl
                            variant="outlined"
                        >
                            <Select
                                value={this.state.selectedDomain}
                                onChange={this.handleChangeDomain}
                                input={
                                    <OutlinedInput name="selectedDomain" />
                                }
                            >
                                {MC_DOMAIN_DETAILS.PROD.map((val) => {
                                    return (
                                        <MenuItem key={val} value={val}>
                                            {val}
                                        </MenuItem>
                                    );
                                })}
                            </Select>       
                        <FormHelperText >Domain Product</FormHelperText>
                        </FormControl>
                        <Button
                            onClick = {this.refreshDomainFlags}
                        >
                            Refresh
                        </Button>
                    </div>
                </div>
                <div style = {{display: 'flex'}}>
                    <form className={classes.container} noValidate>
                        <TextField
                            disabled = {this.state.uiDisable}
                            id="datesm"
                            label="Start Date"
                            type="date"
                            name="StartDate"
                            onChange = {this.handleChangeOnly}
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </form>
                    <form className={classes.container} noValidate>
                        <TextField
                            disabled = {this.state.uiDisable}
                            id="dateem"
                            label="End Date"
                            type="date"
                            name="EndDate"
                            onChange = {this.handleChangeOnly}
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </form>
                    <Button
                        disabled = {this.state.uiDisable}
                        onClick = {this.assembleCampaignData}
                        className = {classes.basicBtn}
                    >
                        Assemble Campaign Data
                    </Button>
                    {(this.state.canGetStats) && (
                        <Button
                          disabled = {this.state.uiDisable}
                          onClick = {this.getCampaignData}
                          className = {classes.basicBtn}
                        >
                          Get Campaign Data
                        </Button>
                    )}
                    {(this.state.csvReady) && (
                        <CSVLink 
                            data={this.state.csvCampaignData} 
                            filename={this.state.csvCampaignName}
                        >Download Campaigns Stats</CSVLink>
                    )}
                    {(this.state.csvBouncesRdy) && (
                        <CSVLink 
                            data={this.state.csvBouncesData} 
                            filename={this.state.csvBouncesName}
                        >Download Campaigns Soft Bouncers</CSVLink>
                    )}
                </div>
                <div style = {{display: 'flex', border: '1px solid'}}>
                    {(this.state.csvReady) && (
                        <div ref={this.mailchimpGraph}/>
                    )}
                </div>
                
                {/* 
                <div style = {{display: 'flex', border: '1px solid', padding: '5px'}}>
                    
                    <form className={classes.container} noValidate>
                        <TextField
                            disabled = {this.state.uiDisable}
                            id="datesc"
                            label="Start Date"
                            type="date"
                            name="StartDateForms"
                            onChange = {this.handleChangeOnly}
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </form>
                    <form className={classes.container} noValidate>
                        <TextField
                            disabled = {this.state.uiDisable}
                            id="dateec"
                            label="End Date"
                            type="date"
                            name="EndDateForms"
                            onChange = {this.handleChangeOnly}
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </form>
                    <Button
                        disabled = {this.state.uiDisable}
                        onClick = {this.readFormsData}
                        className = {classes.basicBtn}
                    >
                        Read in Cognito Forms Data
                    </Button>
                </div>
                */}
                {/* UI for Cognito Forms */}
                {/*
                <div style = {{display: 'flex', border: '1px solid', padding: '5px'}}>
                {(this.state.cf_products_arr.length > 0) && (
                    <Grid container justifyContent="center">
                        <Grid
                            spacing={1}
                            container
                            className={classes.grid}
                        >
                        <Grid item xs={3}>
                            <List className={classes.cfList1}>
                                <ListItem key={'ltitle'}>
                                    <ListItemText primary={'Form Product'} 
                                        classes={{primary:classes.listItemTextTitle}}
                                    />
                                    <ListItemSecondaryAction>
                                        <Typography  className={classes.listItemTextSecondaryTitle}>
                                            {'Count'}
                                        </Typography>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                {this.state.cf_products_arr.map((val, i) => {
                                    return (
                                    <ListItem key={i}>
                                        <ListItemText primary={val} />
                                        <ListItemSecondaryAction>
                                            <Typography>
                                                {this.state.cf_products[val].count}
                                            </Typography>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    )
                                })}

                            </List>
                        </Grid>
                        <Grid item xs={3}>
                            <List className={classes.cfList1}>
                                <ListItem key={'ltitle'}>
                                    <ListItemText primary={'Form Name'} 
                                        classes={{primary:classes.listItemTextTitle}}
                                    />
                                    <ListItemSecondaryAction>
                                        <Typography  className={classes.listItemTextSecondaryTitle}>
                                            {'Count'}
                                        </Typography>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                {this.state.cf_formnames_arr.map((val, i) => {
                                    return (
                                    <ListItem key={i}>
                                        <ListItemText primary={val} />
                                        <ListItemSecondaryAction>
                                            <Typography>
                                                {this.state.cf_formnames[val]}
                                            </Typography>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    )
                                })}

                            </List>
                        </Grid>
                        <Grid item xs={6}>
                            <List className={classes.cfList1}>
                                <ListItem key={'ltitle'}>
                                    <ListItemText primary={'Campaign Name'} 
                                        classes={{primary:classes.listItemTextTitle}}
                                    />
                                    <ListItemSecondaryAction>
                                        <Typography  className={classes.listItemTextSecondaryTitle}>
                                            {'Total Sent'}
                                        </Typography>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                {this.state.cf_ml_camps_arr.map((val, i) => {
                                    return (
                                    <ListItem key={i}>
                                        <ListItemText primary={val} />
                                        <ListItemSecondaryAction>
                                            <Typography>
                                                {this.state.cf_ml_camps[val]}
                                            </Typography>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    )
                                })}

                            </List>
                        </Grid>
                    </Grid>
                    </Grid>
                )}
                </div>
                */}
                <InfoDrawer
                    ref = {this.InfoDrawerElement}
                    onClose = {() => {}}
                />

            </div>
        )


    }

}
export default withStyles(styles)(MCStatsPage)