import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';

import { Typography, Button, TextField } from "@material-ui/core";

import NavBar from "../NavBar";
import MultiSelectAll from "../MultiSelectAll";

import MailchimpService from "../../mailchimp/MailchimpService";
import { MC_DOMAIN_DETAILS } from '../../store/domainStore'
import UnsubCleanConfirm from "../modals/UnsubCleanConfirm";
import InfoDrawer from "../drawers/InfoDrawer";
import InfoModal from "../modals/InfoModal";
import { MSGS } from "../../store/msgStore";

const styles = (theme) => ({
    root: {
        maxHeight: '100vh', 
        backgroundColor: 'white', 
        overflow: 'visible',
    },
    mainDiv: {
        display: "flex",
        padding: theme.spacing(1),
        borderRadius: 10,
        border: 'solid 1px',
    },
    cleanUnsubBtn: {
        color: theme.palette.primary,
        backgroundColor: theme.palette.primary["light"],
    },
});


class MCUnsubscribers extends Component {

    constructor(props) {
        super(props)
        
        this.Mailchimp = new MailchimpService();
        this.ProductElement = React.createRef();
        this.InfoDrawerElement = React.createRef();
        this.InfoModalElement = React.createRef();
        this.cleanConfirmElement = React.createRef();
    }

    state = {
        uiDisable: false,

        UnsubStart: '',
        UnsubEnd: '',
    }

    componentDidMount() {

        this.delayedUpdate()
    }

    delayedUpdate = () => {
        setTimeout(this.updateProdSelect, 100)
        setTimeout(this.fullEnable, 100)
    }

    updateProdSelect = () => {
        this.ProductElement.current.updateOptions(MC_DOMAIN_DETAILS.PROD)
    }

    fullEnable = () => {
        this.ProductElement.current.setEnable(true)
        this.setState( {uiDisable: false} )
    }

    fullDisable = () => {
        this.ProductElement.current.setEnable(false)
        this.setState( {uiDisable: true} )
    }

    handleChangeOnly = (event) => {
        this.setState( {[event.target.name]: event.target.value} )
    }


    hcleanAction = async() => {

        // Check for Products selected
        const ProductsSelected = this.ProductElement.current.getSelected()
        if (ProductsSelected.length <= 1) {
            this.InfoDrawerElement.current.handleOpen(
                MSGS.ERR.UNSUB_NO_PROD.COLOR, 
                MSGS.ERR.UNSUB_NO_PROD.MSG,
                MSGS.ERR.UNSUB_NO_PROD.DELAY,    
            )
            return
        }

        // Check Dates
        if (this.state.UnsubStart === "") {
            this.InfoDrawerElement.current.handleOpen(
                MSGS.ERR.UNSUB_NO_DATES.COLOR, 
                MSGS.ERR.UNSUB_NO_DATES.MSG,
                MSGS.ERR.UNSUB_NO_DATES.DELAY,    
            )
            return
        }

        // Check mc domain flag states
        //ProductsSelected
        let flags_busy = false;
        // for each in ProductsSelected
            // check flag
        for (let f = 0; f < ProductsSelected.length; f++) {
            // Get Domain Index
            const domainIndx = MC_DOMAIN_DETAILS.PROD.indexOf(ProductsSelected[f])
            // Read Flag
            const res = await this.Mailchimp.fetch_domain_state(MC_DOMAIN_DETAILS.REF[domainIndx])
            if (res.mcDomainState === true) { // false => not busy, true = busy
                flags_busy = true;
                break
            }
        }

        if (flags_busy === true) {
            this.InfoDrawerElement.current.handleOpen(
                MSGS.ERR.UNSUB_DOMAIN_BUSY.COLOR, 
                MSGS.ERR.UNSUB_DOMAIN_BUSY.MSG,
                MSGS.ERR.UNSUB_DOMAIN_BUSY.DELAY,    
            )
            return
        }
        
        // 

        const details = {
            products: "Products: " + ProductsSelected.join(','),
            startDate: "Range Start: " + this.state.UnsubStart,
        }
        this.cleanConfirmElement.current.handleOpen(details)
    }


    cleanConfirm = async () => {
        this.fullDisable()

        const ProductsSelected = this.ProductElement.current.getSelected()

        const startDate = new Date(this.state.UnsubStart + "T00:00:00")

        const data = {
            productsSelected: ProductsSelected,
            domainProdList: MC_DOMAIN_DETAILS.PROD,
            domainRefList: MC_DOMAIN_DETAILS.REF,
            startDate: startDate,
        }

        await this.Mailchimp.post_clean_unsubs("empty", data)
        this.fullEnable()
    }

    render() {

        const { classes } = this.props;
        
        const currentPath = this.props.location.pathname;

        return (
            <div className={classes.root}>
                <NavBar currentPath = { currentPath } />

                <div className = {classes.mainDiv}>
                    <div style = {{flexGrow: 1}}>
                        <Typography>
                            Product Selection
                        </Typography>
                        <MultiSelectAll
                            ref = {this.ProductElement}
                        />
                    </div>
                    
                    <div style = {{flexGrow: 1}}>
                        <form className={classes.container} noValidate
                            disabled = {this.state.uiDisable}
                        >
                            <TextField
                                id="date"
                                label="Unsub Start"
                                type="date"
                                name="UnsubStart"
                                onChange = {this.handleChangeOnly}
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </form>
                    </div> 
                </div>
                <div className = {classes.mainDiv}>
                    <div style = {{flexGrow: 1}}>
                        <Button 
                            className = {classes.cleanUnsubBtn}
                            disabled = {this.state.uiDisable}
                            onClick={this.hcleanAction}
                        >
                            Clean Unsubscribers
                        </Button>
                    </div>
                </div>
                <UnsubCleanConfirm
                    ref = {this.cleanConfirmElement}
                    onConfirm = {this.cleanConfirm}
                />
                <InfoDrawer
                    ref = {this.InfoDrawerElement}
                    onClose = {() => {}}
                />
                <InfoModal
                    ref = {this.InfoModalElement}
                />
            </div>
        );
    }

}

export default withStyles(styles)(MCUnsubscribers);    