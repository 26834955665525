import React, { Component } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles'
import './App.css';

import { Route, HashRouter, Switch } from 'react-router-dom'
//import Main from './components/MainPage'
import Login from './components/Login'
import ScrollToTop from './components/ScrollTop'
import PrivateRoute from './PrivateRoute';
import { AuthContext } from "./context/auth";

import AuthService from './components/AuthService';

//import Routes from './routes'
import { indigo } from '@material-ui/core/colors'

class App extends Component {

  constructor(props) {
    super(props);

    this.Auth = new AuthService();
    
  }

  state = {
    data: null,
    ready: false,
  };

  componentDidMount() {
    // Call our fetch function below once the component mounts
    
    this.callBackendAPI()
      .then(res => this.setState({ data: res.express }))
      .catch(err => console.log(err));
    
    const refToken = this.Auth.getRefreshToken();
    
    if (refToken) {      
      const refAttempt = this.Auth.validRefresh();
      if (refAttempt) {
        //this.refreshAccess();
        const tokenA = this.Auth.getAccessToken();
        this.Auth.authTokens = tokenA;
        const service = this.Auth.getService();
        this.Auth.accessChoice = service;
      } else {
        this.Auth.logout();
        this.setState( {ready: true} );
      }
    } else {
      this.setState( {ready: true} );
    }
    
    
    this.setState( {ready: true} );




    /*
    const tokenA = this.Auth.getAccessToken();
    if (tokenA) {
      try {
        this.refreshAccess();
        this.Auth.authTokens = tokenA;
      } catch {
        this.Auth.logout();
        this.setState( {ready: true} )
      }
    } else {
      this.setState( {ready: true} )
    }
    */
  }

  refreshAccess = () => {
    this.Auth.getProductAccess().then(() => {
      this.Auth.getProgramList().then(() => {
        this.setState( {ready: true} )
      }).catch(() => {
        this.Auth.logout();
      });
    }).catch(() => {
      this.Auth.logout();
    });
  }


  // Fetches our GET route from the Express server. (Note the route we are fetching matches the GET route from server.js
  callBackendAPI = async () => {
    const response = await fetch('/express_backend');
    const body = await response.json();

    if (response.status !== 200) {
      throw Error(body.message) 
    }
    return body;
  };
 

  render() {

    return (
      <div style={{ maxHeight: '100vh', backgroundColor: 'white', overflow: 'visible'}}>
        {(this.state.ready === true) && (
          <ThemeProvider theme={theme}>
            <AuthContext.Provider value={{ AuthInstance: this.Auth }}>
              <HashRouter>
                <ScrollToTop>
                  <Switch>
                    <PrivateRoute exact path='/' component={ "CSchedule" } />
                    <Route exact path='/login' component={Login} />
                    <PrivateRoute exact path='/main' component={ "CSchedule" } />
                    <PrivateRoute exact path='/unsub' component={ 'Unsubscribers' } />
                    <PrivateRoute exact path='/warmup' component={ 'Warmup' } />
                    <PrivateRoute exact path='/uploadsubscribers' component={ 'UploadSubscribers' } />
                    <PrivateRoute exact path='/stats' component={ 'StatsPage' } />
                  </Switch>
                </ScrollToTop>
              </HashRouter>
            </AuthContext.Provider>
          </ThemeProvider>
        )}
      </div>
    );
  }
}

const theme = createTheme({
  palette: {
    secondary: {
      main: indigo[50]
    },
    primary: {
      main: indigo[500]//'#058af4'//blue[500]
    }
  },  
});




export default App;