import React, { Component } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Link, withRouter } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import MenuConstants from "./MenuConstants";
import { Button, Typography } from "@material-ui/core";
import { AuthContext } from "../context/auth";

const styles = theme => ({
    appBar: {
      position: "relative",
      boxShadow: "none",
      borderBottom: `1px solid ${theme.palette.grey["100"]}`,
      backgroundColor: theme.palette.primary["light"],//"white"
      color: "white",
      height: window.navBarOffset,
    },
    flex: {
      display: "flex",
    },
    tabContainer: {
      color: "white",
      marginLeft: 32,
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    tabItem: {
      minWidth: "auto"
    },
});



class NavBar extends Component {



    state = {

    }

    static contextType = AuthContext;
    

    handleChange = (event, value) => {
      this.setState({ value });
    }

    handleLogout = () => {
      this.context.AuthInstance.logout();
      this.props.history.replace('/login');
    }

    current = () => {
        if (this.props.currentPath === "/main") {
          return 0;
        }
        if (this.props.currentPath === "/warmup") {
          return 1;
        }
        if (this.props.currentPath === "/unsub") {
          return 2;
        }
        if (this.props.currentPath === "/uploadsubscribers") {
          return 3;
        }
        if (this.props.currentPath === "/stats") {
          return 4;
        }
        if (this.props.currentPath === "/") {
          return 0;
        }
      };

    render() {

        const { classes } = this.props;

        return (
            <AppBar position="absolute" color="default" className={classes.appBar}>
                <Toolbar>
                    <Grid container alignItems="center">
                        <Grid item xs={12} className={classes.flex}>
                            <Typography>
                              {this.context.AuthInstance.accessChoice}
                            </Typography>
                            <div className={classes.tabContainer}>
                                <Tabs
                                  value={this.current()}
                                  indicatorColor="secondary"
                                  textColor="secondary"
                                  onChange={this.handleChange}
                                >
                                {MenuConstants[this.context.AuthInstance.accessChoice].map((item, index) => {

                                    return (
                                        <Tab
                                            key={index}
                                            component={Link}
                                            href={null}
                                            to={
                                                {
                                                    pathname: item.pathname,
                                                    search: this.props.location.search
                                                }
                                            }
                                            target={null}
                                            rel = {null}
                                            classes={{ root: classes.tabItem }}
                                            label={item.label}
                                        />
                                    );
                                })}
                                </Tabs>
                            </div>
                            <Button
                              style = {{backgroundColor: "white"}}
                              onClick = {this.handleLogout}
                            >
                              Logout
                            </Button>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>      
        );
    }
}


export default withRouter(withStyles(styles)(NavBar));