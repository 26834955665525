import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';

import { CSVLink } from "react-csv";

import NavBar from "../NavBar";
import { BASE_NAMES_A, BASE_NAMES_B, BASE_NAMES_C, MC_DOMAIN_DETAILS, WARMUP_DAYS, MC_WARMUP_GROUPS, WARMUP_LETTER_OPTS, MC_WARMUP_SEGS_MAP } from "../../store/domainStore";
import { FormHelperText, OutlinedInput, Select, FormControl, MenuItem, TextField, Button, Typography, Input } from "@material-ui/core";
import MailchimpService from "../../mailchimp/MailchimpService";
import { formatNowDate } from "../../helper/helperFunctions";
import { MSGS } from "../../store/msgStore";
import InfoDrawer from "../drawers/InfoDrawer";
import CampaignConfirm from "../modals/CampaignConfirm";
import MultiSelectAll from "../MultiSelectAll";

const styles = (theme) => ({
    root: {
        maxHeight: '100vh', 
        backgroundColor: 'white', 
        overflow: 'visible',
    },
    buttonStyle: {
        color: theme.palette.primary,
        backgroundColor: theme.palette.primary["light"],
    },
    mainDiv: {
        display: "flex",
        padding: theme.spacing(1),
    },
    wrapperDiv: {
        borderRadius: 10,
        border: 'solid 1px',
    },
});




class MCWarmup extends Component {

    constructor(props) {
        super(props);
        
        this.Mailchimp = new MailchimpService();
  
        this.InfoDrawerElement = React.createRef();
        this.CampConfirmElement = React.createRef();
        this.WarmupDaysElement = React.createRef();
    }
  
    state = {
        loading: false,
        selectedDomain: MC_DOMAIN_DETAILS.PROD[0],
        uiDisable: false,
        domainIndex: 0,
        selectedLetter: WARMUP_LETTER_OPTS[MC_DOMAIN_DETAILS.PROD[0]][0],
        selectedLetterC: WARMUP_LETTER_OPTS[MC_DOMAIN_DETAILS.PROD[0]][0],

        templateData: [],
        templateSelectOpts: [],
        templateSelect: '',
        templateFilter: '',
        
        emailSubject: '',
        StartingMonday: '',
        campaignExtraName: '',
        previewText: '',

        showDownload: false,
        canGetFile: false,
    };

    componentDidMount() {
        this.initWarmupDays()
        this.checkDomainStatus(this.state.domainIndex)
    }
    
    initWarmupDays = () => {
        this.WarmupDaysElement.current.updateOptions(WARMUP_DAYS)
    }

    checkDomainStatus = async (domainIndex) => {

        this.setState( {uiDisable: true} )
        const res = await this.Mailchimp.fetch_domain_state(MC_DOMAIN_DETAILS.REF[domainIndex])
        if (Object.keys(res).length > 0) {
            if (res.mcDomainState === false) { // false => not busy, true = busy
                await this.fetchTemplates(domainIndex)
                this.setState( {uiDisable: false} )
            } else { // busy, don't enable it
                this.setState( {uiDisable: true} )
            }       
        }
    }
    
    fetchTemplates = async (domainIndex) => {
        const res = await this.Mailchimp.get_templates(MC_DOMAIN_DETAILS.REF[domainIndex])
        // res => Array [{id: , name: }]
        const resUnpacked = JSON.parse(res)
        let tempNames = []
        for (let i = 0; i < resUnpacked.length; i++) {
            tempNames.push(resUnpacked[i].name)
        }
        this.setState({
            templateData: resUnpacked,
            templateSelectOpts: tempNames,
            templatesFull: tempNames,
            templateSelect: tempNames[0],
        })
    }

    refreshDomainFlags = async () => {
        this.checkDomainStatus(this.state.domainIndex)
    }

    handleChangeDomain = async (event) => {
        this.setState( {
            [event.target.name]: event.target.value,
            showDownload: false, 
            canGetFile: false
        } )

        this.WarmupDaysElement.current.updateOptions(WARMUP_DAYS)

        let refIndex = -1
        for (let k = 0; k < MC_DOMAIN_DETAILS.REF.length; k++) {
            if (MC_DOMAIN_DETAILS.PROD[k] === event.target.value) {
                refIndex = k
                break
            }
        }
        this.checkDomainStatus(refIndex)
        this.setState({domainIndex: refIndex})
    }

    handleChangeOnly = (event) => {
        this.setState( {[event.target.name]: event.target.value} )
    }

    assignWarmup = async () => {

        // Check Segments Exist
        this.setState( {
            uiDisable: true,
            canGetFile: true,
            showDownload: false,
        } );
        // Read Segments
        const res = await this.Mailchimp.get_segments(MC_DOMAIN_DETAILS.REF[this.state.domainIndex])
        // Check if All Exist
        let missing = []
        for (let i = 0; i < BASE_NAMES_A.length; i++) {
            if (!res.includes(BASE_NAMES_A[i])) {
                missing.push(BASE_NAMES_A[i]);
            }
        }
        for (let i = 0; i < BASE_NAMES_B.length; i++) {
            if (!res.includes(BASE_NAMES_B[i])) {
                missing.push(BASE_NAMES_B[i]);
            }
        }
        for (let i = 0; i < BASE_NAMES_C.length; i++) {
            if (!res.includes(BASE_NAMES_C[i])) {
                missing.push(BASE_NAMES_C[i]);
            }
        }
        
        // If Don't Exist Already
        if (missing.length > 0) {
            this.InfoDrawerElement.current.handleOpen(
                MSGS.ERR.GROUP_SEGMENTS_MISSING.COLOR, 
                MSGS.ERR.GROUP_SEGMENTS_MISSING.MSG,
                MSGS.ERR.GROUP_SEGMENTS_MISSING.DELAY,
            )
        }

        // Assemble Full Information
        const data = {
            start_week_letter: this.state.selectedLetter
        }

        await this.Mailchimp.post_assign_warmup(
            MC_DOMAIN_DETAILS.REF[this.state.domainIndex],
            data
        )
    }

    assignWarmupGet = async () => {
        
        this.setState({
            uiDisable: true,
        });

        // Get Data
        const res = await this.Mailchimp.get_assign_warmup(
            MC_DOMAIN_DETAILS.REF[this.state.domainIndex]
        )

        // Store Data and Set Filename
        const resUnpacked = JSON.parse(res)
        const nowDateStr = formatNowDate()
        const filename = nowDateStr + '_' + MC_DOMAIN_DETAILS.PROD[this.state.domainIndex]
        this.setState({
            csvData: resUnpacked,
            fileName: filename
        })

        setTimeout(() => {
            // Update State
            this.setState({
                uiDisable: false,
                canGetFile: false,
                showDownload: true,
            });
        }, 100);
    }

    //    
    hCampaignCreation = async () => {

        // Check For Correct Start Date
        const refDate = new Date(this.state.StartingMonday + "T00:00:00")
        let validDate = -1
        
        if (refDate.getDay() === 1) {
            validDate = 0
        } else {
            validDate = -2
        }
        if (validDate < 0) {
            this.InfoDrawerElement.current.handleOpen(
                MSGS.ERR.INVALID_DATE.COLOR, 
                MSGS.ERR.INVALID_DATE.MSG,
                MSGS.ERR.INVALID_DATE.DELAY,    
            )
            return
        }
        
        // Email Subject Check
        if (this.state.emailSubject === "") {
            this.InfoDrawerElement.current.handleOpen(
                MSGS.ERR.NO_EMAIL_SUBJ.COLOR, 
                MSGS.ERR.NO_EMAIL_SUBJ.MSG,
                MSGS.ERR.NO_EMAIL_SUBJ.DELAY,    
            )
            return
        }

        const prod = MC_DOMAIN_DETAILS.PROD[this.state.domainIndex]
        const sd = MC_DOMAIN_DETAILS.EMAIL[this.state.domainIndex]

        let details = {
            title: "Warmup Campaign Info",
            product: "Product: " + prod,
            schedule_domain: "Sending Domain: " + sd,
            email_pre_text: "Email Name Pre-text: " + this.state.campaignExtraName,
            email_subj_line: "Email Subject Line: " + this.state.emailSubject,
            email_prev: "Email Preview Text: " + this.state.previewText,
            start_date: "Start Date: " + this.state.StartingMonday,
            start_week: "Start Day's Week: " + this.state.selectedLetterC,
        }
        this.CampConfirmElement.current.handleOpen(details)
    }

    hCampaignConfirm = async () => {
        //this.hCampaigns()
        this.hServerCampaigns()
    }

    hServerCampaigns = async() => {
        
        // 
        const result = this.state.templateData.find(item => 
            item.name === this.state.templateSelect);
        
        const templateID = result.id

        const refDate = new Date(this.state.StartingMonday + "T00:00:00")
        
        // Assemble Email Details
        const emailDetails = [{
            subject: this.state.emailSubject, 
            from_name: MC_DOMAIN_DETAILS.NAME[this.state.domainIndex],
            from: MC_DOMAIN_DETAILS.EMAIL[this.state.domainIndex],            
            preview_text: this.state.previewText,
            template: templateID,
        }]

        let groups_array = MC_WARMUP_GROUPS[MC_DOMAIN_DETAILS.PROD[this.state.domainIndex]][this.state.selectedLetterC]
        let groups_len = groups_array.length
        if (groups_len === 75) {
            groups_array = groups_array.concat(groups_array)
            groups_len = groups_array.length
        }

        // Assemble Warmups
        const warmup_days = this.WarmupDaysElement.current.getSelected()
        
        // overflow value based on groups_len
        const weeks_overflow = Math.floor(groups_len / 15)
        // 150 / 15 => 10
        // 225 / 15 => 15

        let warmups = []
        for (let i = 0; i < warmup_days.length; i++) {
            // Current Warmup Day from Selected list
            const warmup_day_num_str = warmup_days[i]
            const warmup_day_num = parseInt(warmup_day_num_str)
            // Index Range for Given Day
            let l_index = (warmup_day_num - 1) * 15
            let u_index = warmup_day_num * 15
            if (warmup_day_num > weeks_overflow) {
                const mv = Math.floor((warmup_day_num - 1) / weeks_overflow)
                l_index -= mv * groups_len;//150
                u_index -= mv * groups_len;//150
            }

            // Corresponding group-Field portions of Warmup codes
            const subset = groups_array.slice(l_index, u_index)
            // Generate full codes for current Warmup Day
            for (let j = 0; j < subset.length; j++) {
                const warmup_str = warmup_day_num_str + '_' + subset[j]
                warmups.push(warmup_str)
            }
        }

        // Assemble Full Information
        const data = {
            start_date: refDate,
            email_details: emailDetails,
            pre_text: this.state.campaignExtraName,
            product_type: MC_DOMAIN_DETAILS.NAME_TYPE[this.state.domainIndex],
            warmups: warmups,
        }
        
        // Post Information to backend Server for execution there
        this.Mailchimp.post_warmup_campaigns(MC_DOMAIN_DETAILS.REF[this.state.domainIndex], data)
        .then((res) => {
           this.setState({ uiDisable: true })
        });
    }

    checkSegments = async () => {

        this.setState( {uiDisable: true} );
        // Read Segments
        const res = await this.Mailchimp.get_segments(MC_DOMAIN_DETAILS.REF[this.state.domainIndex])
        // Check if All Exist

        // Based on the letter keys in MC_WARMUP_GROUPS, check if all segments exist
        const LTR_KEYS = Object.keys(MC_WARMUP_GROUPS[this.state.selectedDomain])

        let missing = []
        for (const letter of LTR_KEYS) {
            for (let i = 0; i < MC_WARMUP_SEGS_MAP[letter].length; i++) {
                if (!res.includes(MC_WARMUP_SEGS_MAP[letter][i])) {
                    missing.push(MC_WARMUP_SEGS_MAP[letter][i]);
                }
            }
        }

        //
        /*
        for (let i = 0; i < BASE_NAMES_A.length; i++) {
            if (!res.includes(BASE_NAMES_A[i])) {
                missing.push(BASE_NAMES_A[i]);
            }
        }
        for (let i = 0; i < BASE_NAMES_B.length; i++) {
            if (!res.includes(BASE_NAMES_B[i])) {
                missing.push(BASE_NAMES_B[i]);
            }
        }
        for (let i = 0; i < BASE_NAMES_C.length; i++) {
            if (!res.includes(BASE_NAMES_C[i])) {
                missing.push(BASE_NAMES_C[i]);
            }
        }*/
        
        // Create Segments If Don't Exist Already
        if (missing.length > 0) {

            this.InfoDrawerElement.current.handleOpen(
                MSGS.INFO.MISSING_GROUP_SEGMENTS.COLOR, 
                MSGS.INFO.MISSING_GROUP_SEGMENTS.MSG,
                MSGS.INFO.MISSING_GROUP_SEGMENTS.DELAY,    
            )
            
            await this.Mailchimp.create_segments(
                MC_DOMAIN_DETAILS.REF[this.state.domainIndex],
                missing
            )

        } else {
            // Inform that they all exist already
            this.InfoDrawerElement.current.handleOpen(
                MSGS.INFO.GROUP_SEGMENTS_EXIST.COLOR, 
                MSGS.INFO.GROUP_SEGMENTS_EXIST.MSG,
                MSGS.INFO.GROUP_SEGMENTS_EXIST.DELAY,    
            )
            this.setState( {uiDisable: false} );
        }

    }

    render() {

        const { classes } = this.props;

        const currentPath = this.props.location.pathname;

        return (
            <div className={classes.root}>
                <NavBar currentPath = {currentPath} />
                <div style = {{display: 'flex'}}>
                    <div style = {{flexGrow: 1}}>
                        <FormControl
                            variant="outlined"
                        >
                            <Select
                                value={this.state.selectedDomain}
                                onChange={this.handleChangeDomain}
                                input={
                                    <OutlinedInput name="selectedDomain" />
                                }
                            >
                                {MC_DOMAIN_DETAILS.PROD.map((val) => {
                                    return (
                                        <MenuItem key={val} value={val}>
                                            {val}
                                        </MenuItem>
                                    );
                                })}
                            </Select>       
                        <FormHelperText >Domain Product</FormHelperText>
                        </FormControl>
                        <Button
                            onClick = {this.refreshDomainFlags}
                        >
                            Refresh
                        </Button>
                    </div>
                </div>
                <div style={{
                        display:"flex", 
                        borderRadius: 10,
                        border: 'solid 1px',
                        padding: '15px',
                    }}
                >
                    <div style={{flexGrow: 1}}>
                        <Button
                            className={classes.buttonStyle}
                            onClick={this.checkSegments}
                            disabled = {this.state.uiDisable}
                            style = {{margin: '5px'}}
                        >
                            Create Group segments If Don't Exist
                        </Button>
                        <FormControl
                            variant="outlined"
                        >
                            <Select
                                value={this.state.selectedLetter}
                                onChange={this.handleChangeOnly}
                                input={
                                    <OutlinedInput name="selectedLetter" />
                                }
                            >
                                {WARMUP_LETTER_OPTS[this.state.selectedDomain].map((val) => {
                                    return (
                                        <MenuItem key={val} value={val}>
                                            {val}
                                        </MenuItem>
                                    );
                                })}
                            </Select>       
                        <FormHelperText >Warmup Day 1 Letter Choice</FormHelperText>
                        </FormControl>
                        <Button
                            className={classes.buttonStyle}
                            onClick={this.assignWarmup}
                            disabled = {true} // {this.state.uiDisable}
                            style = {{margin: '5px'}}
                        >
                            Generate Warmup Assign Data
                        </Button>
                        {(this.state.canGetFile === true) && (
                            <Button
                                className={classes.buttonStyle}
                                onClick={this.assignWarmupGet}
                                disabled = {this.state.uiDisable}
                            >
                                Get Warmup Assign Data
                            </Button>
                        )}
                        {(this.state.showDownload === true) && (
                            <CSVLink 
                                data={this.state.csvData} 
                                filename={this.state.fileName}
                            >Download Warmup Assign File</CSVLink>
                        )}
                    </div>
                    <div style={{flexGrow: 1}}>
                        {(this.state.busyMakingCSV === true) && (
                            <Typography>
                                {this.state.assignStatus}
                            </Typography>
                        )}
                    </div>
                </div>
                <div className= {classes.wrapperDiv}>
                    <div className = {classes.mainDiv}>
                        
                        <div style = {{flexGrow: 1}}>
                            <Typography>
                                Warmup Days Options
                            </Typography>
                            <MultiSelectAll
                                ref = {this.WarmupDaysElement}
                                keystr = 'Warmup'
                            />
                        </div>
                        <div style = {{flexGrow: 1}}>     
                            <FormControl
                                disabled = {this.state.uiDisable}
                            >
                                <Input
                                    aria-describedby="standard-pn-helper-text"
                                    inputProps={{
                                        'aria-label': 'pn',
                                    }}
                                    onChange = {this.handleChangeOnly}
                                    autoComplete="off"
                                    name = 'emailSubject'
                                    value = {this.state.emailSubject}
                                />
                                <FormHelperText id="standard-pn-helper-text">Email Subject</FormHelperText>
                            </FormControl>    
                            <FormControl
                                variant="outlined"
                            >
                                <Select
                                    value={this.state.selectedLetterC}
                                    onChange={this.handleChangeOnly}
                                    input={
                                        <OutlinedInput name="selectedLetterC" />
                                    }
                                >
                                    {WARMUP_LETTER_OPTS[this.state.selectedDomain].map((val) => {
                                        return (
                                            <MenuItem key={val} value={val}>
                                                {val}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>       
                            <FormHelperText >Warmup Day 1 Letter Choice</FormHelperText>
                            </FormControl>
                        </div>
                        <div style = {{flexGrow: 1}}>
                            <form className={classes.container} noValidate
                                disabled = {this.state.uiDisable}
                            >
                                <TextField
                                    id="date"
                                    label="Starting Monday"
                                    type="date"
                                    name="StartingMonday"
                                    onChange = {this.handleChangeOnly}
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </form>
                        </div>
                        <div style = {{flexGrow: 1}}>
                            <FormControl
                                variant="outlined"
                                disabled = {this.state.uiDisable}
                            >
                                <Input
                                    aria-describedby="standard-pn-helper-text"
                                    inputProps={{
                                        'aria-label': 'pn',
                                    }}
                                    onChange = {this.handleTemplateFilter}
                                    autoComplete="off"
                                    name = 'templateFilter'
                                    value = {this.state.templateFilter}
                                />
                                <FormHelperText >Template Filter</FormHelperText>
                            </FormControl>
                            <FormControl
                                variant="outlined"
                                disabled = {this.state.uiDisable}
                            >
                                <Select
                                    value={this.state.templateSelect}
                                    onChange={this.handleChangeOnly}
                                    input={
                                        <OutlinedInput name="templateSelect" />
                                    }
                                >
                                    {this.state.templateSelectOpts.map((val, i) => {
                                        return (
                                            <MenuItem key={val + String(i)} value={val}>
                                                {val}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>       
                            <FormHelperText >Template Selection</FormHelperText>
                            </FormControl>
                        </div>
                        <div style = {{flexGrow: 1}}>     
                            <FormControl
                                disabled = {this.state.uiDisable}
                            >
                                <Input
                                    aria-describedby="standard-pn-helper-text"
                                    inputProps={{
                                        'aria-label': 'pn',
                                    }}
                                    onChange = {this.handleChangeOnly}
                                    autoComplete="off"
                                    name = 'campaignExtraName'
                                    value = {this.state.campaignExtraName}
                                />
                                <FormHelperText id="standard-pn-helper-text">Extra Campaign Pre-Text</FormHelperText>
                            </FormControl>
                            <FormControl
                                disabled = {this.state.uiDisable}
                            >
                                <Input
                                    aria-describedby="standard-pn-helper-text"
                                    inputProps={{
                                        'aria-label': 'pn',
                                    }}
                                    onChange = {this.handleChangeOnly}
                                    autoComplete="off"
                                    name = 'previewText'
                                    value = {this.state.previewText}
                                />
                                <FormHelperText id="standard-pn-helper-text">Preview Text</FormHelperText>
                            </FormControl>
                        </div>           
                    </div>
                    <div className = {classes.mainDiv}>
                        <div style = {{flexGrow: 1}}>
                            <Button
                                className = {classes.buttonStyle}
                                onClick = {this.hCampaignCreation}
                                disabled = {this.state.uiDisable}
                            >
                                Create and Schedule Warmup
                            </Button>
                        </div>
                    </div>
                </div>
                
                <InfoDrawer
                    ref = {this.InfoDrawerElement}
                    onClose = {() => {}}
                />
                <CampaignConfirm
                    ref = {this.CampConfirmElement}
                    onConfirm = {this.hCampaignConfirm}
                />
            </div>
        )
    }





}
    
export default withStyles(styles)(MCWarmup);
