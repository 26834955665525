// For TopBar Routing
const MenuConstants = {
  MAILERLITE:
  [
    {
      label: "Main",
      pathname: "/main",
    },
    {
      label: "Warmup",
      pathname: "/warmup"
    },
    {
      label: "Unsubscribers",
      pathname: "/unsub",
    },
    {
      label: "Upload Subscribers",
      pathname: "/uploadsubscribers"
    },
    {
      label: "Stats",
      pathname: "/stats"
    },
  ],
  MAILCHIMP: [
    {
      label: "Main",
      pathname: "/main",
    },
    {
      label: "Warmup",
      pathname: "/warmup"
    },
    {
      label: "Unsubscribers",
      pathname: "/unsub",
    },
    {
      label: "Upload Subscribers",
      pathname: "/uploadsubscribers"
    },
    {
      label: "Stats",
      pathname: "/stats"
    },
  ],
  SENDY: [
    {
      label: "Main",
      pathname: "/main",
    },
    {
      label: "Warmup",
      pathname: "/warmup"
    },
    {
      label: "Unsubscribers",
      pathname: "/unsub",
    },
  ]
}
  
  export default MenuConstants;