import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';

import SCScheduler from "./SCScheduler";

import { S_DOMAIN_DETAILS } from '../../store/domainStore';
import InfoModal from "../modals/InfoModal";
import NavBar from "../NavBar";
import SendyService from "../../sendy/SendyService";


const styles = (theme) => ({
    root: {
        maxHeight: '100vh', 
        backgroundColor: 'white', 
        overflow: 'visible',
    },
});


class SMainPage extends Component {

    constructor(props) {
      super(props);
      
      this.Sendy = new SendyService();

      this.tab0Element = React.createRef()
      this.InfoModalElement = React.createRef()
      
      this.TrueSubscriberCount = 0;
      this.SubscribersReadIn = 0;
      
      this.SubsFlags = {};
      this.Subscribers = {};
      this.FieldSegments = {};
    }
  
    state = {
        loading: false,  
        selectedDomain: S_DOMAIN_DETAILS.PROD[0],
        refIndex: 0,
        Subscribers: {},
        uiDisable: true,
        pgStr: "",
        paging: false,
    };

    componentDidMount() {
        this.checkDomainStatus()
    }

    checkDomainStatus = async () => {

        this.setState( {uiDisable: true} )
        const res = await this.Sendy.fetch_domain_state(S_DOMAIN_DETAILS.REF[this.state.refIndex])
        if (res.sDomainState === false) { // false => not busy, true = busy
            this.tab0Element.current.setIndex(this.state.refIndex)
            this.tab0Element.current.fullEnable()
        } else { // busy, don't enable it
            this.tab0Element.current.setIndex(this.state.refIndex)
        }
        this.setState( {uiDisable: false} )
        setTimeout(this.tab0Element.current.initFieldgroups, 300)
    }

    refreshScheduler = async () => {
        this.checkDomainStatus()
    }

    handleChangeDomain = async (event) => {
        this.setState( {[event.target.name]: event.target.value, uiDisable: true} )

        this.tab0Element.current.fullDisable()

        let refIndex = -1
        for (let k = 0; k < S_DOMAIN_DETAILS.REF.length; k++) {
            if (S_DOMAIN_DETAILS.PROD[k] === event.target.value) {
                refIndex = k
                break
            }
        }

        this.tab0Element.current.resetState()
        //this.tab0Element.current.fullEnable()
        
        this.checkDomainStatus()

        this.setState( {refIndex: refIndex, uiDisable: false} )
    }

    testFunction = async () => {

        //https://sendy.lr-mails.com/subscribers?i=1&l=1&u=1

        let options = {
            method:'GET',
            //headers: { 'Content-Type': 'application/json' },
        };
        /*    
        headers = {
            'Content-type': 'application/x-www-form-urlencoded',
        }
        return post(url, SENDY_CREATE_CAMPAIGN, body=details, headers=headers)
        */

        return fetch("https://sendy.lr-mails.com/subscribers?i=1&l=1&u=1", options)
          .then(res => res.text()
          .then((data) => {
            console.log(data)
            return data
          })).catch((err) => {
            console.log(err)
            return err
          })

    }
    

    render() {

        const { classes } = this.props;

        const currentPath = this.props.location.pathname;

        return (
            <div className={classes.root}>
                <NavBar currentPath = {currentPath} />
                <div style = {{display: 'flex'}}>
                    <div style = {{flexGrow: 1}}>
                        <FormControl
                            variant="outlined"
                            disabled = {this.state.uiDisable}
                        >
                            <Select
                                value={this.state.selectedDomain}
                                onChange={this.handleChangeDomain}
                                input={
                                    <OutlinedInput name="selectedDomain" />
                                }
                            >
                                {S_DOMAIN_DETAILS.PROD.map((val) => {
                                    return (
                                        <MenuItem key={val} value={val}>
                                            {val}
                                        </MenuItem>
                                    );
                                })}
                            </Select>       
                        <FormHelperText >Domain Product</FormHelperText>
                        </FormControl>
                        <Button
                            onClick = {this.refreshScheduler}
                        >
                            Refresh
                        </Button>
                    </div>
                </div>
                <SCScheduler 
                    ref = {this.tab0Element}
                />
                <InfoModal
                    ref = {this.InfoModalElement}
                />
            </div>
        )
    }

}

export default withStyles(styles)(SMainPage);


